import createjs from 'easeljs';

import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import TransformHandle from 'views/composer/composition/objects/widgets/graphics/TransformHandle';
import PointUtil from 'views/utils/PointUtil';
import ApplicationState from 'app/ApplicationState';

export default
    class FreeTransformWidget
    extends AbstractObjectWidget
{
    constructor( subject, container ) {
        super( subject, container );
    }
    
    updateView() 
    {   
        // Don't bother with the transformed bounds. Get the original ones save yourself some math.
        let bounds = this.subject.getBounds();
        if( !bounds ) {
            // If no bounds exist yet, the object hasn't rendered. Skip the update.
            return;
        }
        
        // Registration point is the center, in other words, untransformed width / 2. Conventient :)
        let x = this.subject.scaleX * this.subject.regX + (this.subject.scaleX > 0 ? this.widgetMargin : -this.widgetMargin );
        let y = this.subject.scaleY * this.subject.regY + (this.subject.scaleY > 0 ? this.widgetMargin : -this.widgetMargin );
        
        this.handles[0].x = -x;
        this.handles[0].y = -y;
        
        this.handles[1].x = x;
        this.handles[1].y = this.handles[0].y;
        
        this.handles[2].x = this.handles[1].x;
        this.handles[2].y = y;
        
        this.handles[3].x = this.handles[0].x;
        this.handles[3].y = this.handles[2].y;
        
        for( let handle of this.handles ) {
            handle.visible = this.container.allowWidgetInteraction;
        }
        
        this._updateBorder();
    }
    
    _defineView() 
    {
        this.widgetMargin = 0;
        
        // Intersecting lines (rectangle with border)    
        this.border = this._createBorder();
        this.addChild( this.border );
        
        // ... And a widget for each handle
        this.handles = [];
        for( let i = 0; i < 4; i ++ ) {
            let handle = this._createTransformHandle();
            this.handles.push( handle );
            this.addChild( handle );
        }
    }
    
    _createTransformHandle()
    {
        let handle = new TransformHandle();
        
        for( let evtType of ['mousedown', 'pressmove', 'click'] ) {
            handle.addEventListener( evtType, (evt) => this._onTransformHandleMouseEvent( evt ) );
        }
        
        return handle;
    }
    
    _createBorder() 
    {
        let s = new createjs.Shape();
        s.mouseEnabled = false;
        this.addChild( s );
        return s;
    }
    
    _updateBorder()
    {
        const ratio = ApplicationState.get('composerCanvasParameters').dpiMultiplier;
        const bounds = this.subject.getTransformedBounds();
        bounds.width += 2;
        bounds.height += 2;
        this.border.graphics.clear();
        this.border.graphics.beginStroke( 'rgba(0, 0, 0, .9)' ).setStrokeDash( [8 * ratio, 3 * ratio], 0 ).setStrokeStyle( 0.5 * ratio ).beginFill( 'rgba(255, 255, 255, 0.2)' );
        this.border.graphics.drawRect( -bounds.width / 2, -bounds.height / 2, bounds.width, bounds.height );
    }
    
    _onTransformHandleMouseEvent( evt ) 
    {
        // Get the mouse cursor coordinates relative to our subject object
        let pt = this.subject.globalToLocal( evt.rawX, evt.rawY );
        
        // Compensate for the registration point, which is the center of the subject object
        pt.setValues( pt.x - this.subject.regX, pt.y - this.subject.regY );
        
        switch( evt.type ) 
        {
            case 'mousedown':
                // The top left point on the left, is not the same as the top right point dragged to the left.
                // Check the point 'starting position'. Much easier on the math than performing the required
                // intermediate calculations on 'pressmove'.
                this._flippedX = pt.x < 0;
                this._flippedY = pt.y < 0;
                this._manipulationHasStarted = false;
                break;
                
            case 'pressmove':
                if( !this._manipulationHasStarted ) {
                    this._manipulationHasStarted = true;
                    this._dispatchManipulationStart();
                }
            
                // Compensate for the current scale of the subject object
                pt.setValues( pt.x * this.subject.scaleX, pt.y * this.subject.scaleY );
                
                // Compensate for the widget margin
                pt.x += (pt.x > 0 ? -this.widgetMargin : this.widgetMargin );
                pt.y += (pt.y > 0 ? -this.widgetMargin : this.widgetMargin );
                
                // Do the flip trick from 'mousedown'
                if( this._flippedX ) { 
                    pt.x = -1 * pt.x;
                }
                
                if( this._flippedY ) {
                    pt.y = -1 * pt.y;
                }
                
                // prevent excessive values due to division by (close to) zero
                let limit = 2;
                if( pt.x >= 0 && pt.x < limit ) pt.x = limit;
                if( pt.x < 0 && pt.x > -limit ) pt.x = -limit;
                if( pt.y >= 0 && pt.y < limit ) pt.y = limit;
                if( pt.y < 0 && pt.y > -limit ) pt.y = -limit;
                
                // Use the untransformed values as a reference to determine the new scale
                let bounds = this.subject.getBounds();
                
                if( evt.nativeEvent.altKey == true ) {
                    // Perform free transformation
                    this.subject.scaleX = pt.x / (bounds.width / 2);
                    this.subject.scaleY = pt.y / (bounds.height / 2);
                } else {
                    // Perform uniform transformation
                    let centerPt = new createjs.Point( 0, 0 );
                    let originalPt = new createjs.Point( bounds.width / 2, bounds.height / 2 );
                    let currentPt = new createjs.Point( pt.x, pt.y );
                    let originalDelta = PointUtil.distanceBetweenPoints( centerPt, originalPt );
                    let currentDelta = PointUtil.distanceBetweenPoints( centerPt, currentPt );
                    
                    let ratio = currentDelta / originalDelta
                    
                    // Respect the original orientations
                    this.subject.scaleX = this.subject.scaleX > 0 ? ratio : -ratio;
                    this.subject.scaleY = this.subject.scaleY > 0 ? ratio : -ratio;
                }
                
                this._dispatchManipulated();
                break;
                
            case 'click':
                this._dispatchManipulationEnd();
                break;
        }
    }
}