import Marionette from 'backbone.marionette';

import template from 'templates/components/toggle-visibility';

export default
    class ToggleView extends Marionette.ItemView {

    onRender() {
        this.$el.on('click', this._handleToggle.bind(this));
    }

    _handleToggle() {
        var isOn = !this.model.get('on');
        this.model.set('on', isOn);
        this.$el.find('.toggle').toggleClass('toggle--on', isOn);
    }

    get template() {
        return template;
    }
}
