import createjs from 'easeljs';

export default
    class CanvasObjectEvent
    extends createjs.Event
{
    static get WILL_BE_EDITED() { return 'CANVAS_OBJECT_WILL_BE_EDITED_EVENT'; }
    static get HAS_UPDATED() { return 'CANVAS_OBJECT_HAS_UPDATED_EVENT'; }
    static get WAS_SELECTED() { return 'CANVAS_OBJECT_WAS_SELECTED'; }
    static get WAS_DESELECTED() { return 'CANVAS_OBJECT_WAS_DESELECTED'; }
    static get REQUEST_DELETE() { return 'CANVAS_OBJECT_REQUEST_DELETE_EVENT'; }
    
    constructor( type, payload = null ) {
        super( type );
        this._payload = payload;
    }
    
    get payload() {
        return this._payload;
    }
}