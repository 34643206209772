import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Analytics from 'utils/Analytics';

import template from 'templates/lightset-wizard/lightset-wizard-features-row';

import LightsetWizardFeatureCollectionView from 'views/lightset-wizard/LightsetWizardFeatureCollectionView';
import LightsetWizardFeaturesRowModel from 'models/lightset-wizard/FeaturesRow';

export default Marionette.LayoutView.extend({

    _carouselIndex: 0,

    className: 'lightset-wizard__feature-carousel',

    events: {
        'click @ui.next': 'next',
        'click @ui.previous': 'previous'
    },

    template: template,

    regions: {
        'slides': '@ui.slidesWrapper'
    },

    ui: {
        slidesWrapper: '.lightset-wizard__slides-wrapper',
        previous: '.lightset-wizard__prev-slide',
        next: '.lightset-wizard__next-slide'
    },

    initialize() {
        this.model = new Backbone.Model({
            carouselEnabled: this.getOption('collection').length > 6
        });
    },

    onBeforeShow() {
        let collectionView = new LightsetWizardFeatureCollectionView({
            collection: this.getOption('collection')
        });

        this.showChildView('slides', collectionView);

        this.listenTo(collectionView, 'select:item', function(model){
            this.trigger('select:item', model);
        });

        if(this.getOption('active')) {
            let model = this.collection.findWhere({id: this.getOption('active').id});
            if(model) {
                let activeView = collectionView.children.findByModel(model);
                activeView.triggerMethod('select');
            }
        } else if (this.getOption('collection').length === 1) {
            let firstView = collectionView.children.findByIndex(0);

            firstView.triggerMethod('select');
        }
    },

    previous() {
        Analytics.track('navigation_arrow:left');
        this.to(this._carouselIndex - 1);
    },

    next() {
        Analytics.track('navigation_arrow:right');
        this.to(this._carouselIndex + 1);
    },

    to(index) {
        this._carouselIndex = index;
        this.ui.slidesWrapper.css('transform', 'translateX(-' + index * 100 + '%)');

        // Disable prev when we're on the first slide
        this.ui.previous.prop('disabled', index <= 0);
        // Disable next when we're on the last slide
        this.ui.next.prop('disabled', index >= Math.ceil(this.getOption('collection').length / 6) - 1);
    }
});
