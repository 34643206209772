import createjs from 'easeljs';

export default
    class Line
    extends createjs.Shape
{
    constructor() {
        super();
        
        this.hitArea = new createjs.Shape();
        this.setPoints( new createjs.Point( 0, 0 ), new createjs.Point( 300, 0 ) );
    }
    
    get pointA() {
        return this._pointA;
    }
    
    set pointA( point ) {
        this._pointA = point;
        this._update();
    }
    
    get pointB() {
        return this._pointB;
    }
    
    set pointB( point ) {
        this._pointB = point;
        this._update();
    }
    
    setPoints( pointA, pointB ) {
        this._pointA = pointA;
        this._pointB = pointB;
        this._update();
    }
    
    _update() {
        this._drawLine( this.hitArea.graphics, 20, '#000000' );
        this._drawLine( this.graphics, 1, '#ef0606' );
    }
    
    _drawLine( graphics, thickness, color ) {
        graphics.clear();
        graphics.setStrokeStyle( thickness );
        graphics.beginStroke( color );
        graphics.moveTo( this._pointA.x, this._pointA.y );
        graphics.lineTo( this._pointB.x, this._pointB.y );
    }
}