import createjs from 'easeljs';

import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import TransformHandle from 'views/composer/composition/objects/widgets/graphics/TransformHandle';
import PointUtil from 'views/utils/PointUtil';

export default
    class LineTransformWidget
    extends AbstractObjectWidget
{
    constructor( subject, container ) {
        super( subject, container );
    }
    
    updateView() 
    {
        let line = this.subject;
        
        this.children[0].x = line.pointA.x;
        this.children[0].y = line.pointA.y;
        
        this.children[1].x = line.pointB.x;
        this.children[1].y = line.pointB.y;
        
        for( let child of this.children ) {
            child.visible = this.container.allowWidgetInteraction;
        }
    }
    
    _defineView() 
    {
        // Create a square handle for each end of the line
        for( let i = 0; i < 2; i ++ ) {
            let handle = new TransformHandle();
            
            for( let evtType of ['mousedown', 'pressmove', 'click'] ) {
                handle.addEventListener( evtType, (evt) => this._onTransformHandleMouseEvent( evt ) );
            }
            
            this.addChild( handle );
        }
    }
    
    _onTransformHandleMouseEvent( evt ) 
    {
        // Get the mouse cursor coordinates relative to our subject object
        let pt = this.subject.globalToLocal( evt.rawX, evt.rawY );
        
        // Compensate for the registration point (though it should just be 0-0 because the container doesn't move along with the drawn line)
        pt.setValues( pt.x - this.subject.regX, pt.y - this.subject.regY );
        
        switch( evt.type ) 
        {
            case 'mousedown':
                this._manipulationHasStarted = false;
                break;
                
            case 'pressmove':
                if( !this._manipulationHasStarted ) {
                    this._manipulationHasStarted = true;
                    this._dispatchManipulationStart();
                }
                
                let handle = evt.currentTarget;
                let index = this.getChildIndex( handle );
                if( index == 0 ) {
                    this.subject.pointA = pt;
                } else {
                    this.subject.pointB = pt;
                }
                
                this._dispatchManipulated();
                break;
                
            case 'click':
                this._dispatchManipulationEnd();
                break;
        }
    }
}