import createjs from 'easeljs';

export default
    class ContainerUtil
{
    static getFirstUnoccupiedPosition( container, x, y, spacing ) 
    {
        while( true ) {
            let occupied = false;
            
            // This is still flawed, but will do for now...
            for( let obj of container.children ) {
                if( obj.x >= x && obj.x <= x + spacing && obj.y >= y && obj.y <= y + spacing ) {
                    occupied = true;
                }
            }
            
            if( occupied ) {
                x += spacing;
                y += spacing;
            } else {
                break;
            }
        }
        
        return new createjs.Point( x, y );
    }
}