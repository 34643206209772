import Marionette from 'backbone.marionette';
import async from 'async';

export default 
    class AbstractPage 
    extends Marionette.Object {

    get colors() {
        return {
            white: '#ffffff',
            lightBlue: '#0089c4',
            blue: '#4d87c2',
            grey: '#dddddd',
            darkGrey: '#303030'
        };
    }

    get width() {
        return this.pdf.page.width;
    }

    get height() {
        return this.pdf.page.height;
    }

    get margin() {
        return this._convertMmToPoints(18);
    }

    get footerY() {
        return this.height - 80;
    }

    get orientation() {
        return 'portrait';
    }

    get size() {
        return 'A4';
    }

    get steps() {
        throw 'Abstract public get steps() not implemented';
    }

    _convertMmToPoints(mm) {
        let inches = mm * 0.0393700787;
        let pointsPerInch = 72;

        return inches * pointsPerInch;
    }

    constructor(pdf, model, isFirstPage, callback) {
        super();
        this.pdf = pdf;
        this.model = model;

        if (!isFirstPage) {
            this.pdf.addPage({
                layout: this.orientation,
                size: this.size,
                margins: {
                    top: 1,
                    bottom: 1,
                    left: 72,
                    right: 72
                }
            });
        }

        async.series(this.steps, callback);
    }

    addImage(url, options, callback) {
        if (/^data:.+;base64,(.*)$/.exec(url)) {
            this._addImageByDataUri(url, options, callback);
        } else {
            this._addImageByUrl(url, options, callback);
        }
    }

    _addImageByDataUri(data, options, callback) {
        this.pdf.image(data, options.x, options.y, {width: options.width});
        callback();
    }

    _addImageByUrl(url, options, callback) {
        let xhr = new XMLHttpRequest;
        xhr.open('GET', url, true);
        
        xhr.responseType = 'arraybuffer';
        xhr.onload = function() {
            this.pdf.image(new Buffer(xhr.response), options.x, options.y, {width: options.width});
            callback();
        }.bind(this);
        
        xhr.send();
    }

    _addFooter(callback) {
        let logoWidth = 80;

        // Footer line
        this.pdf
            .strokeColor(this.colors.grey)
            .moveTo(this.margin, this.footerY)
            .lineTo(this.width - this.margin, this.footerY)
            .stroke();

        // Disclaimer text
        this.pdf
            .font('CentraleSansBook')
            .fontSize(5)
            .fillColor(this.colors.grey)
            .text(this.model.get('disclaimerText'), this.margin, this.footerY + 10, {
                width: this.width - 2 * this.margin - logoWidth - 15
            }
        );

        this.addImage('img/pdf/logo.png', {
            x: this.width - this.margin - logoWidth,
            y: this.footerY + 10,
            width: logoWidth
        }, callback);
    }
};
