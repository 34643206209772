import createjs from 'easeljs';

import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import IconHandle from 'views/composer/composition/objects/widgets/graphics/IconHandle';
import PointUtil from 'views/utils/PointUtil';

export default
    class RotationWidget
    extends AbstractObjectWidget
{
    constructor( subject, container ) {
        super( subject, container );
    }
    
    updateView() {
        this.handle.visible = this.container.allowWidgetInteraction;
    }
    
    _defineView() {
        // Create the handle, a small circle that can be pressed to flip the subject (scaleX = -scaleX)
        this.handle = new IconHandle( 'img/components/icon-flip.png', 14 );
        this.handle.cursor = 'pointer';
        this.handle.addEventListener( IconHandle.ICON_LOADED, (evt) => this._handleIconLoaded( evt ) );
        this.handle.addEventListener( 'click', (evt) => this._handleClick( evt ) );
        this.addChild( this.handle );
    }
    
    _handleIconLoaded( evt ) {
        // Will force a display update, or else the icon will be invisible initially
        this._dispatchManipulated();
    }
    
    _handleClick( evt ) {
        this._dispatchManipulationStart();
        this.subject.scaleX = -this.subject.scaleX;
        this._dispatchManipulated();
        this._dispatchManipulationEnd();
    }
}