import $ from 'jquery';
import async from 'async';
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Handlebars from 'hbsfy/runtime';
import ObjectFitImages from 'object-fit-images';

import ApplicationState from 'app/ApplicationState';

import MainRouter from 'app/MainRouter';
import MainController from 'app/MainController';
import MainRegion from 'app/MainRegion';
import ModalRegion from 'app/ModalRegion';

import LanguageUtil from 'utils/Language';
import TemplateTranslations from 'models/translations/TemplateTranslations';

export default
    class Application
    extends Marionette.Application 
{
    initialize() {
        // The factor with which to increase the DPI of each canvas rendering, to improve composer/creator and PDF sharpness
        ApplicationState.set('canvasDpiMultiplier', 1.5);
        
        // Cordova is loaded by deviceready
        ApplicationState.set('cordova', false);

        document.addEventListener('deviceready', this._onDeviceReady, false);
        
        Handlebars.registerHelper('trans', function(value, options) {
            return gettext(value);
        });

        Handlebars.registerHelper('ifEquals', function(value1, value2, options) {
            if (value1 === value2) {
                return options.fn(this);
            } else {
                return options.inverse(this);
            }
        });

        this.addRegions({
            main: new MainRegion(),
            modal: new ModalRegion()
        });

        const asyncTasks = [];

        // Load local javascript runtime base on language
        const language = LanguageUtil.activateLanguageFromURL();
        asyncTasks.push(function(callback) {
            $.getScript(`localization/${language}.js`, () => { callback() });
        });

        // Load data
        for (let filename of ['application', 'lightPointTypes', 'heights', 'luminaires', 'brackets', 'poles', 'ral', 'background']) {
            asyncTasks.push(function(callback) {
                $.getJSON(`json/${filename}.json`, function(json) {
                    ApplicationState.set(`${filename}Data`, json);
                    callback();
                })
            }.bind(this));
        }

        async.series(asyncTasks, this.start.bind(this));
    }

    start() {
        super.start();
        //polyfill for object-fit support IE
        ObjectFitImages();

        let mainController = new MainController(this.getRegion('main'), this.getRegion('modal'));

        this.router = new MainRouter({controller: mainController});

        Backbone.history.start({
            pushState: false
        });
    }

    _onDeviceReady() {
        ApplicationState.set('cordova', 'Cordova' in window);
    }

}
