import _ from 'underscore';
import Backbone from 'backbone';

require('backbone-model-file-upload');

export default
    class EmailModel
    extends Backbone.Model {

    get url() {
        return 'scripts/email.php';
    }

    get fileAttribute() {
        return 'attachment';
    }

    get defaults() {
        return {
            inProgress: false,
            isFinished: false,
            isInvalid: false,
            translation: {
                modalHeader: gettext('Send e-mail'),
                modalText: gettext('You can send the project to your e-mailaddress for future reference.'),
                required: gettext('Fields marked with a * are required'),
                success: gettext('Your e-mail was sent to: '),
                error: {
                    form: gettext('An error occured please. Please try again.'),
                    validation: gettext('Please validate if all fields were filled correctly.'),
                },
                email: {
                    label: gettext('E-mail address'),
                    placeholder: gettext('Fill in your e-mail address'),
                },
                subject: {
                    label: gettext('Subject'),
                    placeholder: gettext('Fill in your subject'),
                },
                sendLabel: gettext('Send e-mail'),
                cancelLabel: gettext('Cancel'),
                closeLabel: gettext('Close and continue'),
            }
        };
    }

    initialize() {
        this.listenTo(this, 'sync', this.onSuccess);
        this.listenTo(this, 'error', this.onError);
    }

    validate(attrs, options) {
        this.set(attrs);
        
        let errors = {};

        if (!attrs.hasOwnProperty('subject') || _.isEmpty(attrs.subject)) {
            errors.subject = this.defaults.translation.subject.label;
        }

        const pattern = /^(.+)@(.+)\.(.+)$/i;
        if (!attrs.hasOwnProperty('subject') || !pattern.test(attrs.email)) {
            errors.email = this.defaults.translation.email.label;
        }

        if (Object.keys(errors).length > 0) {
            this.unset('error');
            this.set('isInvalid', true);
            return errors;
        }

        this.set('isInvalid', false, { silent: true });
        this.set('inProgress', true);
    }

    onSuccess() {
        this.unset('error');

        // Silence inProgress, isInvalid: the view listens to isFinished
        this.set({
            inProgress: false,
            isInvalid: false
        }, {
            silent: true
        });

        this.set('isFinished', true);
    }

    onError(model, xhr) {
         // Silence invalid: the view listens to inProgress
        this.set('isInvalid', true, { silent: true });

        this.set({
            inProgress: false,
            error: `${xhr.status} ${xhr.statusText}`
        });
    }
}
