export default
	class ImageUtil
{
	static resizeImage(img, maxWidth, maxHeight, completeHandler) 
	{   
        const ratioW = img.width / maxWidth;
        const ratioH = img.height / maxHeight;
        const ratio = ratioW > ratioH ? ratioW : ratioH;

        const canvas = document.createElement('canvas');
        canvas.width = img.width / ratio;
        canvas.height = img.height / ratio;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        img = new Image();
        img.width = canvas.width;
        img.height = canvas.height;
        img.onload = function() {
        	completeHandler(img);
        }
        img.src = canvas.toDataURL('image/jpeg', 0.7);
	}
}