import AbstractTool from 'controllers/composer/tools/AbstractTool';

export default 
    class SceneToolEdit
    extends AbstractTool
{
    constructor( id ) {
        super( id );
    }
    
    activate() {
        this._setObjectsSelectable( true );
    }
    
    deactivate() {
        this._setObjectsSelectable( false );
    }
    
    _setObjectsSelectable( selectable ) {
        for( let obj of this.context.compositionView.objects ) {
            // The BackgroundObject selectability is governed by the crop tool (SceneToolBackgroundCrop)
            if( obj !== this.context.compositionView.backgroundObject ) { 
                obj.selectable = selectable;
            }
        }
    }
}
