import createjs from 'easeljs';

import AbstractTool from 'controllers/composer/tools/AbstractTool';

import LightSetObject from 'views/composer/composition/objects/LightSetObject';

import MoveWidget from 'views/composer/composition/objects/widgets/MoveWidget';
import FreeTransformWidget from 'views/composer/composition/objects/widgets/FreeTransformWidget';
import RotationWidget from 'views/composer/composition/objects/widgets/RotationWidget';

import ContainerUtil from 'views/utils/ContainerUtil';

export default 
    class SceneToolAddSet
    extends AbstractTool
{
    activate() 
    {
        // Temp functionality. This should open the LightSet Wizard, but we're 
        // just adding a dummy object to the scene for development.
        let s = new createjs.Shape();
        s.graphics.beginFill( 'red' );
        s.graphics.drawRect( 0, 0, 100, 100 );
        s.graphics.beginFill( 'blue' );
        s.graphics.drawRect( 10, 10, 10, 10 );
        s.setBounds( 0, 0, 100, 100 );
        s.regX = s.getBounds().width / 2;
        s.regY = s.getBounds().height / 2;
        
        let obj = new LightSetObject( s, [MoveWidget, FreeTransformWidget, RotationWidget] );
        
        // Put the new lightset on an unoccupied position
        let unoccupiedPosition = ContainerUtil.getFirstUnoccupiedPosition( this.context.compositionView.lightSetContainer, 200, 100, 30 );
        
        obj.x = unoccupiedPosition.x;
        obj.y = unoccupiedPosition.y;
        
        this.context.compositionView.addObject( obj );
    }
    
    deactivate() {
        // this tool is stateless and has nothing to do upon deactivation
    }
}