import SceneToolAddSet from 'controllers/composer/tools/scene/SceneToolAddSet';
import ToolbarModel from 'models/composer/toolbars/ToolbarModel';

export default
    class AbstractToolkitController
{
    constructor() 
    {
        this._toolMap = {}
        this._activeTool = null;
        this._activeToolId = null;
        this._mouseCursorListeners = {};
        
        this._registerTools();
    }
    
    set isActive( active ) {
        this.model.get('itemCollection').setAttributes({
            isActive: active
        });
    }
    
    get context() {
        return this._context;
    }
    
    set context( val ) {
        this._context = val;
    }
    
    get model() {
        return this._model;
    }
    
    set model( model ) {
        if( this._model ) {
            // unsubscribe ourselves from all events
            this._model.off( null, null, this );
        }
        
        this._model = model;
        if( this._model ) {
            this._model.on( 'set:' + ToolbarModel.ACTIVE_TOOL, this._onActiveToolChange, this );
        }
    }
    
    get activeToolModel() {
        return this._activeToolModel;
    }
    
    set activeToolModel( model ) {
        // Todo: just pass the entire model to the tool instead of forwarding stuff
        if( this._activeToolModel ) {
            this._activeToolModel.off( null, null, this );
        }
        
        this._activeToolModel = model;
        if (this._activeToolModel) {
            this._activeToolModel.on( 'slider:change', this._onActiveToolSliderValueChange, this );
        }
    }
    
    get activeTool() {
        return this._activeTool;
    }
    
    get activeToolId() {
        return this._activeToolId;
    }
    
    // We allow passing just a toolId for easy switching of tools by external actors such as ComposerLayoutView. Finding the model itself would be
    // convoluted. When a check for stateless tools (such as undo) is desired, pass a toolModel so we can check it for a stateless property.
    // Stateless tools do not deactivate the current tool as they are single-serving events such as undo.
    activateTool( toolId, toolModel=null ) 
    {
        const tool = this._getToolLazy( toolId );
        const isStateless = toolModel != null && toolModel.get('stateless') === true;
        if (!isStateless && this._activeToolId && this._activeToolId != toolId) {
            this.deactivateCurrentTool();
        }
        if (tool != null) {
            tool.context = this.context; // The context which the tool requires to operate on.
            if (tool.canActivate) {
                tool.activate();
                if (isStateless) {
                    tool.deactivate(); // Kill immediately after operation
                    tool.context = null;
                } else {
                    this._activeTool = tool;
                    this._activeToolId = toolId;
                    if (tool.hasMouseCursor) {
                        this._setMouseCursorListeners( true );
                    }
                }
            } else {
                console.log( 'Cannot activate tool ' + tool.constructor.name + ', its requirements are not met.' );
            }
        }
    }
    
    setToolInput( toolId, model ) {
        if (toolId === this._activeToolId) {
            const tool = this._getToolLazy( toolId );
            if (tool != null) {
                let value = model.get('value');
                const coarseValue = model.get('coarseValue');
                if (coarseValue != null) { // only Sliders use this, togglers don't, they have an absolute binary value
                    value += coarseValue;
                }
                tool.processInput( value, model.get('type') );
            }
        }
    }
    
    deactivateCurrentTool() {
        if( this._activeTool ) {
            this._activeTool.deactivate();
            this._activeTool.context = null;
            if( this._activeTool.hasMouseCursor ) {
                this._activeTool.mouseCursor.parent.removeChild( this._activeTool.mouseCursor );
            }
            this._activeTool = null;
            this._activeToolId = null;
            this._setMouseCursorListeners( false );
        }
    }
    
    _onActiveToolChange( toolbarModel, toolModel ) {
        if( toolModel ) {
            this.activateTool( toolModel.get('type'), toolModel );
            if (this._activeTool && this._activeTool.hasMouseCursor) {
                // Mouse cursor tools use slider values as cursor input, rather than directly applying the value as an effect.
                // For these tools, apply the current slider data upon activation, so that we don't have to mirror the model values
                // with initial magic numbers (brush size etc) in the tool.
                const sliders = toolModel.get('sliders');
                sliders != null && sliders.each(function(sliderModel) {
                    this.setToolInput(this._activeToolId, sliderModel);
                }.bind(this));
            }
        } else {
            this.deactivateCurrentTool();
        }
        this.activeToolModel = toolModel;
    }
    
    _onActiveToolSliderValueChange( sliderModel ) {
        this.setToolInput( this._activeToolId, sliderModel );
    }
    
    _registerTools() {
        throw "Abstract method _registerTools() not implemented.";
    }
    
    _registerTool( toolId, toolClass ) {
        this._toolMap[ toolId ] = toolClass;
    }
    
    _getToolLazy( toolId )
    {
        const toolOrClass = this._toolMap[ toolId ];
        if( typeof toolOrClass === 'function' ) { // Even ES6 has no true classes in runtime, so we match for type function
            const ToolClass = toolOrClass;
            this._toolMap[ toolId ] = new ToolClass( toolId );
        }
        
        return this._toolMap[ toolId ]; // can be null if no tool is registered
    }
    
    _getToolContext() {
        throw "Abstract method _getToolContext() not implemented.";
    }
    
    _setMouseCursorListeners( active ) {
        let types = ['stagemousemove'];
        
        if( active ) {
            for( let type of types ) {
                if( !(type in this._mouseCursorListeners) ) {
                    this._mouseCursorListeners[type] = this.context.compositionView.stage.on( type, this._handleMouseCursorEvent, this );
                }
            }
        } else { 
            for( let type in this._mouseCursorListeners ) {
                this.context.compositionView.stage.off( type, this._mouseCursorListeners[type] );
            }
            this._mouseCursorListeners = {};
        }
    }
    
    _handleMouseCursorEvent( evt ) {
        switch( evt.type ) 
        {
            case 'stagemousemove':
                let cursor = this._activeTool.mouseCursor;
                if( cursor ) {
                    if( !cursor.parent ) {
                        this.context.compositionView.stage.addChild( cursor );
                    }
                    
                    cursor.x = evt.rawX;
                    cursor.y = evt.rawY;
                    this.context.compositionView.stage.update();
                }
                break;
        }
    }
}
