// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "			<li class=\"object-toolbar__tab\"><button class=\"object-toolbar__tab-button\" type=\"button\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Luminaire",{"name":"trans","hash":{},"data":data}))
    + "</button></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "			<li class=\"object-toolbar__tab\"><button class=\"object-toolbar__tab-button\" type=\"button\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Bracket",{"name":"trans","hash":{},"data":data}))
    + "</button></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "			<li class=\"object-toolbar__tab\"><button class=\"object-toolbar__tab-button\" type=\"button\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Pole",{"name":"trans","hash":{},"data":data}))
    + "</button></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"object-toolbar__tabs-section\">\n	<ul class=\"object-toolbar__tabs\">\n		<li class=\"object-toolbar__tab\"><button class=\"object-toolbar__tab-button\" type=\"button\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"All",{"name":"trans","hash":{},"data":data}))
    + "</button></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.luminaireModel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bracketModel : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.poleModel : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</ul>\n</div>\n<div class=\"object-toolbar__main-section\">\n	<div class=\"object-toolbar__back\">\n		<button class=\"object-toolbar__back-button\">\n			<span class=\"icon-arrow-left\"></span>\n		</button>\n	</div>\n	<div id=\"object-toolbar-color-select-region\" class=\"object-toolbar__color-select\"></div>\n</div>\n";
},"useData":true});
