import createjs from 'easeljs';
import $ from 'jquery';

export default
	class HiResStage
	extends createjs.Stage
{
	constructor(stageParameters) 
	{
		const ratio = stageParameters.dpiMultiplier;
		const canvas = stageParameters.canvas;

		super(canvas);
		
		// Modify the physical dimensions of the canvas element
		const width = stageParameters.width;
		const height = stageParameters.height;
		canvas.width = width * ratio;
		canvas.height = height * ratio;

		// Downscale the canvas by proportion, to achieve the original physical dimensions
		canvas.style.width = width + 'px';
		canvas.style.height = height + 'px';
	}
}