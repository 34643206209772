import createjs from 'easeljs';

import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import IconHandle from 'views/composer/composition/objects/widgets/graphics/IconHandle';
import PointUtil from 'views/utils/PointUtil';
import ApplicationState from 'app/ApplicationState';

export default
    class RotationWidget
    extends AbstractObjectWidget
{
    constructor( subject, container ) {
        super( subject, container );
    }
    
    updateView() 
    {   
        let bounds = this.subject.getTransformedBounds();
        if( !bounds ) {
            // If no bounds exist yet, the object hasn't rendered. Skip the update.
            return;
        }
                
        let handleSpacing = this.subject.scaleY >= 0 ? 15 : -15;
        handleSpacing *= ApplicationState.get('composerCanvasParameters').dpiMultiplier;
        this.handle.y = this.subject.scaleY > 0 ? -(bounds.height / 2) : bounds.height / 2;
        this.handle.y -= (handleSpacing + this.widgetMargin / 2);
        
        this.handle.visible = this.container.allowWidgetInteraction;
    }
    
    _defineView() 
    {
        this.widgetMargin = 0;
        
        // Create the handle, a small circle that can be pressmoved to rotate the container of the subject (e.g. the CanvasObject)
        this.handle = new IconHandle( 'img/components/icon-rotate.png', 14 );
        this.handle.addEventListener( IconHandle.ICON_LOADED, (evt) => this._handleIconLoaded( evt ) );
        this.handle.cursor = 'ew-resize';
        
        for( let evtType of ['mousedown', 'pressmove', 'click'] ) {
            this.handle.addEventListener( evtType, (evt) => this._handleRotaterBubbleMouseEvent( evt ) );
        }
        
        this.addChild( this.handle );
    }
    
    _handleIconLoaded( evt ) {
        // Will force a display update, or else the icon will be invisible initially
        this._dispatchManipulated();
    }
    
    _handleRotaterBubbleMouseEvent( evt )
    {
        switch( evt.type )
        {
            case 'mousedown':
                this._manipulationHasStarted = false;
                break;
            
            case 'pressmove':
                if( !this._manipulationHasStarted ) {
                    this._manipulationHasStarted = true;
                    this._dispatchManipulationStart();
                }
            
                // We perform all the rotation logic on the parent, a) to make the widgets rotate along
                // and b) to not have to account for widget and object rotation here and in the scaling logic.
                this.container.rotation = 0; // Reset the rotation to get accurate mouse-to-registration-point angle
                
                let angle = -1 * PointUtil.angleBetweenPoints( 
                    this.container.globalToLocal( evt.rawX, evt.rawY ),
                    new createjs.Point( this.container.regX, this.container.regY )
                    
                );
                
                // Invert the rotation if the subject object itself is inverted
                if( this.subject.scaleY < 0 ) {
                    angle += 180;
                }

                this.container.rotation = angle;
                
                this._dispatchManipulated();
                break;
                
            case 'click':
                this._dispatchManipulationEnd();
                break;
        }
    }
}