import _ from 'underscore';
import Analytics from 'utils/Analytics';

import LightsetWizardAbstractLayoutView from 'views/lightset-wizard/LightsetWizardAbstractLayoutView';
import LightsetWizardFeaturesRowView from 'views/lightset-wizard/LightsetWizardFeaturesRowView';
import LightsetWizardFeatureCollection from 'models/lightset-wizard/FeatureCollection';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

export default LightsetWizardAbstractLayoutView.extend({

    initialize() {
        const luminaireModel = this.model.get('luminaireModel');

        this.lightPointType = this.model.get('lightPointType').id;
        this.bracketFamilies = LightsetWizardDataUtil.getBracketFamiliesToDisplay(
            luminaireModel, this.lightPointType, this.model.get('height')
        );
    },

    onBeforeShow() {
        let bracketsView = new LightsetWizardFeaturesRowView({
            active: this.model.get('bracket'),
            collection: new LightsetWizardFeatureCollection(this.bracketFamilies)
        });

        this.listenTo(bracketsView, 'select:item', this.onSelectBracketFamily);
        this.showChildView('topRow', bracketsView);
    },

    onSelectBracketFamily(bracketFamily) {
        Analytics.track('select_bracket:product_type:' + bracketFamily.get('name'));

        let models = LightsetWizardDataUtil.getBracketModels(
            bracketFamily,
            this.lightPointType,
            this.model.get('luminaireModel'),
            this.model.get('height')
        );

        this.triggerMethod('set:lightset:part', 'bracket', bracketFamily);

        let bracketModelsView = new LightsetWizardFeaturesRowView({
            active: this.model.get('bracketModel'),
            collection: new LightsetWizardFeatureCollection(models)
        });

        this.bracketFamily = bracketFamily;



        this.stopListening(bracketModelsView, 'select:item');
        this.listenTo(bracketModelsView, 'select:item', this.onSelectBracketModel);
        this.showChildView('bottomRow', bracketModelsView);
    },

    onSelectBracketModel(bracketModel) {
        Analytics.track('select_bracket:' + this.bracketFamily.get('name') + ':' + bracketModel.get('name'));

        LightsetWizardDataUtil.updateLuminaireModelByBracket(this.model, this.bracketFamily, this.lightPointType);

        if (this.lightPointType === 'rear') {
            let models = this.bracketFamily.get('models');
            let rearBracketModel = LightsetWizardDataUtil.getRearModel(models, bracketModel);

            this.triggerMethod('set:lightset:part', 'rearBracketModel', rearBracketModel);
        }

        this.triggerMethod('set:lightset:part', 'bracketModel', bracketModel);
    }
});
