import Analytics from 'utils/Analytics';

import AbstractLightsetWizardLayoutView from 'views/lightset-wizard/AbstractLightsetWizardLayoutView';

export default AbstractLightsetWizardLayoutView.extend({

    get stepFunctions() {
        return [
            'closeWizard', // 0
            'showTypeAndHeight', // 1
            'showLuminaires', // 2
            'showBrackets', // 3
            'showPoles', // 4
            'showResult', // 5
            'closeAndPlace' // 6
        ];
    },

    closeWizard() {
        this.triggerMethod('close');
    },

    closeAndPlace() {
        Analytics.track('place_in_composition:button');
        var lightSetController = this.mainRegion.currentView.getLightSetController();

        lightSetController.removeAllEventListeners();
        this.trigger('wizard-complete', lightSetController);
    },
    
    _processResultLabel() {
        return gettext('Place in composition');
    },
    
    _useBackgroundInResultView() {
        return false;
    }
});
