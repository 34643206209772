import Marionette from 'backbone.marionette';
import $ from 'jquery';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import ObjectToolbarItemType from 'models/composer/toolbars/ObjectToolbarItemType';
import ConfirmModel from 'models/ui/confirm/Confirm';
import ConfirmView from 'views/ui/confirm/ConfirmView';

import template from 'templates/composer/toolbars/object-toolbar-item';

export default
    class ObjectToolbarMenuItem
    extends AbstractLayoutView
{
    constructor(params) {
        super(params);
        this._infoIsShown = false;
    }
    
    get tagName() {
        return 'div';
    }
    
    get className() {
        return 'object-toolbar__menu-item';
    }
    
    get template() { 
        return template({ 'attributes': this.model.attributes, 'lightSetComponentDetails': this.lightSetComponentDetails });
    }
    
    get lightSetModel() {
        return this.getOption('lightSetModel');
    }
    
    get lightSetComponentDetails() {
        const lightSetModel = this.getOption('lightSetModel');
        const details = [];
        
        for (let type of ['pole', 'bracket', 'luminaire', 'rearLuminaire']) {
            const component = lightSetModel.get( type + 'Model' );
            const colorModel = lightSetModel.get( type == 'rearLuminaire' ? 'luminaireColor' : type + 'Color' );
            if( component != null && colorModel != null ) {
                details.push({ 'name': component.name, 'color': colorModel.get('name') })
            }
        }
        
        return details;
    }
    
    _defineRegions() {
        return {
            popoverRegion: '.object-toolbar-item-popover-region'
        };
    }
    
    _defineEvents() {
        return {
            'click button': this._onButtonClick
        };
    }
    
    _onButtonClick( evt ) 
    {
        switch( this.model.get('type') ) 
        {
            case ObjectToolbarItemType.INFO:
                evt.stopPropagation();
                this._infoIsShown ? this._hideInfo() : this._showInfo();
                break;
                
            case ObjectToolbarItemType.REMOVE:
                evt.stopPropagation();
                this._showConfirmDelete();
                break;
                
            default:
                this.trigger('activateTool');
                break;
        }
    }
    
    _showConfirmDelete() {
        const confirmModel = new ConfirmModel({
            confirmTxt: gettext('Are you sure you want to remove this Lightset?'),
            confirmLabel: gettext('Remove'),
            cancelLabel: gettext('Cancel'),
            dontShowLabel: gettext('Don\'t show this again')
        });
        
        const confirmView = new ConfirmView({
            orientation: ConfirmView.ORIENTATION_BOTTOM_RIGHT,
            model: confirmModel,
            commandId: 'deletelightset',
            onConfirm: this._deleteLightSet.bind(this)
        });
    
        if (confirmView.needsConfirmation()) {
            this.showChildView('popoverRegion', confirmView);
        } else {
            this._deleteLightSet();
        }
    }
    
    _deleteLightSet() {
        this.trigger('activateTool');
    }
    
    _showInfo() {
        this._infoIsShown = true;
        this.$('.object-toolbar__info-popover').show();
        $('body').one('click', this._hideInfo.bind(this));
    }
    
    _hideInfo() {
        this.$('.object-toolbar__info-popover').hide();
        this._infoIsShown = false;
    }
}
