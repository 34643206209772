import Backbone from 'backbone';

export default
    class LightSetModelSerializer
{
    static serialize( model ) {
        return model.toJSON();
    }
    
    static deserialize( obj ) {
        // If you call the above serialize() on a LightSetController's model, it will be a primitive Object
        // rather than an object that exposes the prototype of a Backbone model. To reintegrate this prototype, we call
        // new Backbone.Model() on the serialized object and voila.
        
        // However, this deserialization is only one level deep and thus models referenced by the attributes property will not be
        // converted back. Hence we loop over all of them and perform the same trick.
        const model = new Backbone.Model(obj);
        
        const keys = [
            'luminaireColor',
            'bracketColor',
            'poleColor'
        ];
        
        for (let key of keys) {
            LightSetModelSerializer._convertObjectToBackboneModel( key, model );
        }
        
        return model;
    }

    static copy( model ) {
        return LightSetModelSerializer.deserialize(LightSetModelSerializer.serialize(model));
    }
    
    static _convertObjectToBackboneModel( key, parentModel ) {
        const obj = parentModel.get( key );
        if (obj) {
            // If we used the above serialize() method, any models referenced by the attributes property will still have their own
            // attributes property (but not the Backbone.Model prototype), so we have to use new Backbone.model( obj.attributes ) to fully 
            // recreate them.
            
            // If however we are deserializing a model that has also been stringified and parses back by JSON (e.g. by using save/restore), the 
            // objects referenced in the attributes will have been completely shallowed out (the referenced models will be replaced by their attributes
            // dictionaries). In that case, we use new Backbone.Model( obj ) directly.
            
            // This simple fix keeps LightSetModelSerializer's serialize and deserialize methods balanced (ObjectToolDuplicate uses them directly), 
            // while also supporting save/restore (SaveUtil and Memento).
            const model = new Backbone.Model( obj.attributes != null ? obj.attributes : obj );
            parentModel.set( key, model );
        }
    }
}