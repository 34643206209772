// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "			<button id=\"replace-lightsets\" class=\"button button--transparent-inverted\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Replace light sets",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <button id=\"email\" class=\"button button--primary-inverted\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"E-mail",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"render__contents render__contents--finished\">\n    <div class=\"render__check\">\n		<svg width=\"90\" height=\"90\" viewbox=\"0 0 150 150\">\n	     	<path class=\"render__check-svg\" d=\"M10,30 l35,46 l80,-60\" />    	\n	    </svg>\n    </div>\n    <h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Rendering completed",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n    <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You can now download your rendering.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n    <div class=\"render__actions\">\n        <button id=\"start-over\" class=\"button button--transparent-inverted\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Back to project",{"name":"trans","hash":{},"data":data}))
    + "</a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allowLightSetReplacement : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <button id=\"download\" class=\"button button--primary-inverted\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Download",{"name":"trans","hash":{},"data":data}))
    + "</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.allowEmail : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});
