import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import ApplicationState from 'app/ApplicationState';
import HiResStageParameters from 'views/canvas/HiResStageParameters';

import IntroLayoutView from 'views/intro/IntroLayoutView';
import ComposerLayoutView from 'views/composer/ComposerLayoutView';
import RenderLayoutView from 'views/render/RenderLayoutView';


export default
    class MainController
    extends Marionette.Object 
{
    constructor(mainRegion, modalOverlayRegion) {
        super();

        ApplicationState.set('target', 'composer');
        ApplicationState.set('composerCanvasParameters', new HiResStageParameters('composition', 900, 600, ApplicationState.get('canvasDpiMultiplier')));

        this.mainRegion = mainRegion;
        this.modalOverlayRegion = modalOverlayRegion;
    }

    showIntro() {
        const model = new Backbone.Model({
            applicationModel: ApplicationState.get('applicationData'),
            image: 'img/intro/intro-lightset-composer.jpg',
            title: gettext('Render tool'),
            intro: gettext('The Render tool enables you to create your own light point solution. You can upload a photo and add light sets to create a realistic preview, which will give you a good impression of the complete lighting set for your project!'),
            language: {
                label: gettext('Select language')
            },
            btn: {
                label: gettext('Start Render tool'),
                href: '#composer'
            }
        });
        
        this._showView(IntroLayoutView, { model: model });
    }

    showComposer() {
        this._showView(ComposerLayoutView);
    }

    showRender() {
        const stage = ApplicationState.get('stage');
        const lightSets = ApplicationState.get('lightSets');
        if (stage != null) {
            const model = new Backbone.Model({
                title: gettext('Untitled project'),
                disclaimerText: gettext('© 2017 Koninklijke Philips N.V. All rights reserved. Philips reserves the right to make changes in specifications and/or to discontinue any product at any time without notice or obligation and will not be liable for any consequences resulting from the use of this publication. www.lighting.philips.com'),
                image: stage,
                lightSets: lightSets,
                indicatorTotal: 1,
                indicatorCurrent: 0
            });
            this._showView(RenderLayoutView, {model: model});
        } else {
            Backbone.history.navigate(ApplicationState.get('target'), { trigger: true, replace: true });
        }
    }

    _showView(LayoutManagerClass, options = null) {
        const manager = new LayoutManagerClass(options);
        if (LayoutManagerClass === RenderLayoutView) {
            this.modalOverlayRegion.show(manager);
            this.modalOverlayRegion.$el.show();
        } else {
            this.modalOverlayRegion.empty();
            this.modalOverlayRegion.$el.hide();
            
            const returningToComposer = (LayoutManagerClass === ComposerLayoutView) && (this.mainRegion.currentView instanceof ComposerLayoutView);
            if (!returningToComposer) {
                // Only if the composer is not already shown, to prevent reset of all the client's hard work :)
                this.mainRegion.show(manager);
            } else if (ApplicationState.get('replacingAllLightSets') === true) {
                // We're returning to the composer after the user clicked "replace all lightsets" in the FinishedView
                ApplicationState.set('replacingAllLightSets', false);
                this.mainRegion.currentView.startReplaceLightSetsFlow();
            }
        }
    }
}
