import Marionette from 'backbone.marionette';
import Analytics from 'utils/Analytics';

import template from 'templates/components/slider-function-button';

export default
	class SliderFunctionButtonView
	extends Marionette.ItemView
{
	onRender() {
        this.$el.on('click', this._handleClick.bind(this));
    }

    _handleClick() {
    	// Rationale: This will cause SceneToolbarItem to trigger a slider:change event.
    	// Bit hackish to piggyback on the default slider event model, but when you think about it,
    	// this is the least intrusive way of implementing this one-off button (select source point for the clone tool).
    	// The SliderFunctionButtonModel is just part of a Sliders array within SceneToolbarItemDataCollection, which
    	// makes it very simple to add this button.
    	// The only code required to make it work is an exception in SceneToolBackgroundClone, where it belongs.
    	// All considered alternatives require a lot of over engineering just to make this single button work, which
    	// I don't prefer.
        Analytics.track(this.model.get('analyticsTag') + ':button');
    	this.model.trigger('change:value', this.model);
    }

    get template() {
        return template;
    }

    get className() {
    	return 'slider--function-button-container';
    }
}