import $ from 'jquery';

import AbstractTool from 'controllers/composer/tools/AbstractTool';

export default
    class AbstractSliderTool
    extends AbstractTool
{
    constructor( id ) {
        super( id );
    }
    
    activate() {
    }
    
    deactivate() {
    }
    
    processInput( value, type ) {
        this._handleSliderValueChange( value, type );
    }
    
    _handleSliderValueChange( value, type ) {
        throw "Abstract method _handleSliderValueChange() not implemented in " + this.constructor.name;
    }

}
