import createjs from 'easeljs';

import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import PointUtil from 'views/utils/PointUtil';

export default
    class MoveWidget
    extends AbstractObjectWidget
{
    constructor( subject, container ) {
        super( subject, container );
        this._defineEventModel();
    }
    
    updateView() {
        // See comments in _defineView.
    }
    
    _defineView() {
        // Don't need much of a view on this one, it's just convenient to put the drag logic in a 
        // widget since we can then piggyback on the existing widget logic:
        // - send events when manipulating the subject or the container, so that history gets recorded
        // - disable any widget visibility or functionality when the container isn't selected
        // - whatever else we decide to implement in the future :)
    }
    
    _defineEventModel() {
        // TODO: remove listeners on cleanup
        for( let evtType of ['mousedown', 'pressmove'] ) {
            // non-createjs way: this.addEventListener( evtType, (evt) => this.handleMouseEvent( evt ) );
            this.subject.on( evtType, this._handleMouseEvent, this );
        }
    }
    
    _handleMouseEvent( evt )  
    {
        // Implement drag & drop, only if touching the editable, not when touching the transform widgets
        if( evt.target === this.subject && this.container.allowWidgetInteraction )
        {
            switch( evt.type )
            {
                case 'mousedown':
                    this._lastRawX = evt.rawX;
                    this._lastRawY = evt.rawY;
                    this._manipulationHasStarted = false;
                    break;

                case 'pressmove':
                    if( !this._manipulationHasStarted ) {
                        this._manipulationHasStarted = true;
                        this._dispatchManipulationStart();
                    }
                    
                    let deltaX = evt.rawX - this._lastRawX;
                    let deltaY = evt.rawY - this._lastRawY;
                    this.container.x += deltaX;
                    this.container.y += deltaY;
                    this._lastRawX = evt.rawX;
                    this._lastRawY = evt.rawY;
                    
                    // TODO: Other widgets don't need to redraw so we may just remove this. We're just following 
                    // standardization here.
                    this._dispatchManipulated();
                    break;
            }
        }
    }
}