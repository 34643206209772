export default
    class StringUtil {

    /**
     * Make a string filename-safe
     * @param  {String} string to convert
     * @return {String} converted string
     */
    static filename(string) {
        return string.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    }
};
