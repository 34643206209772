import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import $ from 'jquery';
import _ from 'underscore';

import Analytics from 'utils/Analytics';
import cookieUtil from 'utils/cookies';
import StorageUtil from 'utils/storage/Storage';

import ApplicationState from 'app/ApplicationState';

import template from 'templates/composer/header/header';

import ConfirmModel from 'models/ui/confirm/Confirm';
import ConfirmView from 'views/ui/confirm/ConfirmView';

import SaveModel from 'models/ui/save/Save';
import SaveView from 'views/ui/save/SaveView';

export default
    class HeaderView 
    extends Marionette.ItemView
{
    get triggers() {
        return {
            'click #open-project': 'import:project'
        };
    }
    
    get className() {
        return 'composer__header';
    }

    get template() {
        return template;
    }
    
    get canvasObjectCount() {
        return this._canvasObjectCount;
    }
    
    get popoverSelector() {
        return '.' + this.className + ' > .popover';
    }
    
    set canvasObjectCount( count ) {
        this._canvasObjectCount = count;
        this.$('#render-image').prop('disabled', count <= 0);
        this.$('#replace-light-sets').prop('disabled', count <= 0);
    }    

    onRender() {
        this.$('#render-image').on('click', this.onRenderClick.bind(this));
        this.$('#select-project-file').on('click', this.onOpenProjectClick.bind(this));
        this.$('#select-project-file').on('change', this.onUploadFile.bind(this));
        this.$('#reset-composition').on('click', this.onResetProjectClick.bind(this));
        this.$('#save-project').on('click', this.onSaveProjectClick.bind(this));
        this.$('#replace-light-sets').on('click', this.onReplaceLightSetsClick.bind(this));
    }

    onReplaceLightSetsClick() {
        Analytics.track('replace-lightsets:button');
        this.triggerMethod('replace:lightsets');
    }

    onResetProjectClick(evt) {
        Analytics.track('reset');
        const resetModel = new ConfirmModel({
            confirmTxt: gettext('Are you sure you want to reset your current project?'),
            confirmLabel: gettext('Reset'),
            cancelLabel: gettext('Cancel'),
            dontShowLabel: gettext('Don\'t show this again')
        });

        const confirmView = new ConfirmView({
            orientation: 'top-right',
            model: resetModel,
            commandId: 'resetComposition',
            onConfirm: this._resetComposition.bind(this),
            onCancel: this._cancelResetComposition.bind(this)
        });

        if( !$(this.popoverSelector).length ) {
            if (confirmView.needsConfirmation() ) {
                $('.composer__header').append(confirmView.render().$el);
            }
        }
    }

    onSaveProjectClick() {
        Analytics.track('save_project:button');
        const saveModel = new SaveModel();
        const saveView = new SaveView({
            orientation: 'default',
            model: saveModel,
            commandId: 'saveProject',
            onConfirm: this._saveComposition.bind(this),
            onCancel: this._cancelSaveComposition.bind(this),
        });

        if( !$(this.popoverSelector).length ) {
            $('.composer').append(saveView.render().$el);
        }
    }

    onOpenProjectClick() {
        Analytics.track('open_project:button');
    }

    _saveComposition() {
        Analytics.track('save_your_project:save:button');
        this.triggerMethod('save:project');
    }

    _cancelSaveComposition() {
        Analytics.track('save_your_project:cancel:button');
    }

    _resetComposition() {
        Analytics.track('reset:button');
        this.triggerMethod('reset:composition');
    }

    _cancelResetComposition() {
        Analytics.track('cancel:button');
    }

    onRenderClick(event) {
        Analytics.track('render_image:button');
        event.preventDefault();
        this.triggerMethod('render:composition');
    }

    /**
     * Style of import depends on online or app usage
     */
    onImportProject() {
        if(ApplicationState.get('cordova')) {
            StorageUtil.read(this.loadProjectData.bind(this));
        } else {
            this.$('#select-project-file').click();
        }
    }

    onUploadFile(event) {
        let file = event.target.files[0];
        const isJsonFile = file.name.toLowerCase().indexOf('.json') > -1;

        if (!isJsonFile) {
            this.triggerMethod('show:error', gettext('Unable to upload file'), gettext('The file must be a valid project save file (JSON).'));
        } else {
            let fileReader = new FileReader();
            fileReader.onload = this.loadProjectData.bind(this);
            fileReader.readAsText(file);
        }
    }

    loadProjectData(progressEvt) {
        const data = progressEvt.target.result;
        this.triggerMethod('project:loaded', data);
    }
}
