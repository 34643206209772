import Marionette from 'backbone.marionette';

export default
    class MainRegion
    extends Marionette.Region {
    constructor() {
        super({
            el: '#main'
        });
    }
}
