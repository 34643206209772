import createjs from 'easeljs';

export default
    class AbstractObjectWidget
    extends createjs.Container
{
    // Events
    
    // Dispatched right before the object starts to be manipulated. At this moment, history will be written :)
    static get MANIPULATION_START() { return 'WIDGET_MANIPULATION_START'; }
    
    // Dispatched every single time the widget manipulates properties of the parent. Other widgets will update their views accordingly.
    static get MANIPULATION_UPDATE() { return 'WIDGET_MANIPULATED'; }
    
    // Dispatched when the user releases the widget.
    static get MANIPULATION_END() { return 'WIDGET_MANIPULATION_END'; }
    
    constructor( subject, container ) 
    {
        super();
        
        this._subject = subject;
        this._container = container;
        
        this._defineView();
        this.updateView();
    }
    
    get subject() {
        return this._subject;
    }
    
    get container() {
        return this._container;
    }
    
    // Don't participate in aggregate getBounds() calculations of the parent Container.
    // We are basically a GUI widget on top of the parent container, in which the parent
    // container has no interest.
    getBounds() {
        return null;
    }
    
    // If this widget manipulates its subject, any sibling widgets do not automatically know about this.
    // They should, because they probably need to update their view positions etc. 
    // Hence, some superview (observer, typically CanvasObject) should be responsible for detecting the change 
    // and then calling updateView() on all attached widgets. And then call stage.update(), since if widgets 
    // would do that, you'd get many duplicate canvas redraws.
    updateView() {
        // Implement view repositioning / updating logic in subclasses
        throw "Abstract public updateView() not implemented";
    }
    
    _defineView() {
        // Implement view drawing logic in subclasses
        throw "Abstract protected _defineView() not implemented";
    }
    
    _dispatchManipulationStart() {
        this.__notifyObservers( AbstractObjectWidget.MANIPULATION_START );
    }
    
    _dispatchManipulated() {
        this.__notifyObservers( AbstractObjectWidget.MANIPULATION_UPDATE );
    }
    
    _dispatchManipulationEnd() {
        this.__notifyObservers( AbstractObjectWidget.MANIPULATION_END );
    }
    
    __notifyObservers( evtType ) {
        let evt = new createjs.Event( evtType );
        this.dispatchEvent( evt );
    }
}