import Marionette from 'backbone.marionette';
import Analytics from 'utils/Analytics';
import StorageUtil from 'utils/storage/Storage';

import ApplicationState from 'app/ApplicationState';

import template from 'templates/composer/upload/upload';

export default
    class UploadView extends Marionette.ItemView {

    static get MIN_IMAGE_WIDTH() {
        return 1280;
    }

    static get MIN_IMAGE_HEIGHT() {
        return 800;
    }

    get triggers() {
        return {
            'click #upload-project-button': 'import:project'
        };
    }

    constructor(options) {
        options.events = {
            'change #background-selector': '_handleFileChange',
            'dragover .image-uploader': '_handleDragEnter',
            'dragenter .image-uploader': '_handleDragEnter',
            'dragleave .image-uploader': '_handleDragLeave',
            'dragend .image-uploader': '_handleDragLeave',
            'drop .image-uploader': '_handleDrop',
            'click #upload-image-button': '_handleUploadImageButtonClick',
            'click #upload-project-button': '_handleUploadProjectButtonClick',
        };

        super(options);
    }

    get className() {
        return 'composer__image-uploader';
    }

    get template() {
        return template;
    }

    _handleUploadImageButtonClick(evt) {
        Analytics.track('upload_image:button');
    }

    _handleUploadProjectButtonClick(evt) {
        Analytics.track('open_project:button');
    }

    _handleDragEnter(event) {
        event.preventDefault();
        event.stopPropagation();
        this.$el.find('.image-uploader').addClass('image-uploader--over');
    }

    _handleDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.$el.find('.image-uploader').removeClass('image-uploader--over');
    }

    _handleDrop(event) {
        Analytics.track('upload_image:button');
        event.preventDefault();
        event.stopPropagation();

        this.$el.find('.image-uploader').removeClass('image-uploader--over');
        let file = event.originalEvent.dataTransfer.files[0];

        this._onFilePicked(file);
    }

    _handleFileChange(event) {
        let file = event.target.files[0];

        this._onFilePicked(file);
    }

    _onFilePicked(file) {
        let fileReader = new FileReader();

        const isJsonFile = file.name.toLowerCase().indexOf('.json') > -1;

        if (isJsonFile) {
            fileReader.onload = this.loadProjectData.bind(this);
            fileReader.readAsText(file);
        } else {
            fileReader.onload = function(progressEvt) {
                const data = progressEvt.target.result;

                if (data.indexOf('image/jpeg') > -1 || data.indexOf('image/png') > -1) {
                    var img = new Image();

                    img.onload = function() {
                        if (img.width < UploadView.MIN_IMAGE_WIDTH || img.height < UploadView.MIN_IMAGE_HEIGHT) {
                            this.triggerMethod('show:error', gettext('Unable to upload image'), gettext('Please upload an image that is at least ${UploadView.MIN_IMAGE_WIDTH} pixels wide and ${UploadView.MIN_IMAGE_HEIGHT} pixels tall'));

                            return;
                        }

                        this.triggerMethod('image:loaded', img);
                    }.bind(this);

                    img.onerror = function() {
                        this.triggerMethod('show:error', gettext('Unable to upload image'), gettext('The file that you tried to upload is not a valid image.'));
                    }.bind(this);

                    img.src = progressEvt.target.result;
                }
            }.bind(this);

            fileReader.readAsDataURL(file);
        }
    }

    onImportProject() {
        if(ApplicationState.get('cordova')) {
            StorageUtil.read(this.loadProjectData.bind(this));
        } else {
            this.$('#background-selector').click();
        }
    }

    loadProjectData(progressEvt) {
        const data = progressEvt.target.result;
        this.triggerMethod('project:loaded', data);
    }
}
