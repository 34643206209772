import ApplicationState from 'app/ApplicationState';


export default class Storage {

    static get handler() {
        if (ApplicationState.get('cordova')) {
            return require('utils/storage/Cordova').default;
        } else {
            return require('utils/storage/Online').default;
        }
    }

    static save(blob) {
        Storage.handler.save(blob);
    }  
 
    static read(callback) {
        Storage.handler.read(callback);
    }
}
