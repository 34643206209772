// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"composer-header__row\">\n	<div class=\"composer-header__left\">\n	</div>\n	<div class=\"composer-header__center\"></div>\n	<div class=\"composer-header__right\">\n		<button id=\"reset-composition\" class=\"text-button\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reset",{"name":"trans","hash":{},"data":data}))
    + " <span class=\"icon-reset\"></span></button>\n	</div>\n</div>\n<div class=\"composer-header__row\">\n	<div class=\"composer-header__left\">\n		<input id=\"select-project-file\" type=\"file\" class=\"composer-header__hidden-input\" name=\"files[]\" />\n		<button id=\"open-project\" class=\"button button--transparent\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Open project",{"name":"trans","hash":{},"data":data}))
    + "</button>\n		<button id=\"save-project\" class=\"button button--transparent\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Save project",{"name":"trans","hash":{},"data":data}))
    + "</button>\n	</div>\n    <div class=\"composer-header__center\">\n	</div>\n	<div class=\"composer-header__right\">\n        <button id=\"replace-light-sets\" class=\"button button--primary\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Replace light sets",{"name":"trans","hash":{},"data":data}))
    + "</button>\n        <button id=\"render-image\" class=\"button button--primary\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Render image",{"name":"trans","hash":{},"data":data}))
    + "</button>\n	</div>\n</div>\n";
},"useData":true});
