import Backbone from 'backbone';

export default
    class ObjectToolbarItemData
    extends Backbone.Model 
{
    get defaults() {
        return {
            isActive: false,
            stateless: false
        };
    }
}
