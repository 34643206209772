import AbstractSliderTool from 'controllers/composer/tools/scene/AbstractSliderTool';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

export default 
    class AbstractBackgroundBitmapTool
    extends AbstractSliderTool
{
    constructor( id ) {
        super( id );
        this._stageListeners = {};
    }
    
    get compositionView() {
        return this.context.compositionView;
    }
    
    get canActivate() {
        return this.compositionView && this.compositionView.backgroundObject && this.compositionView.backgroundObject.bitmap != null;
    }
    
    activate() {
        this._setStageListeners( true );
    }
    
    deactivate() {
        this._setStageListeners( false );
    }
    
    _setStageListeners( add ) 
    {
        let types = ['mousedown', 'pressmove', 'click']
        let stage = this.compositionView.stage;
        stage.mouseChildren = !add;
        
        if( add ) {
            for( let type of types ) {
                if( !(type in this._stageListeners) ) {
                    this._stageListeners[type] = stage.on( type, this._handleStageMouseEvent, this );
                }
            }
        } else { 
            for( let type in this._stageListeners ) {
                stage.off( type, this._stageListeners[type] );
            }
            this._stageListeners = {};
        } 
    }
    
    _handleStageMouseEvent( evt ) 
    {
        let bmp = this.compositionView.backgroundObject.bitmap;
        let localPoint = bmp.globalToLocal( evt.rawX, evt.rawY );
        
        switch( evt.type ) {
            case 'mousedown':
                bmp.uncache();
                this._ticker = 0;
                this.compositionView.backgroundObject.dispatchEvent( CanvasObjectEvent.WILL_BE_EDITED );
                this._editBitmapAt( bmp, localPoint.x, localPoint.y );
                break;
                
            case 'pressmove':
                this._ticker += 1;
                if( this._ticker > 2 ) { // Don't respond to every single mouse move. The goal is not to blow up the end user system.
                    this._ticker = 0;
                    this._editBitmapAt( bmp, localPoint.x, localPoint.y );
                    this.compositionView.backgroundObject.dispatchEvent( CanvasObjectEvent.HAS_UPDATED );
                }
                break;
                
            case 'click':
                if( this._ticker > 0 ) { // Only perform an edit on mouseup if the mouse was moved but the ticker didnt fire
                    this._editBitmapAt( bmp, localPoint.x, localPoint.y );
                }
                bmp.cache( 0, 0, bmp.image.width, bmp.image.height );
                this.compositionView.backgroundObject.dispatchEvent( CanvasObjectEvent.HAS_UPDATED );
                break;
        }
    }
    
    _editBitmapAt( bmp, x, y ) {
        throw "Abstract method editBitmapAt() not implemented in " + this.constructor.name;
    }
}