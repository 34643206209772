import createjs from 'easeljs';

import ApplicationState from 'app/ApplicationState';

export default
	class AbstractWidgetGraphic
	extends createjs.Container
{
	constructor() {
		super();

		const canvasParameters = ApplicationState.get('composerCanvasParameters');
		this.scaleX = this.scaleY = canvasParameters.dpiMultiplier; // Scale the icons back to the expected size
	}
}