// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "					<li class=\"popover__list-item\">"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + ", "
    + alias2(alias1((depth0 != null ? depth0.color : depth0), depth0))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : {};

  return "<div class=\"object-toolbar__menu-item\">\n	<button id=\"object-toolbar__"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.type : stack1), depth0))
    + "-button\" class=\"object-toolbar__menu-button\" type=\"button\">\n		<span class=\"object-toolbar__menu-button-icon icon-"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.type : stack1), depth0))
    + "\"></span>\n		<span class=\"object-toolbar__menu-button-label\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.attributes : depth0)) != null ? stack1.label : stack1), depth0))
    + "</span>\n	</button>\n	<div class=\"object-toolbar-item-popover-region object-toolbar__menu-item-popover\"></div>\n	<div class=\"object-toolbar__menu-item-popover object-toolbar__info-popover\">\n		<div class=\"popover\">\n			<p class=\"popover__title\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias3,"Your light set",{"name":"trans","hash":{},"data":data}))
    + "</p>\n			<ul class=\"popover__list\">\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.lightSetComponentDetails : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "			</ul>\n		</div>\n	</div>\n</div>\n";
},"useData":true});
