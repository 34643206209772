import $ from 'jquery';

import CanvasObject from 'views/composer/composition/objects/CanvasObject';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

import ConfirmModel from 'models/ui/confirm/Confirm';
import ConfirmView from 'views/ui/confirm/ConfirmView';

import MoveWidget from 'views/composer/composition/objects/widgets/MoveWidget';
import LineTransformWidget from 'views/composer/composition/objects/widgets/LineTransformWidget';
import LineDeleteWidget from 'views/composer/composition/objects/widgets/LineDeleteWidget';


export default
    class LineObject
    extends CanvasObject 
{
    constructor( line, widgetClasses = null ) {
        super( line, widgetClasses );
    }
    
    get defaultWidgetClasses() {
        return [MoveWidget, LineTransformWidget, LineDeleteWidget];
    }
    
    get mementoPropertyMap() {
        // Make sure the undo history records the movement of the line
        return super.mementoPropertyMap.concat([
            { _subject: ['pointA', 'pointB'] }
        ]);
    }

    _onWidgetCreated( widget ) {
        if( widget instanceof LineDeleteWidget ) {
            widget.on( 'click', this._onDeleteWidgetClick, this );
        }
    }

    _onDeleteWidgetClick( evt ) {
        let point = new createjs.Point(evt.rawX, evt.rawY);
        let stageWidth = evt.target.stage.canvas.width;
        let stageHeight = evt.target.stage.canvas.height;

        let confirmModel = new ConfirmModel({
            confirmTxt: 'Are you sure you want to remove this line?',
            confirmLabel: 'Remove',
            cancelLabel: 'Cancel',
            dontShowLabel: 'Don\'t show this again'
        });

        let confirmView = new ConfirmView({
            orientation: this._getOrientation(evt.target.x, evt.target.y, stageWidth, stageHeight),
            x: evt.target.x,
            y: evt.target.y,
            model: confirmModel,
            commandId: 'deleteline',
            onConfirm: this.requestDelete.bind(this)
        });

        if (confirmView.needsConfirmation()) {
            $('#composition-wrapper').append(confirmView.render().$el);
        } else {
            this.requestDelete();
        }
    }

    _getOrientation(x, y, width, height) {
        let orientation;

        switch (true) {
            case x < width / 2 && y < height / 2: {
                orientation = ConfirmView.ORIENTATION_TOP_LEFT;
                break;
            }

            case x >= width / 2 && y < height / 2: {
                orientation = ConfirmView.ORIENTATION_TOP_RIGHT;
                break;
            }

            case x < width / 2 && y >= height / 2: {
                orientation = ConfirmView.ORIENTATION_BOTTOM_LEFT;
                break;
            }

            case x >= width / 2 && y >= height / 2: {
                orientation = ConfirmView.ORIENTATION_BOTTOM_RIGHT;
                break;
            }
        }

        return orientation;
    }
}
