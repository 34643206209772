import createjs from 'easeljs';

/*

Layers are arranged as follows:

- background (contains the background images)

- main (contains the pole, bracket (if applicable), luminaire and optional rearLuminaire, in case of Double brackets)
	- pole
	- bracket
	- luminaire
	- rearLuminaire (pointType: double)

The sublayers (pole, bracket etc) are created on-demand when the LightSetController
pushes bitmaps to this container. The main layers are created here only to have the 
correct Z order.

The layer order depends entirely on the kind of luminaire we're dealing with; 
suspended and/or side-entry. See arrangeLayers() for details.

Example part of a layer tree:

BACKGROUND
MAIN
  LUMINAIRE
  	NOCOLOR
  	COLOR1
  	COLOR2
  BRACKET
  	NOCOLOR
  	COLOR1
  POLE
  	NOCOLOR
  	COLOR2

*/


class Layer
	extends createjs.Container
{
	get layers() {
		return this._layers;
	}

	get bitmaps() {
		const ret = [];
		for (let child of this.children) {
			if (child instanceof createjs.Bitmap) {
				ret.push(child);
			}
		}
		return ret;
	}

	constructor(name="layer") {
		super();

		this.name = name;
		this._layers = {};
	}

	addBitmap(bmp, layerPath=[]) {
		if (layerPath.length) {
			layerPath = layerPath.slice();
			const name = layerPath.shift();
			if (!this.layers[name]) {
				this.createLayer(name);
			}
			this.layers[name].addBitmap(bmp, layerPath);
		} else {
			this.addChild(bmp);
		}
	}

	clear() {
		for (let bmp of this.bitmaps) {
			this.removeChild(bmp);
			bmp.onload = null;
		}

		for (let name in this._layers) {
			this._layers[name].clear();
		}
	}

	getLayer(name) {
		let layer = this.layers[name];
		if (!layer) {
			layer = this.createLayer(name);
		}
		return layer;
	}

	createLayer(name) {
		const layer = new Layer(name);
		this.layers[layer.name] = layer;
		this.addChild(layer);
		return layer;
	}
}

export default
	class LightSetImageContainer
	extends Layer
{

	// Primary layers

	// The lowest layer for the background bitmaps, contains nu sublayers
	static get BACKGROUND() {
		return 'background';
	}

	// Contains all the main luminaire parts, everything except for the background
	static get MAIN() {
		return 'main';
	}


	// Secondary layers

	// The second luminaire of a double bracket configuration
	// In front of the bracket in case of suspended luminaires, behind bracket in case of top or side entry luminaires
	static get REAR_LUMINAIRE() {
		return 'rearLuminaire';
	}

	// The primary luminaire, either from the MAIN or REAR layer
	// In front of the bracket in case of suspended or side entry luminaires, behind bracket in case of top luminaires
	static get LUMINAIRE() {
		return 'luminaire';
	}

	// The bracket, either from the MAIN or REAR layer
	// Position varies on light point type, as with a double bracket configuration, this layer is 'between' the two luminaires
	static get BRACKET() {
		return 'bracket';
	}

	// The pole
	// Always positioned on top since you look up against the luminaire, so the pole is always in front
	static get POLE() {
		return 'pole';
	}

	static get NOCOLOR() 	{ return 'noColor';	}	// The leaf layer containing the bitmaps that should not be colored
	static get COLOR1() 	{ return 'color1'; 	}	// The leaf layer containing the bitmaps receiving the primary color
	static get COLOR2()		{ return 'color2'; 	}	// The leaf layer containing the bitmaps receiving the secondary color (same as primary until implemented later)


	constructor()
	{
		super();

		// Create the initial, root layers in correct order.
		// The sublayers are created lazily by Layer.addBitmap().
		this.createLayer(LightSetImageContainer.BACKGROUND);
		this.createLayer(LightSetImageContainer.MAIN);
	}

	colorBitmapsOfType(type, colorModel) 
	{
		const mainLayer = this.layers[LightSetImageContainer.MAIN];
		
		const layers = [mainLayer.getLayer(type)];
		if (type == LightSetImageContainer.LUMINAIRE) {
			// Also take the second luminaire from the possible double bracket...
			layers.push(mainLayer.getLayer(LightSetImageContainer.REAR_LUMINAIRE));
		}

		let hasAppliedColor = false;

		for (let layer of layers) {
			const colorableLayers = [
				layer.getLayer(LightSetImageContainer.COLOR1), 
				layer.getLayer(LightSetImageContainer.COLOR2)
			];

			for (let colorableLayer of colorableLayers) {
				const bitmaps = colorableLayer.bitmaps;
				for (let bmp of bitmaps) {
					bmp.filters = [
	                	new createjs.ColorFilter(colorModel.get('r') / 150, colorModel.get('g') / 150, colorModel.get('b') / 150)
    	        	];
            		
        	    	bmp.updateCache();
        	    	hasAppliedColor = true;
				}
			}
		}

        return hasAppliedColor;
	}

    arrangeLayers(suspended=false, sideEntryLuminaire=false, poleMountingBracket=false, spiralLuminaire=false) {

        const order = [];

        /**
			Arguably there are more readable ways of configuring the layer orders
			but remember that a lot of scenario's need to be captured, just like the logic in the 
			LightSetController. The business rules are as follows:

			* If a luminaire is suspended, it should appear in front of the bracket, since you look up to the luminaire
			* Conversely, if a luminaire is mounted on top of the bracket, it should appear behind the bracket. 

			* If a luminaire is mounted to the sides of the bracket (side entry), the left luminaire appears behind the bracket
			  and the right luminaire appears in front of the bracket

            * If a luminaire is spiral that means the there is one visual showing 3 or 4 luminaires, in this case the
            * the layer needs to be on top of the pole instead of behind

			* If the bracket is mounted to the top of the pole, it appears behind the pole
			* Conversely, pole side entry brackets need to be in front of the pole since their image (adapter part) 'wraps' the pole

			We can assume that lightsets cannot rotate far enough to cause the luminaire to overlap the pole, or else we'd need
			some additional scenario's.

			Now the code below doesn't look so complex, does it ;-)
		*/

		if (!suspended) {
			order.push(LightSetImageContainer.REAR_LUMINAIRE); // post-top/side-entry rear luminaire appears 'behind' the bracket
			if (!sideEntryLuminaire && !spiralLuminaire) {
				order.push(LightSetImageContainer.LUMINAIRE); // post-top main luminaires appear 'behind' the bracket
			}
		}

		if (poleMountingBracket) {
			order.push(LightSetImageContainer.POLE); // pole mounting brackets 'wrap' the pole with adapter, so must appear on top	
		}
		
		order.push(LightSetImageContainer.BRACKET); // place the bracket

		if (suspended) {
			order.push(LightSetImageContainer.REAR_LUMINAIRE); // suspended rear luminaire appears in front of the bracket
		}

		// Place the rest in logical order if not added yet
		order.indexOf(LightSetImageContainer.POLE) < 0 && order.push(LightSetImageContainer.POLE);
		order.indexOf(LightSetImageContainer.LUMINAIRE) < 0 && order.push(LightSetImageContainer.LUMINAIRE);

		// Iterate the primary layers and sort all their sublayers equally.
		const mainLayer = this.layers[LightSetImageContainer.MAIN];
		for (let layerName of order) {
			const layer = mainLayer.layers[layerName];
			layer && mainLayer.addChild(layer);
		}	
	}
}
