import createjs from 'easeljs';
import async from 'async';

import AbstractWidgetGraphic from 'views/composer/composition/objects/widgets/graphics/AbstractWidgetGraphic';

export default
    class DeleteIcon
    extends AbstractWidgetGraphic
{
    // Todo: refactor this to a button class :)
    constructor() {
        super();
        
        let calls = [];
        
        for( let src of ['img/components/icon-delete.png', 'img/components/icon-delete-hover.png'] ) {
            calls.push( function( callback ) {
                this._loadBitmap( src, callback );
            }.bind(this));
        }
        
        async.series( calls, function( error, bitmaps ) {
            this.iconHover = bitmaps.pop();
            this.addChild( this.iconHover );
            this.iconNormal = bitmaps.pop();
            this.addChild( this.iconNormal );
        }.bind( this ));
        
        for( let evtType of ['mouseover', 'mouseout'] ) {
            this.addEventListener( evtType, (evt) => this._onMouseEvent( evt ) );
        }
        
        this.mouseChildren = false;
        this.cursor = 'pointer';
    }
    
    _loadBitmap( src, callback ) {
        let img = new Image();
        let bmp = new createjs.Bitmap( img );
        
        img.onload = function() {
            bmp.regX = img.width / 2;
            bmp.regY = img.height / 2;
            callback( null, bmp );
        }
        
        img.src = src;
        return bmp;
    }
    
    _onMouseEvent( evt ) {
        switch( evt.type ) {
            case 'mouseover':
                this._makeTopmost( this.iconHover );
                break;
                
            case 'mouseout':
                this._makeTopmost( this.iconNormal );
                break;
        }
    }
    
    _makeTopmost( icon ) {
        if( this.getChildIndex( icon ) < this.numChildren - 1 ) {
            this.addChild( icon );
            this.stage && this.stage.update();
        }
    }
}