import SliderView from 'views/components/SliderView';
import $ from 'jquery';

export default
    class CropRotateSliderView
    extends SliderView
{    
    get ui() {
        if (!this.__ui) {
            this.__ui = super.ui;
            $.extend( this.__ui, {
                rotateLeft: '.slider__rotate--left',
                rotateRight: '.slider__rotate--right'
            });
        }
        return this.__ui;
    }
    
    set ui( ui ) {
        this.__ui = ui;
    }
    
    get events() {
        if (!this.__events) {
            this.__events = {
                'click @ui.rotateLeft': function( evt ) {
                    this.coarseValue -= 90;
                },
                'click @ui.rotateRight': function( evt ) {
                    this.coarseValue += 90;
                }
            }
        }
        return this.__events;
    }
    
    set events( events ) {
        this.__events = events;
    }
    
    get coarseValue() {
        return this.model.get('coarseValue');
    }
    
    set coarseValue( value ) {
        this.model.set('coarseValue', value % 360);
    }
        
    onShow() {
        super.onShow();
    }
}