import FileSaver from 'filesaverjs';


export default class Online {

    static get filename() {
        let date = new Date().toISOString();
        date = date.substring(0, date.length - 5); // Too lazy to use Date.js/Moment.js to remove ms using a time format ;)
        return `Project_${date}.json`;
    }

    static save(blob) {
        FileSaver.saveAs(blob, Online.filename);
    }

    static read() {
        // console.log('TODO: Implement');
    }
}
