import Marionette from 'backbone.marionette';

import template from 'templates/ui/select/select-option';

var SelectItemView = Marionette.ItemView.extend({

    className: 'select__option',

    template: template,

    triggers: {
        'click': 'select:option'
    },

    modelEvents: {
        'change:selected': 'onChangeSelected'
    },

    initialize() {
        if (this.model && this.model.get('selected')) {
            this.toggleSelectedClass(true);
        }
    },

    onChangeSelected(model, isSelected) {
        this.toggleSelectedClass(isSelected);
    },

    toggleSelectedClass(isSelected) {
        this.$el.toggleClass('select__option--selected', isSelected);
    }
});

export default SelectItemView;
