import Analytics from 'utils/Analytics';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';

import EnterTitleView from 'views/render/EnterTitleView';
import RenderingView from 'views/render/RenderingView';
import FinishedView from 'views/render/FinishedView';

import template from 'templates/render/layout';

export default
    class RenderLayoutView 
    extends AbstractLayoutView 
{
    get template() {
        return template;
    }

    get className() {
        return 'render';
    }

    get childEvents() {
        return {
            'to:render': this._onToRender,
            'to:cancel': this._onCancel,
            'render:completed': this._onRenderCompleted
        };
    }

    _onToRender() {
        Analytics.track('render_image:button');
        this.showChildView('mainRegion', new RenderingView({model: this.model}));
    }

    _onCancel() {
        Analytics.track('cancel:button');
        window.history.go(-1);
    }

    _onRenderCompleted() {
        this.showChildView('mainRegion', new FinishedView({model: this.model}));
    }

    _defineRegions() {
        return {
            'mainRegion': '#render-main-region'
        };
    }

    onRender() {
        this.showChildView('mainRegion', new EnterTitleView({model: this.model}));
    }
}
