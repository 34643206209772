import Marionette from 'backbone.marionette';
import template from 'templates/lightset-wizard/lightset-wizard-no-data-available';

export default 
	class LightsetWizardNoDataAvailableView
	extends Marionette.ItemView
{
	constructor(options={}) {
		super(options);
	}

	get className() {
		return 'lightset-wizard__feature-carousel no-data';
	}

	get tagName() {
		return 'div';
	}
    
    get template() {
    	return template;
    }
};
