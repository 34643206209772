import Backbone from 'backbone';

export default
    class ToolbarModel
    extends Backbone.Model
{
    static get ACTIVE_TOOL() { return 'activeTool'; }
    
    constructor( itemCollection ) {
        super({
            itemCollection: itemCollection
        });
    }
    
    get defaults() {
        return {
            activeTool: null
        }
    }
}