import Backbone from 'backbone';

export default
    class LightsetWizardFeatureModel extends Backbone.Model {

    constructor(params) {
        params = params || {};
        super(params);
    }
}
