import Backbone from 'backbone';

import ObjectToolbarItemType from 'models/composer/toolbars/ObjectToolbarItemType';
import ObjectToolbarItemData from 'models/composer/toolbars/ObjectToolbarItemData';
import SliderData from 'models/components/SliderData';

export default
    class ObjectToolbarItemDataCollection
    extends Backbone.Collection
{   
    static get defaults() {
        return [
            {
                type: ObjectToolbarItemType.ROTATE,
                label: gettext('Rotate'),
                stateless: true
            },
            {
                type: ObjectToolbarItemType.FLIP,
                label: gettext('Flip'),
                stateless: true
            },
            {
                type: ObjectToolbarItemType.COLOR,
                label: gettext('Color'),
                stateless: false
            },
            {
                type: ObjectToolbarItemType.INFO,
                label: gettext('Set info'),
                stateless: false
            },
            {
                type: ObjectToolbarItemType.DUPLICATE,
                label: gettext('Duplicate'),
                stateless: true,
            },
            {
                type: ObjectToolbarItemType.ERASER,
                label: gettext('Eraser'),
                stateless: false,
                slider: new SliderData({
                    value: 30,
                    min: 10,
                    max: 100,
                    step: 0,
                    orientation: 'horizontal'
                })
            },
            {
                type: ObjectToolbarItemType.REMOVE,
                label: gettext('Remove'),
                stateless: true
            }
        ];
    }

    setAttributes(attributes) {
        this.invoke('set', attributes);
    }

    constructor(data = null) {
        super(data ? data : ObjectToolbarItemDataCollection.defaults);
    }

    get model() {
        return ObjectToolbarItemData;
    }
}