import AbstractToolkitController from 'controllers/composer/tools/AbstractToolkitController';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';

import SceneToolEdit from 'controllers/composer/tools/scene/SceneToolEdit';
import SceneToolBackgroundCrop from 'controllers/composer/tools/scene/SceneToolBackgroundCrop';
import SceneToolBackgroundExposure from 'controllers/composer/tools/scene/SceneToolBackgroundExposure';
import SceneToolBackgroundLuminosity from 'controllers/composer/tools/scene/SceneToolBackgroundLuminosity';
import SceneToolBackgroundContrast from 'controllers/composer/tools/scene/SceneToolBackgroundContrast';
import SceneToolBackgroundClone from 'controllers/composer/tools/scene/SceneToolBackgroundClone';
import SceneToolBackgroundBlur from 'controllers/composer/tools/scene/SceneToolBackgroundBlur';
import SceneToolAddLine from 'controllers/composer/tools/scene/SceneToolAddLine';
import SceneToolUndo from 'controllers/composer/tools/scene/SceneToolUndo';


export default
    class SceneToolkitController
    extends AbstractToolkitController
{
    constructor() {
        super();
    }
    
    // Abstract method overrides
    _registerTools() 
    {
        let map = [
            SceneToolbarItemDataCollection.EDIT,        SceneToolEdit,
            SceneToolbarItemDataCollection.CROP,        SceneToolBackgroundCrop,
            SceneToolbarItemDataCollection.EXPOSURE,    SceneToolBackgroundExposure,
            SceneToolbarItemDataCollection.LUMINOSITY,  SceneToolBackgroundLuminosity,
            SceneToolbarItemDataCollection.CONTRAST,    SceneToolBackgroundContrast,
            SceneToolbarItemDataCollection.CLONE,       SceneToolBackgroundClone,
            SceneToolbarItemDataCollection.BLUR,        SceneToolBackgroundBlur,
            SceneToolbarItemDataCollection.ADD_LINE,    SceneToolAddLine,
            SceneToolbarItemDataCollection.UNDO,        SceneToolUndo
        ];
        
        for( let i = 0; i < map.length; i += 2 ) {
            let toolId = map[i];
            let ToolClass = map[i + 1];
            this._registerTool( toolId, ToolClass );
        }
    }
}
