import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import template from 'templates/lightset-wizard/lightset-wizard-features';
import LightsetWizardFeatureItemView from 'views/lightset-wizard/LightsetWizardFeatureItemView';
import LightsetWizardFeatureCollection from 'models/lightset-wizard/FeatureCollection';
import LightsetWizardFeatureCollectionView from 'views/lightset-wizard/LightsetWizardFeatureCollectionView';

export default Marionette.LayoutView.extend({
    className: 'lightset-wizard__features-view',

    template: template,

    regions: {
        topRow: '#lightset-wizard-top-row-region',
        bottomRow: '#lightset-wizard-bottom-row-region'
    }
});
