import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import ApplicationState from 'app/ApplicationState';

import template from 'templates/render/enter-title';

export default
    class EnterTitleView 
    extends Marionette.ItemView 
{
    onRender() {
        this.$('#composition-title-input').on('change', this._onTitleChange.bind(this));
        const img = this.$('#composition-preview-image');
        const params = ApplicationState.get('composerCanvasParameters');
        img.attr('width', params.width * params.dpiMultiplier);
        img.attr('height', params.height * params.dpiMultiplier);
        img.css('width', params.width);
        img.css('height', params.height);
    }

    get template() {
        return template;
    }

    get triggers() {
        return {
            'click #to-render-screen': 'to:render',
            'click #cancel-and-close': 'to:cancel'
        };
    }

    _onTitleChange(event) {
        this.model.set('title', event.target.value);
    }
}
