import createjs from 'easeljs';

import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import DeleteIcon from 'views/composer/composition/objects/widgets/graphics/DeleteIcon';

export default
    class LineDeleteWidget
    extends AbstractObjectWidget
{
    constructor( line, container ) {
        super( line, container );
    }
    
    updateView()
    {
        let pA = this.subject.pointA;
        let pB = this.subject.pointB;
        let center = new createjs.Point( (pA.x + pB.x) / 2, (pA.y + pB.y) / 2 );
        this.icon.x = center.x;
        this.icon.y = center.y;
    }
    
    _defineView()
    {
        this.widgetMargin = 0;
        
        this.icon = new DeleteIcon();
        this.addChild( this.icon );
    }
}