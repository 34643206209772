import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';

import SliderView from 'views/components/SliderView';
import SliderData from 'models/components/SliderData';

import template from 'templates/composer/toolbars/object-toolbar-slider';

export default
    class ObjectToolbarSliderLayoutView
    extends AbstractLayoutView {

    get className() {
        return 'object-toolbar__slider-view';
    }

    get template() {
        return template;
    }

    get triggers() {
        return {
            'click .object-toolbar__back-button': 'show:menu'
        };
    }

    onBeforeShow() {
        const sliderModel = this.model.get('slider');
        this.showChildView( 'sliderRegion', new SliderView({ model: sliderModel }) );
        this.listenTo( sliderModel, 'change:value', this._onSliderValueChanged );
    }
    
    _onSliderValueChanged( sliderModel, value ) {
        this.model.trigger( 'slider:change', sliderModel, value );
    }
    
    _defineRegions() {
        return {
            'sliderRegion': '#object-toolbar-slider-region'
        };
    }
}
