import $ from 'jquery';
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import SelectLayoutView from 'views/ui/select/SelectLayoutView';

import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

import template from 'templates/composer/toolbars/object-toolbar-color-select';

export default
    class ObjectToolbarColorSelectLayoutView
    extends AbstractLayoutView 
{
    get className() {
        return 'object-toolbar__color-select-view';
    }

    get template() {
        return template;
    }
    
    get triggers() {
        return {
            'click .object-toolbar__back-button': 'show:menu'
        };
    }
    
    onBeforeShow() {
        let colors = new Backbone.Collection(LightsetWizardDataUtil.getColors());
        
        this._colorModels = [];
        this.$('.object-toolbar__tab').on('click', this.onClickTab.bind(this));
        
        const parts = [{
            name: 'global',
            color: this.model.get('globalColor')
        }, {
            name: 'luminaire',
            color: this.model.get('luminaireColor')
        }];
        
        if( this.model.get('bracketModel') ) {
            parts.push({
                name: 'bracket',
                color: this.model.get('bracketColor')
            });
        }
        
        if( this.model.get('poleModel') ) {
            parts.push({
                name: 'pole',
                color: this.model.get('poleColor')
            });
        }
        
        for (var part of parts) {
            let model = new Backbone.Model({
                part: part.name,
                current: part.color,
                options: colors,
                expandUp: true
            });

            model.on('change:current', this._handleColorChange.bind(this));
            this._colorModels.push(model);
        }

        this.showTab(0);
    }

    onClickTab(event) {
        let target = event.currentTarget;
        let clickedTabIndex = $(event.currentTarget).index();

        if (clickedTabIndex !== this._currentTabIndex) {
            this.showTab(clickedTabIndex);
        }
    }

    showTab(index) {
        this._currentTabIndex = index;

        this.$('.object-toolbar__tab')
            .removeClass('object-toolbar__tab--active')
            .eq(index)
            .addClass('object-toolbar__tab--active');

        this.showChildView('colorSelectRegion', new SelectLayoutView({
            model:this._colorModels[index]
        }));
    }

    _handleColorChange( model, colorModel ) {
        const partName = model.get('part');
        if (partName === 'global') {
            this._setColor( partName, colorModel );
            for (let partName of ['luminaire', 'bracket', 'pole']) {
                this._updateColor( partName, colorModel );
            }
        } else {
            this._updateColor( partName, colorModel );
            this._setColor( 'global', null );
            this._colorModels[0].attributes.current = null; // Reset model without triggering a change event, intentionally
        }
    }
    
    _updateColor( partName, colorModel ) {
        if (this.model.get(partName + 'Color') != null) {
            this._setColor( partName, colorModel );
        }
    }
    
    _setColor( partName, colorModel ) {
        this.model.set( partName + 'Color', colorModel );
    }

    _defineRegions() {
        return {
            'colorSelectRegion': '#object-toolbar-color-select-region'
        };
    }
}
