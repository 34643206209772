import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import ToolbarModel from 'models/composer/toolbars/ToolbarModel';
import ObjectToolbarItemType from 'models/composer/toolbars/ObjectToolbarItemType';
import ObjectToolbarItemDataCollection from 'models/composer/toolbars/ObjectToolbarItemDataCollection';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import ObjectToolbarMenuView from 'views/composer/toolbars/ObjectToolbarMenuView';
import ObjectToolbarSliderLayoutView from 'views/composer/toolbars/ObjectToolbarSliderLayoutView';
import ObjectToolbarColorSelectLayoutView from 'views/composer/toolbars/ObjectToolbarColorSelectLayoutView';

import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

import template from 'templates/composer/toolbars/object-toolbar';

export default
    class ObjectToolbarLayoutView
    extends AbstractLayoutView 
{
    constructor( params ) {
        super( params );
    }
    
    get className() {
        return 'object-toolbar';
    }

    get template() {
        return template;
    }

    get childEvents() {
        return {
            'childview:activateTool': this._activateTool,
            'show:menu': this._showMainMenu
        };
    }
    
    get lightSetModel() {
        return this.getOption('lightSetModel');
    }
    
    onBeforeShow() {
        this._showMainMenu();
    }

    onBeforeDestroy() {
        this._setActiveToolModel(null);
    }

    _defineRegions() {
        return {
            'mainRegion': '#object-toolbar-main-region'
        };
    }

    _showMainMenu() {
        this.showChildView('mainRegion', new ObjectToolbarMenuView({
            collection: this.model.get('itemCollection'),
            lightSetModel: this.lightSetModel
        }));
        this._setActiveToolModel(null);
    }

    _activateTool(menu, item) {
        const type = item.model.get('type');
        let activeToolModel = null;
        
        switch (type) {
            case ObjectToolbarItemType.COLOR: {
                this.showChildView( 'mainRegion', new ObjectToolbarColorSelectLayoutView({model: this.lightSetModel}) );
                break;
            }

            case ObjectToolbarItemType.ERASER: {
                this.showChildView( 'mainRegion', new ObjectToolbarSliderLayoutView({model: item.model}) );
                activeToolModel = item.model;
                break;
            }

            default: {
                activeToolModel = item.model;
                break;
            }
        }

        this._setActiveToolModel(activeToolModel);
    }
    
    _setActiveToolModel(model) {
        this.model.set(ToolbarModel.ACTIVE_TOOL, model);
        this.model.trigger('set:' + ToolbarModel.ACTIVE_TOOL, this.model, model);
    }
}
