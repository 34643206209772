import $ from 'jquery';

export default
    class NumbersUtil {

    /**
     * Take a jQuery form element, serialize it to a Backbone readable object
     * @param  {Object} elem Node element obj (form)
     * @return {Object} serialized object
     */
    static serializeToModel(elem) {
        const formData = $(elem).serializeArray();

        const data = formData.reduce(function(prev, field) { 
            prev[field.name] = field.value;
            return prev;
        }, {});

        return data;
    }
};
