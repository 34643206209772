import Backbone from 'backbone';

export default
    class NotificationModel
    extends Backbone.Model {

    static get TYPE_INFO() {
        return 'info';
    }

    static get TYPE_WARNING() {
        return 'warning';
    }
    
    get defaults() {
        return {
            title: 'Title not supplied',
            message: 'Message not supplied',
            type: this.TYPE_INFO,
            dismissed: false
        };
    }
}
