// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<figure class=\"spinner\"></figure>";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <p>\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please be patient, this is quite a heavy process for an iPad.",{"name":"trans","hash":{},"data":data}))
    + "<br>\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Do not close the application.",{"name":"trans","hash":{},"data":data}))
    + "\n        </p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"render__contents render__contents--progress\">\n    <h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Rendering image",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n    <div class=\"render__progress\">\n        <div class=\"render__progress__indicator\">\n            "
    + alias3(((helper = (helper = helpers.indicatorCurrent || (depth0 != null ? depth0.indicatorCurrent : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"indicatorCurrent","hash":{},"data":data}) : helper)))
    + " / "
    + alias3(((helper = (helper = helpers.indicatorTotal || (depth0 != null ? depth0.indicatorTotal : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"indicatorTotal","hash":{},"data":data}) : helper)))
    + "\n        </div>\n	    "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.cordova : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </div>\n    <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"One moment please",{"name":"trans","hash":{},"data":data}))
    + "...</p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cordova : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
