// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"select__color-preview\" style=\"background-color:rgb("
    + alias4(((helper = (helper = helpers.r || (depth0 != null ? depth0.r : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"r","hash":{},"data":data}) : helper)))
    + ","
    + alias4(((helper = (helper = helpers.g || (depth0 != null ? depth0.g : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"g","hash":{},"data":data}) : helper)))
    + ","
    + alias4(((helper = (helper = helpers.b || (depth0 != null ? depth0.b : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"b","hash":{},"data":data}) : helper)))
    + ");\"></div>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {};

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.r : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"select__option-text\">"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"useData":true});
