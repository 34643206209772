import Marionette from 'backbone.marionette';
import $ from 'jquery';

import template from 'templates/ui/save/save';

export default
    class SaveView
    extends Marionette.ItemView {

    static get ORIENTATION_DEFAULT() {
        return 'default';
    }

    get className() {
        return 'popover popover--default';
    }

    get template() {
        return template;
    }

    onBeforeRender() {
        this.$el.addClass('popover--default');
    }

    onRender() {
        this.$('.popover__confirm').on('click', this.onConfirm.bind(this));
        this.$('.popover__cancel').on('click', this.onCancel.bind(this));

        this._toggleModal();
    }

    onConfirm() {
        this.confirm();
    }

    _toggleModal() {
        $('.modal-overlay').fadeToggle( 200, "linear" );
    }

    onCancel() {
        const callback = this.getOption('onCancel');
        if (callback !== null) {
            callback.call();
        }
        this.destroy();
        this._toggleModal();
    }   

    confirm() {
        this.getOption('onConfirm').call();
        this.destroy();
        this._toggleModal();
    }
}
