import Backbone from 'backbone';

import SceneToolbarItemData from 'models/composer/toolbars/SceneToolbarItemData';
import SliderData from 'models/components/SliderData';
import SliderFunctionButtonData from 'models/components/SliderFunctionButtonData';

export default
    class SceneToolbarItemDataCollection 
    extends Backbone.Collection 
{
    // Item types (toolbar functions)
    static get ADD_SET() {                      return 'scene_toolbar_item_add-set';                }
    static get EDIT() {                         return 'scene_toolbar_item_edit';                   }
    static get CROP() {                         return 'scene_toolbar_item_crop';                   }
    static get CROP_ROTATION() {                return 'scene_toolbar_item_crop_rotation';          }
    static get CROP_ZOOM() {                    return 'scene_toolbar_item_crop_zoom';              }
    static get EXPOSURE() {                     return 'scene_toolbar_item_exposure';               }
    static get LUMINOSITY() {                   return 'scene_toolbar_item_luminosity';             }
    static get CONTRAST() {                     return 'scene_toolbar_item_contrast';               }
    static get CLONE() {                        return 'scene_toolbar_item_stamp';                  }
    static get CLONE_SOFTNESS() {               return 'scene_toolbar_item_stamp_softness';         }
    static get CLONE_SIZE() {                   return 'scene_toolbar_item_stamp_size';             }
    static get CLONE_SELECT_SOURCE_POINT() {    return 'scene_toolbar_item_stamp_select_source';    }
    static get BLUR() {                         return 'scene_toolbar_item_blur';                   }
    static get BLUR_STRENGTH() {                return 'scene_toolbar_item_blur_strength';          }
    static get BLUR_INTENSITY() {               return 'scene_toolbar_item_blur_intensity';         }
    static get ADD_LINE() {                     return 'scene_toolbar_item_line';                   }
    static get UNDO() {                         return 'scene_toolbar_item_undo';                   }
    
    // Slider positions
    static get SLIDER_POSITION_TOOLBAR() {      return 'toolbar';           }
    static get SLIDER_POSITION_CANVAS_TOP() {   return 'canvas-top';        }
    static get SLIDER_POSITION_CANVAS_RIGHT() { return 'canvas-right';      }

    static get defaults() {
        return [
            {
                type: this.ADD_SET,
                tool: 'add',
                btnLabel: gettext('Add set'),
            },
            {
                type: this.EDIT,
                tool: 'transform',
                btnLabel: gettext('Edit'),
                title: gettext('Edit placed Lightsets and lines'),
                description: gettext('Move, rotate and resize lightsets and lines that have been placed on the canvas')
            },
            {
                type: this.CROP,
                tool: 'crop',
                btnLabel: gettext('Crop'),
                title: gettext('Crop tool'),
                description: gettext('Rotate and scale the background image. Please note that placed objects (lightsets and lines) will remain as they are.'),
                sliders: new Backbone.Collection([
                    new SliderData({
                        value: 0,
                        min: -20,
                        max: 20,
                        step: 0,
                        type: this.CROP_ROTATION,
                        tool: 'rotate',
                        labelAffix: '&deg;',
                        position: this.SLIDER_POSITION_CANVAS_TOP,
                        orientation: 'horizontal',
                        analyticsTag: 'image_tilt',
                    }),
                    new SliderData({
                        value: 100,
                        min: 25,
                        max: 200,
                        step: 0,
                        type: this.CROP_ZOOM,
                        tool: 'zoom',
                        labelAffix: '%',
                        position: this.SLIDER_POSITION_CANVAS_RIGHT,
                        orientation: 'vertical',
                        analyticsTag: 'zoom_percentage',
                    })
                ])
            },
            {
                type: this.EXPOSURE,
                tool: 'exposure',
                btnLabel: gettext('Exposure'),
                title: gettext('Exposure tool'),
                description: gettext('Adjust the tonal values of the background image'),
                sliders: new Backbone.Collection([
                    new SliderData({
                        value: 0,
                        min: -100,
                        max: 100,
                        step: 0,
                        type: this.EXPOSURE,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'exposure_percentage',
                    })
                ])
            },
            {
                type: this.LUMINOSITY,
                tool: 'luminosity',
                btnLabel: gettext('Luminosity'),
                title: gettext('Luminosity tool'),
                description: gettext('The objective of luminosity enhancement is to bring out detail in desired areas of an image.'),
                sliders: new Backbone.Collection([
                    new SliderData({
                        value: 0,
                        min: -100,
                        max: 100,
                        step: 0,
                        type: this.LUMINOSITY,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'luminosity_percentage',
                    })
                ])},
            {
                type: this.CONTRAST,
                tool: 'contrast',
                btnLabel: gettext('Contrast'),
                title: gettext('Contrast tool'),
                description: gettext('The contrast slider expands or shrinks the overall range of tonal values in the image.'),
                sliders: new Backbone.Collection([
                    new SliderData({
                        value: 0,
                        min: -100,
                        max: 100,
                        step: 0,
                        type: this.CONTRAST,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'contrast_percentage',
                    })
                ])},
            {
                type: this.CLONE,
                tool: 'stamp',
                btnLabel: gettext('Stamp'),
                title: gettext('Stamp tool'),
                description: gettext('This tool allows you to copy one area of an image onto another area. It can be used for erasing existing light sets or cleaning the picture. Clicking "Select source point" in the menu (or holding down the ALT key) allows you to select the area to copy (the cursor will be red in this mode). Afterwards, click on another area to copy the source to the target area. Instead of clicking, you can also hold down the mouse button to \'paint\' to the target area.'),
                sliders: new Backbone.Collection([
                    new SliderFunctionButtonData({
                        value: 0,
                        type: this.CLONE_SELECT_SOURCE_POINT,
                        label: gettext("Select source point"),
                        analyticsTag: 'select_source_point',
                    }),
                    new SliderData({
                        value: 100,
                        min: 0,
                        max: 100,
                        step: 0,
                        label: gettext('Hardness'),
                        labelAffix: '%',
                        type: this.CLONE_SOFTNESS,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'hardness_percentage',
                    }),
                    new SliderData({
                        value: 15,
                        min: 5,
                        max: 100,
                        step: 0,
                        label: gettext('Size'),
                        labelAffix: 'pt',
                        type: this.CLONE_SIZE,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'size_percentage',
                    }),
                ])},
            {
                type: this.BLUR,
                tool: 'blur',
                btnLabel: gettext('Blur'),
                title: gettext('Blur tool'),
                description: gettext('The Blur Tool unfocuses image areas. You can use this tool to blur people’s faces to make them anonymous.'),
                sliders: new Backbone.Collection([
                    new SliderData({
                        value: 45,
                        min: 10,
                        max: 80,
                        step: 0,
                        label: gettext('Size'),
                        labelAffix: 'pt',
                        type: this.BLUR_SIZE,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'blur_size_percentage',
                    }), 
                    new SliderData({
                        value: 55,
                        min: 10,
                        max: 100,
                        step: 0,
                        label: gettext('Strength'),
                        labelAffix: '%',
                        type: this.BLUR_STRENGTH,
                        position: this.SLIDER_POSITION_TOOLBAR,
                        analyticsTag: 'blur_strength_percentage',
                    })
                ])
            },
            {
                type: this.ADD_LINE,
                tool: 'line',
                btnLabel: gettext('Line'),
                toggleable: true,
                toggleAnalyticsTag: 'line_switch',
                title: gettext('Line tool'),
                description: gettext('Draw lines to create a perspective guide which will help you with the placement of the light sets. These lines will not be visible on the final image.')
            },
            {
                type: this.UNDO,
                tool: 'undo',
                btnLabel: gettext('Undo'),
                stateless: true
            }
        ];
    }

    setAttributes(attributes) {
        this.invoke('set', attributes);
    }

    constructor(data = null) {
        super(data ? data : SceneToolbarItemDataCollection.defaults);
    }

    get model() {
        return SceneToolbarItemData;
    }
    
    getSlider( type ) {
        // Assumes all sliders have a unique type, which conforms to design
        for (let model of this.models) {
            const slider = model.getSlider(type);
            if (slider) {
                return slider;
            }
        }
    }
    
    setSliderValue( type, value ) {
        const sliderModel = this.getSlider(type);
        sliderModel.set('value', value);
    }
}
