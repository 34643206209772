// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"flyout-region\">\n			This text should be replaced with sliders.\n		</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li class=\"composer-scene-toolbar__item\">\n	<button class=\"composer-scene-toolbar__button  composer-scene-toolbar__button--"
    + alias4(((helper = (helper = helpers.tool || (depth0 != null ? depth0.tool : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tool","hash":{},"data":data}) : helper)))
    + "\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.active : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n		<span class=\"composer-scene-toolbar__button-icon icon-"
    + alias4(((helper = (helper = helpers.tool || (depth0 != null ? depth0.tool : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tool","hash":{},"data":data}) : helper)))
    + "\"></span>\n		<span class=\"composer-scene-toolbar__button-label\">"
    + alias4(((helper = (helper = helpers.btnLabel || (depth0 != null ? depth0.btnLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"btnLabel","hash":{},"data":data}) : helper)))
    + "</span>\n	</button>\n	\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showFlyout : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</li>\n";
},"useData":true});
