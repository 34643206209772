import Marionette from 'backbone.marionette';
import _ from 'underscore';
import Analytics from 'utils/Analytics';

import ApplicationState from 'app/ApplicationState';

import LightsetWizardTypeAndHeightLayoutView from 'views/lightset-wizard/LightsetWizardTypeAndHeightLayoutView';
import LightsetWizardLuminairesLayoutView from 'views/lightset-wizard/LightsetWizardLuminairesLayoutView';
import LightsetWizardBracketsLayoutView from 'views/lightset-wizard/LightsetWizardBracketsLayoutView';
import LightsetWizardPolesLayoutView from 'views/lightset-wizard/LightsetWizardPolesLayoutView';
import LightsetWizardResultView from 'views/lightset-wizard/LightsetWizardResultView';
import LightsetWizardHeaderView from 'views/lightset-wizard/LightsetWizardHeaderView';

import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

import template from 'templates/lightset-wizard/lightset-wizard';

export default Marionette.LayoutView.extend({

    className: 'lightset-wizard',

    template: template,

    ui: {
        'title': '.lightset-wizard__title',
        'nextBtn': '.lightset-wizard__next',
        'skipToPolesBtn': '.lightset-wizard__skip-to-poles',
        'backBtn': '.lightset-wizard__back',
        'progress': '.progress__fill'
    },

    modelEvents: {
        'change:nextEnabled': 'onNextEnabledChange',
        'change:step': 'onStepChange',
    },

    triggers: {
        'click @ui.nextBtn': 'next',
        'click @ui.skipToPolesBtn': 'skipToPolesBtnClick',
        'click @ui.backBtn': 'back'
    },

    childEvents: {
        'set:lightset:part': '_onSetLightsetPart',
        'close:wizard': '_onCloseWizard',
        'reset:wizard': '_onResetWizard'
    },

    regions: {
        mainRegion: '#lightset-wizard-main-region',
        headerRegion: '#lightset-wizard-header-region'
    },

    initialize: function() {
        this.currentStep = '';

        if (ApplicationState.get('backToProject')) {
            this.lightSetModel = ApplicationState.get('lightSetModel');
        } else {
            this.lightSetModel = this.getOption('lightSetModel');
        }

        this._preselectColors = this.getOption('preSelectColors');
        this.nextStep = null;
        this.listenTo(this.lightSetModel, 'change:luminaire', this.onLuminaireFamilyChange);
        this.listenTo(this.lightSetModel, 'change:bracket', this.onBracketFamilyChange);

        this.listenTo(this.lightSetModel, 'change:luminaireModel', this.onLuminaireModelChange);
        this.listenTo(this.lightSetModel, 'change:bracketModel', this.onBracketModelChange);
    },

    onBeforeShow() {
        this.showChildView('headerRegion', new LightsetWizardHeaderView({model: this.model}));
        this._setBackButtonVisibility(false);

        if (ApplicationState.get('backToProject')) {
            this.model.set('step', 5);
            ApplicationState.set('backToProject', false);
        } else {
            this.model.set('step', 1);
        }
    },

    onLuminaireFamilyChange(){
        this._unsetLuminaireModel();
    },

    onBracketFamilyChange(){
        this._unsetBracketModel();
    },

    onLuminaireModelChange() {
        this._unsetBracket();
        this._updateNavigationButtons();
    },

    onBracketModelChange() {
        this._unsetPole();
    },

    onNextEnabledChange(model, enabled) {
        this.ui.nextBtn.prop('disabled', !enabled);
    },

    _setSkipToPolesButtonVisibility(visible) {
        this.ui.skipToPolesBtn.toggle(visible);
    },

    _setBackButtonVisibility(visible) {
        this.ui.backBtn.toggle(visible);
    },

    _setNextButtonVisibility(visible) {
        this.ui.nextBtn.toggle(visible);
    },

    _unsetLuminaireModel() {
        this.lightSetModel.unset('luminaireModel');
    },

    _unsetBracketModel() {
        this.lightSetModel.unset('bracketModel');
    },

    _unsetBracket() {
        this._unsetBracketModel();
        this.lightSetModel.unset('bracket');
    },

    _unsetPole() {
        this.lightSetModel.unset('poleModel');
        this.lightSetModel.unset('pole');
    },

    _onCloseWizard() {
        Analytics.track(this.currentStep + ':close');
        this.destroy();
    },

    _onResetWizard() {
        Analytics.track('reset');
        this.model.clear({silent: true});
        this.lightSetModel.clear({silent: true});

        this.lightSetModel.set({
            luminaireColor: LightsetWizardDataUtil.getDefaultColor(),
            bracketColor: LightsetWizardDataUtil.getDefaultColor(),
            poleColor: LightsetWizardDataUtil.getDefaultColor()
        });

        this.model.set({
            'steps': 5,
            'step': 1
        });
    },

    _hasBrackets(luminaireModel) {
        if (!luminaireModel || !this.lightPointType || this.lightPointType === 'top' || this.lightPointType === 'column') {
            return false;
        } else {
            return LightsetWizardDataUtil.getBracketFamilies(
                luminaireModel, this.lightPointType, this.lightSetModel.get('height')
            ).length > 0;
        }
    },

    _hasPoles(luminaireModel) {
        if (!luminaireModel || !this.lightPointType || this.lightPointType === 'wall' || this.lightPointType === 'column') {
            return false;
        } else {
            return LightsetWizardDataUtil.getPolesFromLuminaire(luminaireModel, this.lightPointType).length > 0;
        }
    },

    onSkipToPolesBtnClick() {
        // only has one function now: to show up in the Select Luminaire step, in order to skip
        // choosing a bracket and go straight to poles, in case of Single or Double solutions without brackets.
        this._unsetBracket();
        this.model.set('step', 4);
    },

    onNext() {
        if (this.nextStep > 0 && this.nextStep < this.stepFunctions.length) {
            this.model.set('step', this.nextStep);
            Analytics.track(this.currentStep + ':button');
        }
    },

    onBack() {
        Analytics.track(this.currentStep + ':back:link');

        switch (this.model.get('step')) {
            case 5: {
                if (this.lightSetModel.get('poleModel')) {
                    this.model.set('step', 4);
                    break; // Yes, this break belongs INSIDE the conditional. Else, fall through to case 4 logic.
                }
            }

            case 4: {
                if (this.lightSetModel.get('bracketModel')) {
                    this._unsetPole();
                    this.model.set('step', 3);
                } else {
                    this._unsetBracket();
                    this._unsetPole();
                    this.model.set('step', 2);
                }

                break;
            }

            default: {
                this.model.set('step', this.model.get('step') - 1);
            }
        }
    },

    onStepChange(model, value) {
        // for analytics
        switch(value) {
            case 1:     this.currentStep = 'select_height_and_type';  break;
            case 2:     this.currentStep = 'select_luminaire';        break;
            case 3:     this.currentStep = 'select_bracket';          break;
            case 4:     this.currentStep = 'select_pole';             break;
            case 5:     this.currentStep = 'view_result';             break;
        }

        this._updateNavigationButtons();

        this.ui.progress.css('width', value / this.model.get('steps') * 100 + '%');
        this.model.set('nextEnabled', false);
        this[this.stepFunctions[value]].call(this);

        if (value === 1 && (
                this.lightPointType === 'wall' ||
                this.lightPointType === 'column' ||
                this.lightPointType === 'luminaire-only'
            )
        ){
            this.mainRegion.currentView.triggerMethod('hide:bottom:row');
        }
    },

    _updateNavigationButtons() {
        const step = this.model.get('step');

        this._setBackButtonVisibility(step > 1);
        this._setNextButtonVisibility(true);
        this._setSkipToPolesButtonVisibility(false);

        this.nextStep = -1;

        switch (step) {
            case 2: {
                if (this.lightPointType === 'column' || this.lightPointType === 'luminaire-only') {
                    this.nextStep = 5;
                } else if (this.lightPointType === 'top') {
                    this.nextStep = 4;
                } else if (this.lightPointType === 'wall') {
                    this.nextStep = 3;
                } else {
                    // Single, Double or Spiral configs may have brackets, poles, or both
                    // So we need to toggle two different buttons for this.
                    // The next button takes care of the brackets, and the skipToPoles
                    // button is for showing the options where the bracket is integrated into
                    // the pole.
                    const luminaireModel = this.lightSetModel.get('luminaireModel');
                    if (!luminaireModel) {
                        this.nextStep = -1;
                    } else {
                        this.nextStep = 3;
                        this._setNextButtonVisibility(this._hasBrackets(luminaireModel));
                        this._setSkipToPolesButtonVisibility(this._hasPoles(luminaireModel));
                    }
                }

                break;
            }

            case 3: {
                if (this.lightPointType === 'wall') {
                    this.nextStep = 5;
                } else {
                    this.nextStep = 4;
                }
                break;
            }

            default:
                this.nextStep = step + 1;
        }

        let nextLabel = '';
        switch (this.nextStep) {
            case 2:     nextLabel = gettext('Select luminaire');    break;
            case 3:     nextLabel = gettext('Select bracket');      break;
            case 4:     nextLabel = gettext('Select pole');         break;
            case 5:     nextLabel = gettext('View result');         break;
            default:    nextLabel = gettext('Next step');           break;
        }
        this.ui.nextBtn.text(nextLabel);
    },

    _onSetLightsetPart(view, part, model) {
        if (part === 'lightPointType') {
            this.lightPointType = model.id;
        }

        if (part === 'bracket') {
            let bracketOptions = _.findWhere(this.lightSetModel.get('luminaireModel').brackets, {id: model.get('id')});

            for (let property in bracketOptions) {
                model.set(property, bracketOptions[property]);
            }
        }

        let requiredAttributesPerStep = {
            1: ['lightPointType', 'height'],
            2: ['luminaire', 'luminaireModel'],
            3: ['bracket', 'bracketModel'],
            4: ['pole', 'poleModel']
        };

        this.lightSetModel.set(part, model.toJSON());

        var nextEnabled = true;

        requiredAttributesPerStep[this.model.get('step')].forEach(function(requiredAttribute){
            nextEnabled = (nextEnabled && !!this.lightSetModel.get(requiredAttribute));
        }, this);

        view.triggerMethod('show:bottom:row');

        // No pole selection so hide the heights view & enable the next button
        if (
            this.lightPointType === 'wall' ||
            this.lightPointType === 'column' ||
            this.lightPointType === 'luminaire-only'
        ) {
            view.triggerMethod('hide:bottom:row');
            nextEnabled = true;
        }

        this.model.set('nextEnabled', nextEnabled);

        // Hotfix to immediately go to next step when selecting 'all' and a light point type has been chosen
        if (model.get('min') === 0 && model.get('max') > 10000 && this.lightSetModel.get('lightPointType') !== null) {
            this.onNext();
        }
    },

    showTypeAndHeight() {
        this.model.set({
            title: gettext('Select light point type & height'),
        });

        this.showChildView('mainRegion', new LightsetWizardTypeAndHeightLayoutView({
            model: this.lightSetModel
        }));
    },

    showLuminaires() {
        this.model.set({
            title: gettext('Select luminaire')
        });

        this.showChildView('mainRegion', new LightsetWizardLuminairesLayoutView({
            model: this.lightSetModel
        }));
    },

    showBrackets() {
        this.model.set({
            title: gettext('Select bracket'),
        });

        this.showChildView('mainRegion', new LightsetWizardBracketsLayoutView({
            model: this.lightSetModel
        }));
    },

    showPoles() {
        this.model.set({
            title: gettext('Select pole'),
        });

        this.showChildView('mainRegion', new LightsetWizardPolesLayoutView({
            model: this.lightSetModel
        }));
    },

    showResult() {
        this.model.set({
            title: gettext('Result: Your light point solution'),
            nextEnabled: true,
        });

        this.ui.nextBtn.text(this._processResultLabel());

        this.applyPreselectColors();

        this.showChildView('mainRegion', new LightsetWizardResultView({
            model: this.lightSetModel,
            useBackground: this._useBackgroundInResultView()
        }));
    },

    applyPreselectColors() {
        if (this._preselectColors) {
            for (let colorPart of ['luminaire', 'bracket', 'pole']) {
                const key = colorPart + 'Color';

                if (this._preselectColors[key]) {
                    this.lightSetModel.set(key, this._preselectColors[key]);
                }
            }
        }
    },

    _useBackgroundInResultView() {
        return true;
    },

    _processResultLabel() {
        return 'Implement getter _processResultLabel()';
    }
});
