import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';
import SceneToolbarItemSlidersView from 'views/composer/toolbars/SceneToolbarItemSlidersView';
import SceneToolbarItemToggleVisiblityView from 'views/composer/toolbars/SceneToolbarItemToggleVisiblityView';

import template from 'templates/composer/toolbars/scene-toolbar-item';

export default
    class SceneToolbarItem 
    extends AbstractLayoutView 
{
    constructor( params ) {
        super(params);
        this._sliders = params.model.get('sliders');
        this._hasToolbarSliders = !!this._sliders && this._sliders.where({
            position: SceneToolbarItemDataCollection.SLIDER_POSITION_TOOLBAR
        }).length > 0;

        this._flyoutIsShown = false;
        this._isToggleable = params.model.get('toggleable');

        this.model.set('showFlyout', this._hasFlyout);
    }

    initialize() {
        this.listenTo(this.model, 'change:isActive', this.onActiveChange);
        this.model.trigger('change:isActive');
    }

    onActiveChange(model, isActive) {
        this.$el.toggleClass('composer-scene-toolbar__item--inactive', !isActive);
        this.$('.composer-scene-toolbar__button').attr('disabled', !isActive);
    }
    
    onShow() {
        if (this._hasToolbarSliders) {
            this.showChildView('flyoutRegion', new SceneToolbarItemSlidersView({
                collection: this._sliders,
                expandUpwards: this.isInSecondHalf()
            }));

            this.listenTo( this._sliders, 'change:value', function( sliderModel, value ) {
                this.model.trigger( 'slider:change', sliderModel, value );
            });
        } else if (this._isToggleable) {
            var model = new Backbone.Model({
                value: 1
            });

            this.showChildView('flyoutRegion', new SceneToolbarItemToggleVisiblityView({
                model: model,
                expandUpwards: this.isInSecondHalf()
            }));

            this.listenTo(this.flyoutRegion.currentView.model, 'change:value', function(model){
                this.trigger('toggler:changed', this.model, model);
            });
        }
    }

    isInSecondHalf() {
        return this.model.collection.indexOf(this.model) >= this.model.collection.length / 2;
    }

    _defineRegions() {
        return {
            flyoutRegion: '.flyout-region'
        };
    }

    _defineEvents() {
        return {
            'mousedown': function(evt) {
                this._widgetValueChanged = false;
                this.trigger('item:mousedown', evt);
            },

            'click .composer-scene-toolbar__button': function(evt) {
                evt.stopImmediatePropagation();

                if (!this._widgetValueChanged) {
                    this._widgetValueChanged = false;
                    this.trigger('item:clicked', evt);
                }
            },

            'touchmove': function(evt) {
                evt.preventDefault();
            }
        };
    }

    get childView() {
        return SliderView;
    }

    get tagName() {
        return 'li';
    }

    get className() {
        return 'composer-scene-toolbar__item';
    }

    get template() {
        return template(this.model.attributes);
    }
    
    toggleFlyout() {
        this._setFlyoutShownState(!this._flyoutIsShown);
    }
    
    showFlyout() {
        this._setFlyoutShownState(true);
    }
    
    hideFlyout() {
        this._setFlyoutShownState(false);
    }
    
    _setFlyoutShownState( show ) {
        if (this._hasFlyout) {
            const region = this.$('.flyout-region');
            show ? region.show() : region.hide();
            this._flyoutIsShown = show;
            
            // Needed to solve this with a class since sibling selectors just don't work in this order.
            const button = this.$('.composer-scene-toolbar__button');
            button.toggleClass('composer-scene-toolbar__button--showing-flyout', this._flyoutIsShown);
        }
    }
    
    get _hasFlyout() {
        return this._isToggleable || this._hasToolbarSliders;
    } 
}
