import async from 'async';
import AbstractPDF from 'controllers/pdf/AbstractPDF';
import ComposerPage from 'controllers/pdf/ComposerPage';
import LightSetPage from 'controllers/pdf/LightSetPage';

export default
    class PDFRenderer 
    extends AbstractPDF 
{
    get orientation() {
        return 'landscape';
    }

    get steps() {
        return [this._addComposerPage.bind(this), this._addLightSetPages.bind(this)];
    }

    render() {
        const lightSets = this.model.get('lightSets');
        this.model.set({
            indicatorTotal: 1 + lightSets.length, // first image is the composition
            indicatorCurrent: 0 
        });

        super.render();
    }

    _addComposerPage(callback) {
        const page = new ComposerPage(this.pdf, this.model, true, function(...params) {
            this.model.set('indicatorCurrent', 1);
            callback.apply(this, params);
        }.bind(this));
    }

    _addLightSetPages(callback) {
        var functions = [];
        let lightSets = this.model.get('lightSets');

        if (lightSets) {
            lightSets.forEach(function(lightSet, index){
                functions.push(function(cb){
                    const lightSetPage = new LightSetPage(this.pdf, this.model, lightSet, false, function(...params) {
                        this.model.set('indicatorCurrent', index + 2); // first image is the composition
                        cb.apply(this, params);
                    }.bind(this));
                }.bind(this));
            }, this);

            async.series(functions, callback);
        } else {
            callback();
        }
    }
};
