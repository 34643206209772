import Marionette from 'backbone.marionette';

export default
    class AbstractLayoutView 
    extends Marionette.LayoutView // LayoutView is the bastard child of an ItemView and a RegionManager :)
{
    // public

    get template() {
        throw 'Abstract public get template() not implemented';
    }
    
    get regions() {
        if (!this.__regions) {
            this.__regions = this._defineRegions();
        }

        return this.__regions;
    }

    // Apparently Marionette will set this value (with the exact same value as returned by the getter).
    // JavaScript 'practices' for the win.

    set regions(val) {
        this.__regions = val;
    }

    // protected

    _defineRegions() {
        throw 'Abstract protected _defineRegions() not implemented';
    }

    get events() {
        if (!this._defineEvents) {
            return;
        }

        if (!this.__events) {
            this.__events = this._defineEvents();
        }

        return this.__events;
    }

    // Apparently Marionette will set this value (with the exact same value as returned by the getter).
    // JavaScript 'practices' for the win.

    set events(val) {
        this.__events = val;
    }

    get ui() {
        if (!this._defineUi) {
            return;
        }

        if (!this.__ui) {
            this.__ui = this._defineUi();
        }

        return this.__ui;
    }

    // Apparently Marionette will set this value (with the exact same value as returned by the getter).
    // JavaScript 'practices' for the win.

    set ui(val) {
        this.__ui = val;
    }
}
