import Marionette from 'backbone.marionette';
import $ from 'jquery';
import Analytics from 'utils/Analytics';

import ToolbarModel from 'models/composer/toolbars/ToolbarModel';
import SceneToolbarItem from 'views/composer/toolbars/SceneToolbarItem';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';

// Internal constants
const kItemSelectedStyle = 'composer-scene-toolbar__item--activate';

export default
    class SceneToolbarView
    extends Marionette.CollectionView
{   
    // Events
    static get SELECTED() { return 'tool:selected'; }
    static get SLIDER_CHANGED() { return 'tool:sliderChanged'; }
    static get TOGGLER_CHANGED() { return 'tool:togglerChanged'; }
    
    constructor( params ) {
        super({ collection: params.model.get('itemCollection') });
        this.model = params.model;
    }
    
    get tagName() {
        return 'ul';
    }
    
    get className() {
        return 'composer-scene-toolbar';
    }
    
    get id() {
        return 'scene-toolbar';
    }
        
    get childEvents() {
        return {
            'item:clicked': this._handleItemClick,
            'slider:changed': this._handleSliderChanged,
            'toggler:changed': this._handleTogglerChanged
        }
    }
    
    get childView() {
        return SceneToolbarItem;
    }
    
    onBeforeShow() {
        $('body').on('mousedown', this._handleBodyClick.bind(this));
    }
    
    onBeforeDestroy() {
        $('body').off('mousedown');
    }
    
    _handleItemClick( child, evt ) {
        Analytics.track(child.model.get('type') + ':menu');
        if(child.$el.hasClass(kItemSelectedStyle)) {
            // Note: bad idea to check if clicked item is the active tool by checking a class
            // We should check the model instead. Edit Tiemen: Nah, since multiple tools can be active due to stateless tools. 
            child.toggleFlyout();
        } else {
            if( !child.model.get('stateless') ) {
                this._deselectAllItems();
                child.showFlyout();
                child.$el.addClass( kItemSelectedStyle );
            }
            this.model.set( ToolbarModel.ACTIVE_TOOL, child.model );
            this.model.trigger( 'set:' + ToolbarModel.ACTIVE_TOOL, this.model, child.model );
        }
    }
    
    _handleSliderChanged( view, model, sliderModel ) {
        this.trigger( SceneToolbarView.SLIDER_CHANGED, model, sliderModel );
    }
    
    _handleTogglerChanged( view, model, togglerModel ) {
        Analytics.track(model.get('toggleAnalyticsTag') + ':' + (togglerModel.get('value') == 0 ? 'off' : 'on'));
        this.trigger( SceneToolbarView.TOGGLER_CHANGED, model, togglerModel );
    }
    
    _deselectAllItems() {
        this.$el.children().removeClass( kItemSelectedStyle );
        this._hideAllFlyouts();
    }
    
    _hideAllFlyouts() {
        this.children.each(function(view){
            view.hideFlyout();
        });
    }
    
    _handleBodyClick(evt) {
        if (!$(evt.target).is('#' + this.id + ' *, #' + this.id)) {
            this._hideAllFlyouts();
        }
    }
}
