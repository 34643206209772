import Marionette from 'backbone.marionette';
import PDF from 'pdfkit';
import async from 'async';

export default
    class AbstractPDF extends Marionette.Object {

    constructor(model) {
        super();

        this.model = model;

        this.pdf = new PDF({
            layout: this.orientation,
            size: 'A4',
            margins: {
                top: 1,
                bottom: 1,
                left: 72,
                right: 72
            }
        });
    }

    get orientation() {
        return 'portrait';
    }

    get blob() {
        return this.pdf.output('blob');
    }

    get arrayBuffer() {
        return this.pdf.output('arraybuffer');
    }

    get steps() {
        throw 'Abstract method get steps() not implemented in ' + this.constructor.name;
    }

    _registerFonts(callback) {
        let fonts = ['CentraleSansBook', 'CentraleSansMedium'];

        var xhr = new XMLHttpRequest;
        xhr.open('GET', 'fonts/CentraleSans.ttc', true);
        
        xhr.responseType = 'arraybuffer';
        xhr.onload = function() {
            for (let font of fonts) {
                this.pdf.registerFont(font, new Buffer(xhr.response), font);
                this.pdf.registerFont(font, new Buffer(xhr.response), font);
            }

            callback();
        }.bind(this);

        xhr.onerror = function() {
            alert(gettext('Failed to load required assets. Please check your internet connection.'));
            window.history.back();
        }
        
        xhr.send();
    }

    _onRender() {
        this.pdf.end();
        this.trigger('render');
    }

    openInNewTab() {
        return this.pdf.output('dataurlnewwindow');
    }

    render() {
        let steps = this.steps;

        steps.unshift(this._registerFonts.bind(this));
        async.series(steps, this._onRender.bind(this));
    }
};
