// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Bracket",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.bracketModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "</label>\n				<div id=\"lightset-wizard-select-bracket-color-region\"></div>\n			</fieldset>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"Pole",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.poleModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "</label>\n				<div id=\"lightset-wizard-select-pole-color-region\"></div>\n			</fieldset>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"lightset-wizard__result-wrapper\">\n	<div id=\"lightset-wizard-preview-region\" class=\"lightset-wizard__preview-section\"></div>\n	<div class=\"lightset-wizard__change-color-section\">\n		<div class=\"checkbox\">\n			<input type=\"checkbox\" class=\"checkbox__label\" id=\"lightset-wizard-color-individually\" checked=\"checked\">\n			<label class=\"checkbox__label\" for=\"lightset-wizard-color-individually\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Color parts individually",{"name":"trans","hash":{},"data":data}))
    + "</label>\n		</div>\n		<div id=\"lightset-wizard-global-color-section\">\n			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Entire lightset",{"name":"trans","hash":{},"data":data}))
    + "</label>\n				<div id=\"lightset-wizard-select-global-color-region\"></div>\n			</fieldset>\n		</div>\n		<div id=\"lightset-wizard-individual-colors-section\">\n			<fieldset class=\"lightset-wizard__change-color-fieldset\">\n				<label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Luminaire",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.luminaireModel : depth0)) != null ? stack1.name : stack1), depth0))
    + "</label>\n				<div id=\"lightset-wizard-select-luminaire-color-region\"></div>\n			</fieldset>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.bracketModel : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.poleModel : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n";
},"useData":true});
