import Backbone from 'backbone';
import LightsetWizardFeature from 'models/lightset-wizard/Feature';

export default
    class LightsetWizardFeatureCollection extends Backbone.Collection {

    constructor(params) {
        params = params || {};
        super(params);
    }
}
