import Backbone from 'backbone';

export default
	class SliderFunctionButtonData
	extends Backbone.Model
{
	get defaults() {
        return {
            label: "Not implemented"
        };
    }
}