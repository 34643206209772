import $ from 'jquery';
import Backbone from 'backbone';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import ApplicationState from 'app/ApplicationState';

import UploadView from 'views/upload/UploadView';

import LightsetWizardLayoutView from 'views/lightset-wizard/LightsetWizardLayoutView';
import LightSetWizardModel from 'models/lightset-wizard/Wizard';
import LightSetModel from 'models/lightset-wizard/LightSet';

import HeaderView from 'views/composer/header/HeaderView';
import LightSetObject from 'views/composer/composition/objects/LightSetObject';
import CompositionView from 'views/composer/composition/CompositionView';
import SceneToolbarView from 'views/composer/toolbars/SceneToolbarView';
import ObjectToolbarLayoutView from 'views/composer/toolbars/ObjectToolbarLayoutView';

import SliderView from 'views/components/SliderView';
import CropRotateSliderView from 'views/components/CropRotateSliderView';

import NotificationView from 'views/ui/notification/NotificationView';
import NotificationModel from 'models/ui/notification/Notification';

import SceneToolkitController from 'controllers/composer/tools/scene/SceneToolkitController';
import ObjectToolkitController from 'controllers/composer/tools/object/ObjectToolkitController';
import ToolbarModel from 'models/composer/toolbars/ToolbarModel';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';
import ObjectToolbarItemDataCollection from 'models/composer/toolbars/ObjectToolbarItemDataCollection';

import ImageUtil from 'utils/images'; // needs consistent naming; class files should be named after the exposed class
import SaveUtil from 'utils/SaveUtil';

import template from 'templates/composer/composer';

export default
    class ComposerLayoutView
    extends AbstractLayoutView 
{
    get template() {
        return template;
    }

    get childEvents() {
        return {
            'show:error': this._onShowError,
            'image:loaded': this._onImageLoaded,
            'save:project': this._onSaveProject,
            'reset:composition': this._resetProject,
            'render:composition': this._renderComposition,
            'project:loaded': this._loadProject,
            'replace:lightsets': this.startReplaceLightSetsFlow
        };
    }

    onBeforeShow() {
        this.listenTo(this.mainRegion, 'swapOut', this._onSwapMainView);
        this.listenTo(this.wizardRegion, 'show', this._onWizardRegionShow);
        this.listenTo(this.wizardRegion, 'empty', this._onWizardRegionEmpty);
        this._resetProject();
    }

    activateTool(toolId) {
        this.sidebarRegion.currentView.children.findByModel(this.sidebarRegion.currentView.collection.findWhere({
            type: toolId
        })).triggerMethod('item:clicked');
    }
    
    startReplaceLightSetsFlow() {
        this.isReplacingAllLightSets = true;
        this.activateTool(SceneToolbarItemDataCollection.ADD_SET);
    }
    
    get isReplacingAllLightSets() {
        return this._isReplacingAllLightSets;
    }
    
    set isReplacingAllLightSets(bool) {
        this._isReplacingAllLightSets = bool;
    }

    _resetProject() {
        var uploadViewModel = new Backbone.Model({
            canDownload: Modernizr.adownload
        });

        const sceneToolbarModel = new ToolbarModel( new SceneToolbarItemDataCollection() );
        this._sceneToolkitController = new SceneToolkitController();
        this._sceneToolkitController.model = sceneToolbarModel;
        this.listenTo( sceneToolbarModel, 'set:' + ToolbarModel.ACTIVE_TOOL, this._handleSceneToolbarSelectEvent );
        
        this._objectToolkitController = new ObjectToolkitController(); // Model will be assigned as soon as a LightSet gets selected

        this.showChildView( 'mainRegion', new UploadView({ model: uploadViewModel }));
        this.showChildView( 'sidebarRegion', new SceneToolbarView({ model: sceneToolbarModel }));

        this.listenTo(this.sidebarRegion.currentView, SceneToolbarView.TOGGLER_CHANGED, this._setSceneToolbarInput);
        // this.__skipUpload();
    }

    _startNewComposition() {
        var compositionView = new CompositionView();
        var headerViewModel = new Backbone.Model({
            canDownload: Modernizr.adownload
        });

        this.showChildView('mainRegion', compositionView);
        this.showChildView('topRegion', new HeaderView({
            model: headerViewModel
        }));
        
        this.listenTo(compositionView, CompositionView.DID_ADD_OBJECT, this._handleCanvasObjectCountChanged);
        this.listenTo(compositionView, CompositionView.DID_REMOVE_OBJECT, this._handleCanvasObjectCountChanged);
        this.listenTo(compositionView, CompositionView.DID_SELECT_OBJECT, this._handleCanvasObjectSelected);
        this.listenTo(compositionView, CompositionView.DID_DESELECT_OBJECT, this._handleCanvasObjectDeselected);
        this.listenToOnce(compositionView, 'destroy', this._onCompositionViewDestroy);

        // Initialize the composer
        compositionView.createNewComposition();
        this._sceneToolkitController.context = {compositionView: compositionView};
        this._setToolbarActive(true);
    }

    __skipUpload() {
        var image = new Image();

        image.src = 'img/_tmp/composition.jpg';
        image.onload = function() {
            this._onImageLoaded(null, image);
        }.bind(this);
    }

    _onSwapMainView() {
        this.topRegion.empty();
        this.bottomRegion.empty();
        this.canvasTopSliderRegion.empty();
        this.canvasRightSliderRegion.empty();
        this.wizardRegion.empty();
        this.notificationRegion.empty();
    }

    _onWizardRegionShow() {
        this._setToolbarActive(false);
        this.listenToOnce(this.wizardRegion.currentView, 'wizard-complete', function(lightSetController){
            if (this.isReplacingAllLightSets) {
                const compositionView = this.mainRegion.currentView;
                compositionView.historyManager.truncateEntriesTo(0);
                compositionView.replaceAllLightSetsWith(lightSetController);
            } else {
                // Add the light set to the ApplicationState registry, for PDF rendering
                this.mainRegion.currentView.addLightSetController(lightSetController, true, true);
            }
            
            this.isReplacingAllLightSets = false;
            this.wizardRegion.empty();
        });

        this.wizardRegion.$el.show();
    }

    _onWizardRegionEmpty() {
        this.wizardRegion.$el.hide();
        this._setToolbarActive(true);
        this.activateTool(SceneToolbarItemDataCollection.EDIT);
    }

    _onShowError(view, title, message) {
        // Show errors regardless of being dismissed earlier or not
        this._showNotification(new NotificationModel({
            title: title,
            message: message,
            type: NotificationModel.TYPE_WARNING
        }));
    }

    _showInfoNotification(title, message) {
        this._showNotification(new NotificationModel({
            title: title,
            message: message,
            type: NotificationModel.TYPE_INFO
        }));
    }

    _showNotification(notification) {
        this.showChildView('notificationRegion', new NotificationView({
            model: notification
        }));
    }

    _onImageLoaded(view, img) {
        const maxWidth = 1920;
        const maxHeight = 1080;
        if (img.width > maxWidth || img.height > maxHeight) {
            ImageUtil.resizeImage(img, maxWidth, maxHeight, this._onImageProcessingComplete.bind(this));
        } else {
            this._onImageProcessingComplete(img);
        }
    }

    _onImageProcessingComplete(img) {
        this._startNewComposition();
        this.mainRegion.currentView.backgroundBitmap = new createjs.Bitmap(img);

        const scale = this.mainRegion.currentView.backgroundBitmap.scaleX;
        this._sceneToolkitController.model.get('itemCollection').setSliderValue(SceneToolbarItemDataCollection.CROP_ZOOM, scale * 100);

        this.activateTool(SceneToolbarItemDataCollection.CROP);
    }

    // Save the stage when unloading it so that we can use it for exports
    _renderComposition() {
        const compositionView = this.mainRegion.currentView;
        for (let obj of compositionView.objects) {
            obj.selected = false;
        }

        let linesWereHidden = false;
        if (compositionView.lineContainer.visible) {
            linesWereHidden = true;
            compositionView.lineContainer.visible = false;
        }

        compositionView.stage.update();
        ApplicationState.set('stage', compositionView.stage.toDataURL());

        if (linesWereHidden) {
            compositionView.lineContainer.visible = true;
            compositionView.stage.update();
        }

        Backbone.history.navigate('/render', {trigger:true});
    }

    _loadProject(headerView, jsonString) {
        this._resetProject();
        this._startNewComposition();
        const compositionView = this.mainRegion.currentView;
        const historyManager = compositionView.historyManager;

        historyManager.pauseRecording();
        historyManager.truncateEntriesTo(0);
        SaveUtil.restore(compositionView, jsonString, function() {
            historyManager.resumeRecording();
        });
    }

    _setToolbarActive(isActive) {
        this._sceneToolkitController.isActive = isActive;
        this.sidebarRegion.currentView.isActive = isActive;
    }

    _defineRegions() {
        return {
            mainRegion: '#composer-main-region',
            topRegion: '#composer-top-region',
            sidebarRegion: '#composer-sidebar-region',
            bottomRegion: '#composer-bottom-region',
            canvasTopSliderRegion: '#composer-canvas-top-region',
            canvasRightSliderRegion: '#composer-canvas-right-region',
            wizardRegion: '#composer-wizard-region',
            notificationRegion: '#composer-notification-region'
        };
    }

    _handleSceneToolbarSelectEvent( sceneToolbarModel, model ) { 
        if (model.get('stateless') !== true) {
            this.canvasTopSliderRegion.empty();
            this.canvasRightSliderRegion.empty();
            this.notificationRegion.empty();
            this.topRegion.currentView.triggerMethod('hide:clone:source:button');

            $('body').off('keydown', this._onCloneStampKeyDown.bind(this));
            $('body').off('keyup', this._onCloneStampKeyUp.bind(this));

            if (model.get('title') && model.get('description')) {
                this._showInfoNotification(model.get('title'), model.get('description'));
            }
        }

        switch (model.get('type')) {
            // We handle the Add Set functionality ourselves, since it goes beyond the compositionView's
            // responsibilities (showing the wizard). All the other tool events are forwarded to the
            // SceneToolkitController.
            case SceneToolbarItemDataCollection.ADD_SET: {

                this.showChildView('wizardRegion', new LightsetWizardLayoutView({
                    lightSetModel: new LightSetModel(),
                    model: new LightSetWizardModel(),
                    preSelectColors: this._getPreselectColors()
                }));
                break;
            }

            case SceneToolbarItemDataCollection.CLONE: {
                this.topRegion.currentView.triggerMethod('show:clone:source:button');
                const cloneTool = this._sceneToolkitController.activeTool;
                cloneTool.setSourceMode(!cloneTool.hasSourcePoint());
                $('body').on('keydown', this._onCloneStampKeyDown.bind(this));
                $('body').on('keyup', this._onCloneStampKeyUp.bind(this));
                break;
            }

            case SceneToolbarItemDataCollection.CROP: {
                const topSliderModel = model.getSlider(SceneToolbarItemDataCollection.CROP_ROTATION);
                this.showChildView('canvasTopSliderRegion', new CropRotateSliderView({ model: topSliderModel }));
                for (let evt of ['change:value', 'change:coarseValue']) {
                    this.listenTo(topSliderModel, evt, this._setSceneToolbarInput.bind(this, model, topSliderModel));
                }

                const rightSliderModel = model.getSlider(SceneToolbarItemDataCollection.CROP_ZOOM);
                this.showChildView('canvasRightSliderRegion', new SliderView({ model: rightSliderModel }));
                this.listenTo(rightSliderModel, 'change:value', this._setSceneToolbarInput.bind(this, model, rightSliderModel));
                break;
            }
        }
    }

    _getPreselectColors() {
        // If there is a lightset on the canvas pass it the colors from the first lightset to prefill the creator
        if (this.mainRegion.currentView.lightSetContainer.children.length > 0) {
            let model = this.mainRegion.currentView.lightSetContainer.children[0].lightSetController.model.attributes;

            return {
                'luminaireColor': (model.luminaireColor) ? model.luminaireColor: false,
                'bracketColor': (model.bracketColor) ? model.bracketColor: false,
                'poleColor': (model.poleColor) ? model.poleColor: false
            };
        }

        return false;
    }

    _onCloneStampKeyDown(event) {
        const cloneTool = this._sceneToolkitController.activeTool;
        if (event.which === 18 && !cloneTool.inSourceMode()) { // alt key
            cloneTool.setSourceMode(true);
        }
    }

    _onCloneStampKeyUp(event) {
        const cloneTool = this._sceneToolkitController.activeTool;
        if (event.which === 18 && cloneTool.hasSourcePoint()) { // alt key
            cloneTool.setSourceMode(false);
        }
    }

    _onSaveProject() {
        SaveUtil.save(this.mainRegion.currentView);
    }

    _setSceneToolbarInput(toolbarItemModel, inputModel) {
        this._sceneToolkitController.setToolInput(toolbarItemModel.get('type'), inputModel);
    }

    _handleCanvasObjectSelected(canvasObj) {
        if (canvasObj instanceof LightSetObject) {
            const collection = new ObjectToolbarItemDataCollection();
            const model = new ToolbarModel(collection);

            this._objectToolkitController.model = model;
            this._objectToolkitController.context = {
                compositionView: this.mainRegion.currentView,
                lightSetObject: canvasObj
            };

            // Most functions of the toolbar are delegated to the ObjectToolkitController, but some (such as setting part colors) are handled by the menu
            // directly. In part because the menu also requires this model to determine which parts to show a color dropdown for.
            this.showChildView('bottomRegion', new ObjectToolbarLayoutView({model: model, lightSetModel: canvasObj.lightSetController.model}));
        }
    }

    _handleCanvasObjectDeselected(canvasObj) {
        if (canvasObj instanceof LightSetObject) {
            this.bottomRegion.empty();
            this._objectToolkitController.model = null;
        }
    }
    
    _handleCanvasObjectCountChanged() {
        const compositionView = this.mainRegion.currentView;
        const headerView = this.topRegion.currentView;
        headerView.canvasObjectCount = compositionView.objects.length - 1; // 1 is the background
    }
}
