export default
    class AbstractTool
{
    constructor( id ) {
        this._id = id;
    }
    
    get id() {
        return this._id;
    }
    
    get context() {
        return this._context;
    }
    
    set context( val ) {
        this._context = val;
    }
    
    get canActivate() {
        return true;
    }
    
    get hasMouseCursor() {
        return false;
    }
    
    get mouseCursor() { 
        throw "Abstract method get mouseCursor() not implemented in " + this.constructor.name;
    }
    
    activate() {
        console.log( 'Tool', this.id, 'activate()' );
        throw "Abstract method activate() not implemented in " + this.constructor.name;
    }
    
    processInput( value, type ) {
        console.log( 'Tool', this.id, 'processInput()', value, type );
        throw "Abstract method processInput() not implemented in " + this.constructor.name;
    }
    
    deactivate() {
        console.log( 'Tool', this.id, 'deactivate()' );
        throw "Abstract method deactivate() not implemented in " + this.constructor.name; 
    }
}