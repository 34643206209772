import Marionette from 'backbone.marionette';
import LightsetWizardFeatureItemView from 'views/lightset-wizard/LightsetWizardFeatureItemView';

export default Marionette.CollectionView.extend({
    className: 'lightset-wizard__slides',

    childView: LightsetWizardFeatureItemView,

    tagName: 'ul',

    childEvents: {
        'select': 'onChildSelect'
    },

    onChildSelect(view) {
        this.$el.children().removeClass('lightset-wizard__feature--active');
        view.$el.addClass('lightset-wizard__feature--active');
        this.trigger('select:item', view.model);
    }
});
