export default
    class PointUtil
{
    static distanceBetweenPoints( p1, p2 ) {
        let deltaX = p1.x - p2.x;
        let deltaY = p1.y - p2.y;
        return Math.sqrt( deltaX * deltaX + deltaY * deltaY );
    }
    
    static radiansBetweenPoints( p1, p2 ) {
        let deltaX = p2.x - p1.x;
        let deltaY = p2.y - p1.y;
        return Math.atan2( deltaX, deltaY );
    }
    
    static angleBetweenPoints( p1, p2 ) {
        let radians = PointUtil.radiansBetweenPoints( p1, p2 );
        return radians * 180 / Math.PI;
    }
}