import createjs from 'easeljs';
import async from 'async';

import BackgroundObject from 'views/composer/composition/objects/BackgroundObject';

import LightSetObject from 'views/composer/composition/objects/LightSetObject';
import LightSetController from 'controllers/LightSetController';

import LineObject from 'views/composer/composition/objects/LineObject';
import Line from 'views/composer/composition/objects/subjects/Line';

import SaveModel from 'models/ui/save/Save';
import SaveView from 'views/ui/save/SaveView';

import Memento from 'models/composer/history/Memento';
import StorageUtil from 'utils/storage/Storage';


export default
    class SaveUtil
{
    static get BACKGROUND_OBJECT() { return 'BACKGROUND_OBJECT'; }
    static get LIGHT_SET_OBJECT() { return 'LIGHT_SET_OBJECT'; }
    static get LINE_OBJECT() { return 'LINE_OBJECT'; }
    
    static get MANIFEST_FILENAME() { return 'manifest.json'; }
    
    static get kCanvasObjects() { return 'canvasObjects'; }
    
    static getTypeStringForObject( obj ) {
        if( obj instanceof BackgroundObject ) {
            return SaveUtil.BACKGROUND_OBJECT;
        } else if( obj instanceof LightSetObject ) {
            return SaveUtil.LIGHT_SET_OBJECT;
        } else if( obj instanceof LineObject ) {
            return SaveUtil.LINE_OBJECT;
        }
    }
    
    static save( compositionView ) {
        const canvasObjects = [];
        
        const recordOperations = [];
        for( let canvasObj of compositionView.objects ) {
            recordOperations.push( function( callback ) {
                return canvasObj.recordState( true, callback );
            });
        }
        
        async.series( recordOperations, function( err, results ) {
            for( let memento of results ) {
                const serializedMemento = memento.serialize();
                const type = SaveUtil.getTypeStringForObject( memento.target );
                canvasObjects.push({ type: type, data: serializedMemento });
            }
            
            const manifest = {};
            manifest[ SaveUtil.kCanvasObjects ] = canvasObjects;
            
            const blob = new Blob([JSON.stringify( manifest )], { type: "application/json" } );
            StorageUtil.save(blob);
        });
    }

    static restore( compositionView, jsonString, callback = null ) {
        
        const json = JSON.parse( jsonString );
        const canvasObjects = json[ SaveUtil.kCanvasObjects ];
        const restoreOperations = [];
        
        for( let entry of canvasObjects ) {
            const memento = new Memento();
            memento.deserialize( entry.data );
            
            switch( entry.type ) {
                case SaveUtil.BACKGROUND_OBJECT:
                    restoreOperations.push( function( callback ) {
                        compositionView.backgroundBitmap = new createjs.Bitmap();
                        memento.restore( compositionView.backgroundObject, callback );
                    });
                    break;
                
                case SaveUtil.LIGHT_SET_OBJECT:
                    restoreOperations.push( function( callback ) {
                        const controller = new LightSetController();
                        const lightSetObject = new LightSetObject( controller );

                        memento.restore( lightSetObject, function() {
                            lightSetObject.selectable = true;
                            compositionView.addObject( lightSetObject );
                            controller.render();
                            callback();
                        })
                    });
                    break;
                    
                case SaveUtil.LINE_OBJECT:
                    restoreOperations.push( function( callback ) {
                        const line = new Line();
                        const lineObject = new LineObject( line );
                        memento.restore( lineObject, function() {
                            compositionView.addObject( lineObject );
                            callback();
                        })
                    });
                    break;
            }
        }
        
        async.series( restoreOperations, function( err, results ) {
            callback();
        });
    }
}
