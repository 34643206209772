import Marionette from 'backbone.marionette';

export default
    class ModalRegion
    extends Marionette.Region {
    constructor() {
        super({
            el: '#modal-overlay' 
        });
    }
}
