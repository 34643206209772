import Marionette from 'backbone.marionette';

import cookieUtil from 'utils/cookies';
import Analytics from 'utils/Analytics';

import template from 'templates/ui/notification/notification';

export default
    class NotificationView
    extends Marionette.ItemView 
{
    static get COOKIE_PREFIX() {
        return 'prevent_notification_dialog';
    }
    
    constructor(params) {
        params = params || {};
        params.events = {
            'click .well__toggle': 'toggleDismissedState'
        };
        params.template = template;
        
        super(params);
        this.useCookieFallback = false;
        this.dismissed = false; // fallback for no-cookie browsers
    }
    
    onShow() {
        this.updateViewState();
    }
    
    updateViewState() {
        const dismissed = this.getCookieValue();
        this.$el.find('.well').toggleClass('well--hide', dismissed);
        this.$el.find('.icon').toggleClass('icon-arrow-up', dismissed);
        this.$el.find('.icon').toggleClass('icon-arrow-down', !dismissed);
    }
    
    toggleDismissedState() {
        if (this.getCookieValue()) {
            Analytics.track('show_help_text:button');
        } else {
            Analytics.track('hide_help_text:button');
        }
        this.setCookieValue(!this.getCookieValue());
        this.updateViewState();
    }
    
    setCookieValue(state) {
        // No fallback activated? Attempt to set the cookie
        if (!this.useCookieFallback) {
            cookieUtil.set(NotificationView.COOKIE_PREFIX, state);
        }
        
        // If cookie and expected value are out of sync, cookies are disabled, so fall back
        if (!this.useCookieFallback && this.getCookieValue() !== state) { 
            this.useCookieFallback = true;
        }
        
        // If the fallback was activated just now or in a previous function call, update this.dismissed instead of the cookie
        if (this.useCookieFallback) {
            this.dismissed = state;
        }
    }
    
    getCookieValue() {
        return this.useCookieFallback ? this.dismissed : cookieUtil.get(NotificationView.COOKIE_PREFIX) === 'true';
    }
}
