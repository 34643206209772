// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "    	<button class=\"slider__rotate slider__rotate--left\"><span class=\"slider__rotate-icon icon-undo\"></span> "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"-90°",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<label class=\"slider__label\">"
    + container.escapeExpression(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"label","hash":{},"data":data}) : helper)))
    + "</label>";
},"5":function(container,depth0,helpers,partials,data) {
    return "slider__handle--with-icon";
},"7":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<span class=\"slider__handle-icon icon-"
    + container.escapeExpression(((helper = (helper = helpers.icon || (depth0 != null ? depth0.icon : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"icon","hash":{},"data":data}) : helper)))
    + "\"></span>";
},"9":function(container,depth0,helpers,partials,data) {
    return "    	<button class=\"slider__rotate slider__rotate--right\"><span class=\"slider__rotate-icon icon-redo\"></span> "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : {},"+90°",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"slider\" data-orientation=\""
    + alias4(((helper = (helper = helpers.orientation || (depth0 != null ? depth0.orientation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orientation","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = (helpers.ifEquals || (depth0 && depth0.ifEquals) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"scene_toolbar_item_crop_rotation",{"name":"ifEquals","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.label : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <span class=\"slider__min-value\">"
    + alias4(((helper = (helper = helpers.min || (depth0 != null ? depth0.min : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"min","hash":{},"data":data}) : helper)))
    + ((stack1 = ((helper = (helper = helpers.labelAffix || (depth0 != null ? depth0.labelAffix : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelAffix","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n    <div class=\"slider__slider\">\n        <div class=\"slider__fill "
    + alias4(((helper = (helper = helpers.orientation || (depth0 != null ? depth0.orientation : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"orientation","hash":{},"data":data}) : helper)))
    + "\"></div>\n		<div class=\"slider__zero\"></div>\n        <div class=\"slider__handle "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.icon : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.icon : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n    </div>\n    <span class=\"slider__max-value\">"
    + alias4(((helper = (helper = helpers.max || (depth0 != null ? depth0.max : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"max","hash":{},"data":data}) : helper)))
    + ((stack1 = ((helper = (helper = helpers.labelAffix || (depth0 != null ? depth0.labelAffix : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"labelAffix","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</span>\n    \n"
    + ((stack1 = (helpers.ifEquals || (depth0 && depth0.ifEquals) || alias2).call(alias1,(depth0 != null ? depth0.type : depth0),"scene_toolbar_item_crop_rotation",{"name":"ifEquals","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
