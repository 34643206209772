import AbstractFilterTool from 'controllers/composer/tools/scene/AbstractFilterTool';

export default 
    class SceneToolBackgroundContrast
    extends AbstractFilterTool
{
    _setFilterValue( value ) {
        // range: -100 to 100
        this.context.compositionView.backgroundObject.colorMatrixFilter.contrast = value * .5;
    }
}
