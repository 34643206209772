import Backbone from 'backbone';
import Analytics from 'utils/Analytics';

import LightsetWizardAbstractLayoutView from 'views/lightset-wizard/LightsetWizardAbstractLayoutView';
import LightsetWizardFeaturesRowView from 'views/lightset-wizard/LightsetWizardFeaturesRowView';
import LightsetWizardFeatureCollection from 'models/lightset-wizard/FeatureCollection';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';
import LightsetWizardNoDataAvailableView from 'views/lightset-wizard/LightsetWizardNoDataAvailableView';

export default LightsetWizardAbstractLayoutView.extend({

    initialize() {
        this.lightPointType = this.model.get('lightPointType').id;
        this.poleFamilies = LightsetWizardDataUtil.getPoleFamiliesToDisplay(
            this.model.get('luminaireModel'),
            this.model.get('bracketModel'),
            this.lightPointType,
            this.model.get('height')
        );
    },

    onBeforeShow() {
        let polesView = new LightsetWizardFeaturesRowView({
            active: this.model.get('pole'),
            collection: new LightsetWizardFeatureCollection(this.poleFamilies)
        });

        this.listenTo(polesView, 'select:item', this.onSelectPoleFamily);
        this.showChildView('topRow', polesView);
    },

    onSelectPoleFamily(poleFamily) {
        Analytics.track('select_pole:product_type:' + poleFamily.get('name'));
        this.poleFamily = poleFamily;
        const models = LightsetWizardDataUtil.getPoleModels(
            poleFamily,
            this.lightPointType,
            this.model.get('height'),
            this.model.get('luminaireModel'),
            this.model.get('bracketModel')
        );

        if (!models || !models.length) {
            const noDataView = new LightsetWizardNoDataAvailableView({
                model: new Backbone.Model({
                    message: gettext(
                        'There are no poles available within the selected '
                        + 'height filter. Please go back and select a different height filter, or choose All.')
                })
            });

            this.showChildView('bottomRow', noDataView);
        } else {
            const poleModelsView = new LightsetWizardFeaturesRowView({
                active: this.model.get('poleModel'),
                collection: new LightsetWizardFeatureCollection(models)
            });

            this.triggerMethod('set:lightset:part', 'pole', poleFamily);

            this.stopListening(poleModelsView, 'select:item');
            this.listenTo(poleModelsView, 'select:item', this.onSelectPoleModel);
            this.showChildView('bottomRow', poleModelsView);
        }
    },

    onSelectPoleModel(model) {
        Analytics.track('select_pole:' + this.poleFamily.get('name') + ':' + model.get('name'));
        this.triggerMethod('set:lightset:part', 'poleModel', model);
    }
});
