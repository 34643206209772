import AbstractTool from 'controllers/composer/tools/AbstractTool';

import LightSetController from 'controllers/LightSetController';

export default 
    class ObjectToolRotate
    extends AbstractTool
{
    activate() {
        const controller = this.context.lightSetObject.lightSetController;
        controller.angleIndex++; // The index will automatically be normalized using modulo operations

        // this is an unfortunate hack that probably has better solutions that I am unaware of at the time
        // TODO: Redactor this
        controller.render(false);
        this.context.lightSetObject._realignSubject();
    }
    
    deactivate() {
        // Todo: deactivate() should only be called on tools that aren't stateless
    }
}
