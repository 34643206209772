import createjs from 'easeljs';

import AbstractTool from 'controllers/composer/tools/AbstractTool';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

export default 
    class ObjectToolErase
    extends AbstractTool
{
    constructor( id ) {
        super( id );
        
        this._radius = 30;
    }
    
    get lightSet() {
        return this.context.lightSetObject;
    }
    
    get compositionView() {
        return this.context.compositionView;
    }
    
    get stage() {
        return this.context.compositionView.stage;
    }
    
    get hasMouseCursor() { 
        return true;
    }
    
    get mouseCursor() {
        if( !this._mouseCursor ) {
            this._mouseCursor = new createjs.Shape();
            this._updateMouseCursorRadius();
        }
        return this._mouseCursor;
    }
    
    processInput( value, type ) {
        this._radius = value;
        this._updateMouseCursorRadius();
    }
    
    _updateMouseCursorRadius() {
        let r = this._radius;
        this.mouseCursor.graphics.clear()
        this.mouseCursor.graphics.beginStroke( 'rgba( 255, 255, 255, .6 )' ).setStrokeStyle( 1 ).drawCircle( 0, 0, r );
        this.mouseCursor.graphics.beginStroke( 'rgba( 0, 0, 0, .4 )' ).setStrokeStyle( 1 ).drawCircle( 0, 0, r - 1 );
        
        r += 2;
        this.mouseCursor.cache( -r, -r, r * 2, r * 2 );
    }
    
    activate() 
    {
        this.lightSet.allowWidgetInteraction = false;
        
        // Update the mask scaling
        this.lightSet.maskContainer.scaleX = this.lightSet.subject.scaleX;
        this.lightSet.maskContainer.scaleY = this.lightSet.subject.scaleY;
        
        this._mouseDownListener = this.stage.on( 'mousedown', this._onMouseDown, this );
        this._pressMoveListener = this.stage.on( 'pressmove', this._onPressMove, this );
        this.stage.update();
    }
    
    deactivate() 
    {
        this.lightSet.allowWidgetInteraction = true;
        
        this.stage.off( 'pressmove', this._pressMoveListener );
        this.stage.off( 'mousedown', this._mouseDownListener );
        this.stage.update();
    }
    
    _onMouseDown( evt ) {
        this.lightSet.dispatchEvent( CanvasObjectEvent.WILL_BE_EDITED );
        this._erase( evt );
    }
    
    _onPressMove( evt ) {
        this._erase( evt );
    }
    
    _erase( evt ) 
    {
        const pt = this.lightSet.subject.globalToLocal( evt.rawX, evt.rawY );
        const radius = this._radius / Math.abs(this.context.lightSetObject.subject.scaleX);
        
        this.lightSet.maskShape.graphics.clear();
        this.lightSet.maskShape.graphics.beginFill('rgba(0, 0, 0, 1)').drawCircle( pt.x, pt.y, radius );
        this.lightSet.maskContainer.updateCache( 'destination-out' );
        this.lightSet.maskContainer.scaleX = this.lightSet.subject.scaleX;
        this.lightSet.maskContainer.scaleY = this.lightSet.subject.scaleY;
        this.lightSet.subject.updateCache();
        
        this.stage.update();
    }
}