import Backbone from 'backbone';
import LightSetModel from 'models/lightset-wizard/LightSet';
import $ from 'jquery';
import createjs from 'easeljs';
import async from 'async';

// Made this a model for a possible future 'visible' history manager, since this is trivial to link to Backbone views...
// Plus it really is a data container, so ... 

export default
    class Memento
    extends Backbone.Model
{
    constructor( propertyMap=null ) {
        super();
        this._propertyMap = propertyMap;
    }
    
    get rom() {
        return this._rom;
    }
    
    get target() {
        return this._target;
    }
    
    serialize() {
        return JSON.stringify({ 
            rom: this._rom,
            propertyMap: this._propertyMap 
        });
    }
    
    deserialize( str ) {
        const obj = JSON.parse( str );
        this._rom = obj.rom;
        this._propertyMap = obj.propertyMap;
    }
    
    record( target, stringify=false, callback=null ) {
        this._validatePropertyMap();
        
        this._target = target;
        this._rom = {}
        this._stringify = stringify;
        
        const operations = this._traverseRecursively( this._target, this._propertyMap, this._rom, true );
        async.series( operations, this._getAsyncCallbackWrapper( callback ) );
        
        return this;
    }
    
    restore( targetOverride=null, callback=null ) { 
        this._validatePropertyMap();
        
        // targetOverride facilitates two things:
        // - When populating a memento from deserialization, (e.g. when loading a project), there will be no target. We can
        //   then create an empty canvas object and restore the memento on that object.
        // - Allow for Memento's to be restored on any other desired object, making for a true deep clone (which includes
        //   cloning image and canvas objects)
        const target = targetOverride ? targetOverride : this._target;
        const operations = this._traverseRecursively( target, this._propertyMap, this._rom, false );
        operations.push( function( callback ) {
            target.onStateRestored();
            callback();
        }.bind(this));
        
        async.series( operations, this._getAsyncCallbackWrapper( callback ) );
        return this;
    }

    _getRecordOperation(scope, propertyName, rom) {
        let value = (scope instanceof Backbone.Model || scope instanceof LightSetModel) ?
            scope.get(propertyName): scope[propertyName];

        if (value && value.nodeName) {
            // Regardless of whether the source is an image or a canvas instance, we always serialize to base64 (PNG)
            // and always deserialize to Canvas (more powerful and easier to work with). We only use createjs.Bitmap
            // throughout the application and it accepts either an image or canvas element. The clone and blur tools
            // convert the source image to a canvas upon the first edit, anyway. Keeps things a heck of a lot simpler.
            switch (value.nodeName) {
                case 'IMG':{
                    value = this._stringify ? this._imageToBase64( value ) : this._canvasCopyFromImage( value );
                    break;
                }

                case 'CANVAS':{
                    value = this._stringify ? this._canvasToBase64( value ) : this._canvasCopyFromImage( value );
                    break;
                }
            }
        }

        return function( callback ) {
            rom[propertyName] = value;
            callback();
        };
    }

    _getRestoreOperation (scope, propertyName, value) {
        if (typeof(value) == 'string') {
            const base64RegEx = new RegExp('[^-A-Za-z0-9+/=]|=[^=]|={3,}$');
            if (base64RegEx.test(value)) {
                return function(callback) {
                    this._assignValueAsCanvas(value, scope, propertyName, callback);
                }.bind(this);
            }
        }

        return function (callback) {
            if (scope instanceof Backbone.Model || scope instanceof LightSetModel) {
                scope.set(propertyName, value);
            } else {
                scope[propertyName] = value;
            }

            callback();
        };
    }

    _traverseRecursively( scope, list, rom, isRecording, operations=[], path='' ) {
        for( let p of list ) {
            if( typeof(p) == "string" ) {
                if( isRecording ) {
                    operations.push( this._getRecordOperation( scope, p, rom ) );
                } else {
                    operations.push( this._getRestoreOperation( scope, p, rom[p] ) );
                }
            }
            else if( typeof(p) == "object" ) {
                // If not a property, it's an object containing subproperties and respective property lists
                for( let key of Object.keys(p) ) {
                    if( isRecording && rom[key] == null ) {
                        rom[key] = {}
                    } 
                    this._traverseRecursively( scope[key], p[key], rom[key], isRecording, operations, path + '.' + key );
                }
            }
        }
        return operations;
    }
    
    _assignValueAsCanvas( base64, scope, propertyName, callback ) {
        const image = new Image();
        image.onload = function() {
            const canvas = this._canvasCopyFromImage( image );
            scope[propertyName] = canvas;
            callback();
        }.bind(this);
        
        image.src = base64;
    }
    
    _canvasToBase64( canvas ) {
        return canvas.toDataURL( 'image/png' );
    }
    
    _imageToBase64( image ) {
        const canvas = this._canvasCopyFromImage( image );
        return this._canvasToBase64( canvas );
    }
    
    _canvasCopyFromImage( image ) {
        // Accepts either image or canvas elements. We always normalize to canvas elements.
        const canvas = document.createElement( 'canvas' );
        canvas.width = image.width;
        canvas.height = image.height;
        
        const ctx = canvas.getContext('2d');
        ctx.drawImage( image, 0, 0 );
        return canvas;
    }
    
    _getAsyncCallbackWrapper( callback=null ) {
        if( typeof(callback) == 'function' ) {
            return function( err, result ) {
                // Mask the individual results and errors, just send our overarching status and results.
                // TODO: Catch errors and supply an overarching error.
                callback( null, this );
            }.bind( this );
        }
        else {
            return null;
        }
    }
    
    _validatePropertyMap() {
        if( !this._propertyMap ) {
            throw 'Cannot perform record or restore operation - no propertyMap defined in ' + this.constructor.name;
        }
    }
}
