import createjs from 'easeljs';

let __sharedInstance = null;

export default
    class AggregatedColorMatrixFilter
    extends createjs.ColorMatrixFilter
{
    constructor( matrix = null ) 
    {
        super();
        
        if( !__sharedInstance ) 
        {
            this.matrix = matrix ? matrix : new createjs.ColorMatrix();

            this._exposure = 0;
            this._brightness = 0;
            this._contrast = 0;
            this._saturation = 0;
            this._hue = 0;
            
            __sharedInstance = this;
        }
        
        return __sharedInstance;
    }
    
    get exposure() {
        return this._exposure;
    }
    
    set exposure( exposure ) {
        if( this._exposure != exposure ) {
            this._exposure = exposure;
            this._applyValues();
        }
    }
    
    get brightness() {
        return this._brightness;
    }
    
    set brightness( brightness ) {
        if( this._brightness != brightness ) {
            this._brightness = brightness;
            this._applyValues();
        }
    }
    
    get contrast() {
        return this._contrast;
    }
    
    set contrast( contrast ) {
        if( this._contrast != contrast ) {
            this._contrast = contrast;
            this._applyValues();
        }
    }
    
    get saturation() {
        return this._saturation;
    }
    
    set saturation( saturation ) {
        if( this._saturation != saturation ) {
            this._saturation = saturation;
            this._applyValues();
        }
    }
    
    get hue() {
        return this._hue;
    }
    
    set hue( hue ) {
        if( this._hue != hue ) {
            this._hue = hue;
            this._applyValues();
        }
    }
    
    _applyValues() {
        let brightness = this._brightness + (this._exposure / 2);
        let contrast = this._contrast + (this._exposure / 3);
        this.matrix.setColor( brightness, contrast, this._saturation, this._hue );
    }
}