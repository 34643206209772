import AbstractTool from 'controllers/composer/tools/AbstractTool';

export default 
    class ObjectToolRemove
    extends AbstractTool
{
    activate() {
        this.context.lightSetObject.requestDelete();
    }
    
    deactivate() {
    }
}