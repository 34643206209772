import $ from 'jquery';

import AbstractTool from 'controllers/composer/tools/AbstractTool';
import LightSetController from 'controllers/LightSetController';
import LightSetModelSerializer from 'controllers/LightSetModelSerializer';

export default 
    class ObjectToolDuplicate
    extends AbstractTool
{
    activate() {
        const controllerCopy = this.context.lightSetObject.lightSetController.copy();
        
        // We supply true as the initiallySelectable argument because this tool can only be triggered when editing an object,
        // e.g. the object to duplicate is selected and the edit tool is active. 
        // Objects are made selectable as soon as the edit tool activates, so if we don't do this,
        // the new object cannot be selected until the edit tool activation is triggered again.
        this.context.compositionView.addLightSetController( controllerCopy, true );
        
        controllerCopy.render();
    }
    
    deactivate() {
        // Todo: deactivate() should only be called on tools that aren't stateless
    }
    
}