import Backbone from 'backbone';

export default
    class ApplicationState {

    static set(key, value) {
        this.model.set(key, value);
    }

    static get(key) {
        return this.model.get(key);
    }

    static has(key) {
        return this.model.has(key);
    }

    static push(key, value) {
        let array = this.get(key) || [];

        array.push(value);
        this.set(key, array);
    }

    static get model() {
        return this._model || this._initModel();
    }

    static _initModel() {
        this._model = new Backbone.Model();

        return this._model;
    }
}
