// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<form class=\"image-uploader\" enctype=\"multipart/form-data\">\n    <div class=\"image-uploader__inner\">\n        <span class=\"image-uploader__icon icon-upload-image\"></span>\n        <h2 class=\"image-uploader__title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upload image or project file",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n        <p class=\"image-uploader__instructions\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Drop your image or project (.json) file here. Image must be at least 1280 pixels in width and 800 pixels in height.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n    </div>\n    <div class=\"image-uploader__actions\">\n        <label id=\"upload-image-button\" class=\"image-uploader__add-image button button--primary\" for=\"background-selector\"><!-- span class=\"icon-image\"></span-->"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upload image",{"name":"trans","hash":{},"data":data}))
    + "</label>\n        <label id=\"upload-project-button\" class=\"image-uploader__add-image button button--transparent\" for=\"background-selector\"><!--span class=\"icon-open-project\"></span-->"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Open project",{"name":"trans","hash":{},"data":data}))
    + "</label>\n        <input id=\"background-selector\" type=\"file\" class=\"image-uploader__hidden-input hidden-input\" name=\"files[]\" />\n    </div>\n</form>\n";
},"useData":true});
