export default
    class HistoryManager
{
    constructor( stage ) 
    {
        this._stage = stage;
        this._entries = [];
        this._recordingIsPaused = false;
        this._entryLimit = 5;
    }
    
    get entryLimit() {
        return this._entryLimit;
    }
    
    set entryLimit( val ) {
        this._entryLimit = val;
        this._truncateEntries();
    }
    
    get recordingIsPaused() {
        // No actor can add entries while we are paused, but this allows other actors to optimize some code,
        // by not performing any recordings to begin with, if we are paused.
        return this._recordingIsPaused;
    }
    
    addEntry( entry ) {
        if( !this.recordingIsPaused ) {
            this._entries.push( entry );
            this._truncateEntries();
        }
    }
    
    undo() {
        if( this._entries.length > 0 ) {
            let memento = this._entries.pop();
            memento.restore();
        }
    }
    
    pauseRecording() {
        this._recordingIsPaused = true;
    }
    
    resumeRecording() {
        this._recordingIsPaused = false;
    }
    
    truncateEntriesTo( limit ) {
        const previousLimit = this._entryLimit;
        this.entryLimit = limit;
        this.entryLimit = previousLimit;
    }
    
    _truncateEntries() {
        if( this._entryLimit > 0 ) { // 0 = unlimited undo :)
            while( this._entries.length > this._entryLimit ) {
                this._entries.shift();
            }
        }
    }
}