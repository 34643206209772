import queryString from 'query-string';

import cookieUtil from 'utils/cookies';


class LanguageUtil {
    static get DEFAULT_LANGUAGE() { 
        return 'en'; 
    }

    static get COOKIE_NAME() {
        return 'django_language';
    }

    static get PARAM() {
        return 'language';
    }

    static getCurrentLanguage() {
        if( cookieUtil.has(LanguageUtil.COOKIE_NAME) ) {
            return cookieUtil.get(LanguageUtil.COOKIE_NAME);
        }

        return LanguageUtil.DEFAULT_LANGUAGE;
    }

    static setLanguage(language) {
        cookieUtil.set(LanguageUtil.COOKIE_NAME, language, Infinity);
    }

    static reloadWithLanguage(language) {
        const { origin, pathname } = window.location;
        window.location.href = `${origin}${pathname}?${LanguageUtil.PARAM}=${language}`;
    }

    static activateLanguageFromURL() {
        const qs = queryString.parse(window.location.search);

        let languageCode = LanguageUtil.DEFAULT_LANGUAGE;
        
        /**
         * hasOwnProperty does not work since query-string 
         * returns an object created with Object.create(null) 
         * and thus does not have a prototype.
         */
        if (LanguageUtil.PARAM in qs) {
            languageCode = qs[LanguageUtil.PARAM];
        }

        LanguageUtil.setLanguage(languageCode);
        
        return LanguageUtil.getCurrentLanguage();
    }
};

export default LanguageUtil;
