// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression;

  return "	<p class=\"popover__text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.success : stack1), depth0))
    + " "
    + alias2(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"email","hash":{},"data":data}) : helper)))
    + "</p>\n	<div class=\"popover__actions\">\n		<button class=\"popover__cancel button button--primary\" type=\"button\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.closeLabel : stack1), depth0))
    + "</button>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {}, alias2=container.lambda, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isInvalid : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n	<div class=\"popover__actions\">\n		<form action=\".\" class=\"form form-horizontal popover__form\" novalidate>\n			<div class=\"form-group"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.validationError : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				<label for=\"email\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.email : stack1)) != null ? stack1.label : stack1), depth0))
    + "<span class=\"form-required\">*<span></label><input type=\"email\" name=\"email\" id=\"email\" placeholder=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.email : stack1)) != null ? stack1.placeholder : stack1), depth0))
    + "\" class=\"form-control"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.validationError : depth0)) != null ? stack1.email : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n			</div>\n\n			<div class=\"form-group"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.validationError : depth0)) != null ? stack1.subject : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n				<label for=\"subject\">"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.subject : stack1)) != null ? stack1.label : stack1), depth0))
    + "<span class=\"form-required\">*<span></label><input type=\"text\" name=\"subject\" placeholder=\""
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.subject : stack1)) != null ? stack1.placeholder : stack1), depth0))
    + "\" class=\"form-control"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.validationError : depth0)) != null ? stack1.subject : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subject : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " />\n			</div>\n\n			<div class=\"form-group form-group--actions\">\n				<button class=\"popover__confirm button button--primary\" type=\"submit\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.sendLabel : stack1), depth0))
    + "</button>\n				<button class=\"popover__cancel button button--transparent\" type=\"button\">"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.cancelLabel : stack1), depth0))
    + "</button>\n			</div>\n\n			<div class=\"form-group\">\n				<p><small>"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.required : stack1), depth0))
    + "</small></p>\n			</div>\n		</form>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inProgress : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "	</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<p class=\"popover__text error\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.error : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression;

  return alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.error : stack1)) != null ? stack1.form : stack1), depth0))
    + " ("
    + alias1(((helper = (helper = helpers.error || (depth0 != null ? depth0.error : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"error","hash":{},"data":data}) : helper)))
    + ")";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.error : stack1)) != null ? stack1.validation : stack1), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "		<p class=\"popover__text\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.modalText : stack1), depth0))
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " form-group--error";
},"13":function(container,depth0,helpers,partials,data) {
    return " form-control--error";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return " value=\""
    + container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"email","hash":{},"data":data}) : helper)))
    + "\"";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return " value=\""
    + container.escapeExpression(((helper = (helper = helpers.subject || (depth0 != null ? depth0.subject : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"subject","hash":{},"data":data}) : helper)))
    + "\"";
},"19":function(container,depth0,helpers,partials,data) {
    return "		<div class=\"spinner spinner--inverted spinner--centered spinner--on-top\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<h3 class=\"popover__header\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.translation : depth0)) != null ? stack1.modalHeader : stack1), depth0))
    + "</h3>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.isFinished : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
