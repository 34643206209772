import Backbone from 'backbone';

export default
    class SceneToolbarItemData
    extends Backbone.Model 
{
    get defaults() {
        return {
            isActive: false,
            stateless: false
        };
    }
    
    getSlider( type ) {
        const sliders = this.get('sliders');
        if (sliders) {
            return sliders.findWhere({ type: type });
        }
    }
}
