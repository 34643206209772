import blobStream from 'blob-stream';

import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import ApplicationState from 'app/ApplicationState';

import PDFRenderer from 'controllers/pdf/PDFRenderer';
import JSZip from 'jszip';

import template from 'templates/render/rendering';

export default
    class RenderingView 
    extends Marionette.ItemView 
{
    get template() {
        return template;
    }

    get templateHelpers() {
        return {
            cordova: ApplicationState.get('cordova')
        };
    }

    get base64Marker() {
        return ';base64,';
    }

    get modelEvents()  {
        return {
            change: this.update
        }
    }

    update() {
        this.updating = true;
        this.render();
    }

    onRender() {
        if (this.updating !== true) {
            ApplicationState.set('pdfImages', []);
            this.pdf = new PDFRenderer(this.model);
            const stream = this.pdf.pdf.pipe(blobStream());
            stream.on('finish', this._onPDFRender.bind(this, stream));

            this.pdf.render();
        }
    }

    _onPDFRender(stream) {
        // iPad/iPhone/iPad/Safari ==> Download PDF
        // other devices    ==> Download ZIP
        if (Modernizr.adownload) {
            this._downloadZip(stream);
        } else {
            this._downloadPdf(stream);
        }
    }
    
    _downloadZip(stream) {
        const reader = new FileReader();

        reader.onload = function() {
            const pdfAsBase64 = this.getRawBase64( reader.result );
            // const pdfAsArray = this.convertDataURIToBinary(pdfAsBase64);

            const zip = new JSZip();
            zip.file('composition.pdf', pdfAsBase64, {base64: true});

            // PDFJS.workerSrc = 'main.js';
            // PDFJS.disableWorker = true;
            // PDFJS.getDocument( pdfAsArray ).then(function(pdf) {
            //     console.log('loaded pdf');
            //     for( let i = 0; i < pdf.numPages; i ++ ) {
            //         console.log( 'page ' + i, pdf.getPage(i) );
            //     }


            // });

            // console.log('base64?', pdfAsBase64.substr(1, 500));

            // Add the images generated by ComposerPage and LightSetPage
            const pdfImages = ApplicationState.get('pdfImages');
            let lightSetIndex = 0;
            for (let img of pdfImages) {
                const base64Data = img.data.substr(img.data.indexOf(',') + 1);
                let title = img.type == 'composition' ? 'composition' : 'lightset' + (++lightSetIndex).toString();
                zip.file( title + '.png', base64Data, {base64: true});
            }

            ApplicationState.set('zip', zip.generate({type: 'blob'}));

            this.triggerMethod('render:completed');
        }.bind(this);

        reader.readAsDataURL(stream.toBlob());
    }

    _downloadPdf(stream) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            ApplicationState.set('pdf', stream.toBlob());
        } else {
            ApplicationState.set('pdf', stream.toBlobURL('application/pdf'));
        }

        this.triggerMethod('render:completed');
    }

    getRawBase64( base64WithDataUri ) {
        const base64Index = base64WithDataUri.indexOf(this.base64Marker) + this.base64Marker.length;
        const base64 = base64WithDataUri.substring(base64Index);
        return base64;
    }

    convertDataURIToBinary( rawBase64 ) {
        const raw = window.atob(rawBase64);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for(let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    }


}
