import AbstractTool from 'controllers/composer/tools/AbstractTool';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

export default 
    class SceneToolBackgroundCrop
    extends AbstractTool
{
    constructor( id ) {
        super( id );
    }
    
    get compositionView() {
        return this.context.compositionView;
    }
    
    activate() {
        this.compositionView.backgroundObject.selectable = true;
        this._previousSliderType = null;
    }
    
    deactivate() {
        this.compositionView.backgroundObject.selectable = false;
    }
    
    processInput( value, type ) {
        const obj = this.compositionView.backgroundObject;
        
        // Dispatch a will be edited event, just in time, so that undo history will be recorded.
        // We want history to be recorded just before the user starts manipulating the sliders, and only once
        // per time the same slider is manipulated. Not on every mouse down, so to speak.
        if( this._previousSliderType != type ) {
            obj.dispatchEvent( CanvasObjectEvent.WILL_BE_EDITED );
            this._previousSliderType = type;
        }
        
        switch( type ) {
            case SceneToolbarItemDataCollection.CROP_ROTATION:
                obj.rotation = value;
                break;
                
            case SceneToolbarItemDataCollection.CROP_ZOOM:
                obj.subject.scaleX = obj.subject.scaleY = value / 100;
                break;
        }
        
        this.compositionView.stage.update();
    }
}
