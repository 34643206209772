import AbstractPage from 'controllers/pdf/AbstractPage';
import ApplicationState from 'app/ApplicationState';

export default
    class ComposerPage 
    extends AbstractPage 
{
    get orientation() {
        return 'landscape';
    }

    get steps() {
        return [this._addComposition.bind(this), this._addHeader.bind(this), this._addFooter.bind(this)];
    }

    get footerY() {
        return this.height - 56;
    }

    get compositionY() {
        return 36;
    }

    get title() {
        return this.model.get('title');
    }

    _addHeader(callback) {
        if (this.title !== gettext('Untitled project')) {
            const padding = 20;
            this.pdf
                .rect(this.margin + padding, this.compositionY + padding, this.width - this.margin * 2 - 2 * padding, 50)
                .fillColor(this.colors.blue)
                .fillOpacity(0.2)
                .fill();

            this.pdf
                .font('CentraleSansMedium')
                .fontSize(30)
                .fillColor(this.colors.white)
                .text(this.title, this.margin + 12 + padding, this.compositionY + 7 + padding, {
                    width: 450
                });
        }
        callback();
    }

    _addComposition(callback) {
        const imgData = this.model.get('image');
        ApplicationState.push('pdfImages', {data: imgData, type: 'composition'});
        this.addImage(imgData, {
            x: this.margin,
            y: this.compositionY,
            width: this.width - 2 * this.margin
        }, callback);
    }
};
