import Backbone from 'backbone';

export default
    class SaveModel
    extends Backbone.Model {

    get defaults() {
        return {
            modalHeader: gettext('Save your project'),
            modalTxt: gettext('You can save your project and continue later. The project will be exported containing all the necessary assets.'),
            confirmLabel: gettext('Save'),
            cancelLabel: gettext('Cancel')
        };
    }
}
