import createjs from 'easeljs';

export default
	class LightSetHeightRuler
	extends createjs.Container
{
	constructor( pixelsPerMeter, meters, scale ) {
		super();

		this.pixelsPerMeter = pixelsPerMeter;
		this.meters = meters;
		this.lineThickness = 2 * scale;
		this.markerWidth = 20 * scale;
		this.fontSize = 11 * scale;
		this.markColor = 'rgba(160, 160, 160, 1)'

		this.drawVerticalLine();
		this.drawMarks();
	}

	drawVerticalLine() {
		const verticalLine = new createjs.Shape();
		verticalLine.graphics.beginFill(this.markColor).drawRect(0, 0, this.lineThickness, -(this.meters * this.pixelsPerMeter));
		this.addChild( verticalLine );
	}

	drawMarks() {
		for (let i = 0; i < this.meters; i ++) {
			const container = new createjs.Container();
			
			const mark = new createjs.Shape();
			mark.graphics.beginFill(this.markColor).drawRect(0, i ? -this.lineThickness / 2 : -this.lineThickness, this.markerWidth, this.lineThickness);
			mark.x = 0;
			mark.y = -(i * this.pixelsPerMeter);
			container.addChild(mark);

			if (i) {
				const label = new createjs.Text((i * 1000).toString() + 'mm', this.fontSize.toString() + 'px centrale_sans', this.markColor);
				label.x = this.markerWidth + (2 * this.lineThickness);
				label.y = mark.y - label.getBounds().height / 2.3;
				container.addChild(label);
			}
			
			this.addChild(container);
		}
	}

}