import Marionette from 'backbone.marionette';
import $ from 'jquery';

import FormUtils from 'utils/forms';

import ApplicationState from 'app/ApplicationState';

import template from 'templates/ui/email/email';

export default
    class EmailView
    extends Marionette.ItemView {

    get className() {
        return 'popover popover--fill';
    }

    get template() {
        return template;
    }

    get modelEvents() {
        return {
            'invalid': 'render',
            'change:inProgress': 'onProgressToggle',
            'change:isInvalid': 'onProgressToggle',
            'change:isFinished': 'onProgressToggle',
        }
    }

    onRender() {
        this.$('.popover__form').on('submit', this.onSubmit.bind(this));
        this.$('.popover__cancel').on('click', this.onCancel.bind(this));
    }

    onSubmit(event) {
        event.preventDefault();
        const data = FormUtils.serializeToModel(event.currentTarget);
        data.attachment = ApplicationState.get('zip') || ApplicationState.get('pdf');
        
        this.model.save(data);
    }

    onCancel() {
        this.destroy();
    }

    onProgressToggle() {
        this.$el.toggleClass('popover--lock', this.model.get('inProgress'));
        this.render();
    }
    
    serializeData() {
        const data = this.model.toJSON();
        if (this.model.validationError) {
            data.validationError = this.model.validationError;
        }
        return data;
    }
}
