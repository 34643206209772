import _ from 'underscore';
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import filesaverjs from 'filesaverjs';
import StringsUtil from 'utils/strings';
import Analytics from 'utils/Analytics';

import ApplicationState from 'app/ApplicationState';

import template from 'templates/render/finished';

import EmailModel from 'models/ui/email/Email';
import EmailView from 'views/ui/email/EmailView';

export default
    class FinishedView 
    extends Marionette.ItemView 
{
    get template() {
        return template;
    }
    
    get templateHelpers() {
        return {
            allowLightSetReplacement: ApplicationState.get('target') === 'composer',
            // allowEmail: !ApplicationState.get('cordova'),
            allowEmail: false,
        };
    }

    get popoverSelector() {
        return this.$el.find('> .popover');
    }


    get triggers() {
        return {
            'click #download': 'download',
            'click #email': 'email',
            'click #start-over': 'startOver',
            'click #replace-lightsets': 'replaceLightSets'
        };
    }
    
    onStartOver() {
        Analytics.track('back_to_project:button');
        ApplicationState.set('backToProject', true);
        Backbone.history.navigate(ApplicationState.get('target'), {trigger: true, replace: true});
    }

    onDownload() {
        if (ApplicationState.get('cordova')) {
            const stage = ApplicationState.has('stage') ? ApplicationState.get('stage') : _.first(ApplicationState.get('pdfImages')).data;
            const base64Data = stage.replace('data:image/png;base64,', '');
            cordova.base64ToGallery(base64Data, { prefix: 'philips_', mediaScanner: true }, this.onSaveToGallerySuccess, this.onSaveToGalleryFail);
        } else {
            if (ApplicationState.get('zip')) {
                Analytics.track('download_zip:button');
                // var filename = prompt('Save as:' , StringsUtil.filename(this.model.get('title')));
                const filename = prompt(gettext('Save as:'));
                filesaverjs.saveAs(ApplicationState.get('zip'), StringsUtil.filename(filename) + '.zip');
            } else {
                Analytics.track('download_pdf:button')
                const blob = ApplicationState.get('pdf');
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, 'rendertool-result.pdf');
                }
                else {
                    window.open(blob, '_blank');
                }
            }
        }
    }
   
    onReplaceLightSets() {
        ApplicationState.set('replacingAllLightSets', true);
        Backbone.history.navigate(ApplicationState.get('target'), {trigger: true, replace: true});
    }

    onEmail() {
        Analytics.track('email:button');
        const emailView = new EmailView({
            model: new EmailModel(),
        });

        if( !this.popoverSelector.length ) {
            this.$el.append(emailView.render().$el);
        }
    }

    /**
     *  Only called from the Cordova save to Photo gallery success callback
     */
    onSaveToGallerySuccess() {
        navigator.notification.alert(
            gettext('The render was saved to the Photo Gallery'), 
            func => func, 
            gettext('Download')
        );
    }

    /**
     *  Only called from the Cordova save to Photo gallery error callback
     */
    onSaveToGalleryFail(err) {
        navigator.notification.alert(
            gettext(`There was an error saving the render to the Photo Gallery (${err})`), 
            func => func, 
            gettext('Error')
        );
    }
}
