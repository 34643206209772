// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div id=\"lightset-wizard-header-region\"></div>\n<div class=\"lightset-wizard__main\">\n    <div class=\"lightset-wizard__progress\">\n        <div class=\"progress progress--large\">\n            <div class=\"progress__fill\"></div>\n        </div>\n    </div>\n    <div id=\"lightset-wizard-main-region\" class=\"lightset-wizard__main-region\"></div>\n</div>\n\n<div class=\"lightset-wizard__footer\">\n    <button class=\"lightset-wizard__back\"><span class=\"icon-back-arrow\"></span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Back",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    <button class=\"lightset-wizard__skip-to-poles button button--primary\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Go to Poles",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    <button class=\"lightset-wizard__next button button--primary\">"
    + alias3(((helper = (helper = helpers.nextBtnLabel || (depth0 != null ? depth0.nextBtnLabel : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"nextBtnLabel","hash":{},"data":data}) : helper)))
    + "</button>\n    \n</div>\n";
},"useData":true});
