import AbstractToolkitController from 'controllers/composer/tools/AbstractToolkitController';
import ObjectToolbarItemType from 'models/composer/toolbars/ObjectToolbarItemType';

import ObjectToolRotate from 'controllers/composer/tools/object/ObjectToolRotate';
import ObjectToolFlip from 'controllers/composer/tools/object/ObjectToolFlip';
import ObjectToolDuplicate from 'controllers/composer/tools/object/ObjectToolDuplicate';
import ObjectToolErase from 'controllers/composer/tools/object/ObjectToolErase';
import ObjectToolRemove from 'controllers/composer/tools/object/ObjectToolRemove';


export default
    class ObjectToolkitController
    extends AbstractToolkitController
{   
    // Abstract method overrides
    _registerTools() 
    {
        let map = [
            ObjectToolbarItemType.ROTATE,       ObjectToolRotate,
            ObjectToolbarItemType.FLIP,         ObjectToolFlip,
            ObjectToolbarItemType.DUPLICATE,    ObjectToolDuplicate,
            ObjectToolbarItemType.ERASER,       ObjectToolErase,
            ObjectToolbarItemType.REMOVE,       ObjectToolRemove,
        ];
        
        for( let i = 0; i < map.length; i += 2 ) {
            let toolId = map[i];
            let ToolClass = map[i + 1];
            this._registerTool( toolId, ToolClass );
        }
    }
}