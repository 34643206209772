import createjs from 'easeljs';

import ApplicationState from 'app/ApplicationState';

import CanvasObject from 'views/composer/composition/objects/CanvasObject';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';
import LightSetController from 'controllers/LightSetController';

import MoveWidget from 'views/composer/composition/objects/widgets/MoveWidget';
import FreeTransformWidget from 'views/composer/composition/objects/widgets/FreeTransformWidget';
import RotationWidget from 'views/composer/composition/objects/widgets/RotationWidget';
import FlipWidget from 'views/composer/composition/objects/widgets/FlipWidget';

export default
    class LightSetObject
    extends CanvasObject {

    constructor(lightSetController, widgetClasses = null){
        const output = lightSetController.output;

        super(output, widgetClasses);

        // See method get dpiMultiplier().
        // First step of applying the DPI increase is to downscale the outer container.
        // The inner container won't be upscaled here; this is done in the _realignSubject method, as it needs to
        // happen after every rerender.
        const canvasParameters = ApplicationState.get('composerCanvasParameters');

        output.scaleX = output.scaleY = 0.8 / this.dpiMultiplier;

        // If we restore a lightSetObject from a save file, the lightset will need time to render before we
        // can reapply the saved mask (created by erasing parts of the lightset). The mask cache will be stored in
        // this property.
        this._deferredMaskCache = null;

        this._lightSetController = lightSetController;
        this._lightSetController.on(LightSetController.UPDATED, this._onLightSetControllerUpdate, this);

        if (this._lightSetController.isRendered) {
            // It was added after the lightSetController has rendered, so just update the view
            this._onLightSetControllerRender();
        } else {
            // It will render at some point in the future, so listen up.
            this._lightSetController.on(LightSetController.RENDERED, this._onLightSetControllerRender, this);
        }
    }

    get dpiMultiplier() {
        // Tools such as the Erase tool will cause the lightset to be cached as bitmap.
        // The lightset contains a downscaled version of all its elements, so zooming in on the lightset will still
        // result in sharp images. The caching however, will result in a 1:1 bitmap, so zooming in by any amount will
        // zoom pixels beyond their 100% size. We increase this 'dpi' by upscaling the inner container,
        // then downscaling the output by the same factor, to compensate. Don't use a too large value, since that
        // would result in a lot of memory usage and large erase masks to be drawn/saved.
        return 3.0;
    }

    get defaultWidgetClasses() {
        return [MoveWidget, FreeTransformWidget, RotationWidget, FlipWidget];
    }

    get lightSetController() {
        return this._lightSetController;
    }

    get mementoPropertyMap() {
        return super.mementoPropertyMap.concat([
            'maskCache',
            {subject: ['regX', 'regY']},
            {lightSetController: ['serializedModel', 'resultWidth', 'resultHeight']}
        ]);
    }

    get maskShape() {
        if (!this._maskShape) {
            const s = new createjs.Shape();

            s.regX = this.subject.regX;
            s.regY = this.subject.regY;
            this._maskShape = s;
        }

        return this._maskShape;
    }

    get maskContainer() {
        if (!this._maskContainer) {
            const bounds = this.subject.getBounds();
            const c = new createjs.Container();

            c.addChild(this.maskShape);
            c.cache(-this.subject.regX, -this.subject.regY, bounds.width, bounds.height);
            c.cacheCanvas.getContext('2d').fillRect(0, 0, bounds.width, bounds.height);
            c.visible = false;

            this.subject.filters = [new createjs.AlphaMaskFilter(c.cacheCanvas)];
            this.subject.cache(0, 0, bounds.width, bounds.height);

            this.addChild(c);
            this._maskContainer = c;
        }

        return this._maskContainer;
    }

    get maskCache() {
        if (this._maskContainer && this._maskContainer.cacheID) {
            const cache = document.createElement('canvas');

            cache.width = this._maskContainer.cacheCanvas.width;
            cache.height = this._maskContainer.cacheCanvas.height;

            const ctx = cache.getContext('2d');

            ctx.drawImage(this._maskContainer.cacheCanvas, 0, 0);

            return cache;
        }

        return null;
    }

    set maskCache(cache) {
        // Only update the mask cache if there actually was a cache. When restoring from memento for example,
        // set maskCache() will be called with null if there was no previous mask.
        if (cache != null) {
            // When restoring a project from a save file, we need to wait for the lightset to render before we can
            // apply the mask.
            if (!this.lightSetController || !this.lightSetController.isRendered) {
                this._deferredMaskCache = cache;
            } else {
                // Everything ready to go? Apply the mask from cache.
                const ctx = this.maskContainer.cacheCanvas.getContext('2d');

                ctx.clearRect(0, 0, this.maskContainer.cacheCanvas.width, this.maskContainer.cacheCanvas.height);

                if (cache) {
                    ctx.drawImage(cache, 0, 0);
                    this.subject.updateCache();
                }
            }
        }
    }

    _onLightSetControllerUpdate(evt) {
        // The images of the lightset load asynchronously, so make sure the stage updates after each image that loads.

        // This event handler will also trigger when the lightset's colors change, so update the cache of the subject,
        // if it exists (or else no color updates will be visible after using anything that sets a mask, such as the
        // Erase tool).
        if (this.subject.cacheCanvas != null) {
            this.subject.updateCache();
        }

        // Tells this CanvasObject to dispatch an update event, which will (indirectly repaint the canvas.
        this.update();
    }

    _onLightSetControllerRender(evt = null) {
        // New images might have been loaded  (eg when replacing lightsets) so update the registration point.
        // we don't realign after every controller update anymore because that gives unexpected results when setting
        // bounds
        this._realignSubject();

        // Done rendering? Apply the deferred mask cache if we have any.
        if (this._deferredMaskCache != null && this.lightSetController.isRendered) {
            this.maskCache = this._deferredMaskCache;
            this._deferredMaskCache = null;
        }

        this.update();
    }

    _realignSubject() {
        // See method get dpiMultiplier().
        // Step one: increase the scale of the container so that cleanForRendertool() will generate correct bounds
        // and apply correct positioning
        this.subject.container.scaleX = this.subject.container.scaleY =
            this._lightSetController.scale * this.dpiMultiplier;

        // Step two: get some clean output (whitespace-cropped and without height label)
        const bounds = this._lightSetController.cleanForRendertool();

        if (bounds) {
            // Step three: Re-center the registration point, since its dimensions might have changed because
            // additional elements were loaded. The center registration is required for intuitive scaling and rotation
            this.subject.regX = bounds.width / 2;
            this.subject.regY = bounds.height / 2;
        }
    }
}
