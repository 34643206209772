import createjs from 'easeljs';

import AbstractWidgetGraphic from 'views/composer/composition/objects/widgets/graphics/AbstractWidgetGraphic';

export default
    class IconHandle
    extends AbstractWidgetGraphic
{
    static get ICON_LOADED() { return 'ICON_LOADED_EVENT'; }
    
    constructor( iconUri, iconSize=14 ) {
        super();
        
        this.mouseChildren = false;
        let size = 11;
        
        // draw the handle
        this.sprite = new createjs.Shape();
        this.sprite.graphics.beginStroke( 'rgba( 0, 0, 0, 0.4 )' );
        this.sprite.graphics.beginFill( 'rgba(255, 255, 255, .9)' );
        this.sprite.graphics.drawCircle( 0, 0, size );
        this.addChild( this.sprite );
        
        // draw the hitarea
        this.hitArea = new createjs.Shape();
        this.hitArea.graphics.beginFill( 'red' );
        this.hitArea.graphics.drawCircle( 0, 0, size * 2.5 );
        
        if (iconUri != null) {
            const ref = this;
            const bmp = new createjs.Bitmap( iconUri );
            bmp.image.onload = function() {
                const scale = iconSize / this.width;
                bmp.scaleX = bmp.scaleY = scale;
                bmp.x -= scale * this.width / 2;
                bmp.y -= scale * this.height / 2;
                ref.dispatchEvent( IconHandle.ICON_LOADED );
            }
            
            this.icon = bmp;
            this.addChild( this.icon );
        }
    }
}