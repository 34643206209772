import AbstractTool from 'controllers/composer/tools/AbstractTool';

import Line from 'views/composer/composition/objects/subjects/Line';
import LineObject from 'views/composer/composition/objects/LineObject';

import PointUtil from 'views/utils/PointUtil';

export default 
    class SceneToolAddLine
    extends AbstractTool
{
    constructor( id ) {
        super( id );
        this._stageListeners = {};
    }
    
    get compositionView() {
        return this.context.compositionView;
    }
    
    get backgroundBitmap() {
        if( this.compositionView && this.compositionView.backgroundObject ) {
            return this.compositionView.backgroundObject.bitmap;
        } else {
            return null;
        }
    }
    
    activate() {
        if(this.compositionView.lineContainer.visible) {
            this._setStageListeners( true );
        }
    }
    
    deactivate() {
        this._setStageListeners( false );
    }

    processInput( value, type ) {
        var isVisible = value === 1;

        this.compositionView.lineContainer.visible = isVisible;
        this._setStageListeners(isVisible);
        this.compositionView.stage.update();
    }
    
    _setStageListeners( add ) 
    {
        let types = ['mousedown', 'pressmove', 'click']
        let stage = this.compositionView.stage;
        
        if( add ) {
            for( let type of types ) {
                if( !(type in this._stageListeners) ) {
                    this._stageListeners[type] = stage.on( type, this._handleStageMouseEvent, this );
                }
            }
        } else { 
            for( let type in this._stageListeners ) {
                stage.off( type, this._stageListeners[type] );
            }
            this._stageListeners = {};
        } 
    }
    
    _handleStageMouseEvent( evt ) 
    {
        switch( evt.type ) {
            case 'mousedown':
                // Record the mousedown point
                this._startPoint = new createjs.Point( evt.rawX, evt.rawY );
                this._line = null;
                break;
                
            case 'pressmove':
                let currentPoint = new createjs.Point( evt.rawX, evt.rawY );
                
                // Only create the line if the mouse displaced more than 5px from the start point
                if( !this._line && PointUtil.distanceBetweenPoints( this._startPoint, currentPoint ) > 5 ) {
                    this._line = new Line();
                    this._line.mouseEnabled = false; // Prevent the line from swallowing the click event
                    this._line.pointA = this._startPoint;
                    this.compositionView.stage.addChild( this._line );
                }
                
                // Only rerender the line's endpoint if the line actually exists, obviously
                if( this._line ) {
                    this._line.pointB = currentPoint;
                    this.compositionView.stage.update();
                }
                break;
                
            case 'click':
                // If a line was drawn, wrap it into a LineObject. If not, do nothing... Mouse didn't move enough to draw a line.
                if( this._line ) {
                    this._line.mouseEnabled = true;
                    let lineObj = new LineObject( this._line );
                    this.compositionView.addObject( lineObj );
                    this._line = null;
                }
                break;
        }
    }    
}
