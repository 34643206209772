import AbstractTool from 'controllers/composer/tools/AbstractTool';

export default 
    class SceneToolUndo
    extends AbstractTool
{
    activate() {
        // Temp functionality. This should open the LightSet Wizard, but we're 
        // just adding a dummy object to the scene for development.
        this.context.compositionView.historyManager.undo();
    }
    
    deactivate() {
        // this tool is stateless and has nothing to do upon deactivation
    }
}