import createjs from 'easeljs';

export default
    class LightSetLabelHelper {
        
    static get BG_COLOR() {
        return '#4d87c2';
    }

    static getArrow(x, y, width, height) {
        let arrow = new createjs.Shape();

        arrow.graphics
            .beginFill(this.BG_COLOR)
            .moveTo(x, y) // start at the left side of the button, down Y pixels
            .lineTo(x, y + height) // go down
            .lineTo(x - width, y + height / 2) // go left and halfway up
            .lineTo(x, y); // go back to origin to close

        return arrow;
    }

    static getLabel(text, x, y) {
        let label = new createjs.Text(text, '14px centrale_sans', 'white');

        label.x = x;
        label.y = y;
        label.textAlign = 'center';
        label.textBaseline = 'middle';

        return label;
    }

    static getBackground(width, height) {
        let background = new createjs.Shape();

        background.graphics
            .beginFill(this.BG_COLOR)
            .drawRect(0, 0, width, height);

        return background;
    }
}
