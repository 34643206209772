import Backbone from 'backbone';

export default
    class TemplateTranslations
    extends Backbone.Model 
{
    get defaults() {
        return {
            translations: [
                gettext('All'),
                gettext('Back'),
                gettext('Reset'),
                gettext('Open project'),
                gettext('Save project'),
                gettext('Back to project'),
                gettext('Render image'),
                gettext('Rendering image'),
                gettext('Rendering completed'),
                gettext('You can now download your rendering.'),
                gettext('Download'),
                gettext('One moment please'),
                gettext('Please be patient, this is quite a heavy process for an iPad.'),
                gettext('Do not close the application.'),
                gettext('All'),
                gettext('Luminaire'),
                gettext('Bracket'),
                gettext('Pole'),
                gettext('Go to Poles'),
                gettext('Upload image or project file'),
                gettext('Drop your image or project (.json) file here. Image must be at least 1280 pixels in width and 800 pixels in height.'),
                gettext('Upload image'),
                gettext('Color parts individually'),
                gettext('Entire lightset'),
                gettext('Replace light sets'),
                gettext('Download'),
                gettext('E-mail'),
                gettext('Feel free to add a title to the final image. The image will be rendered in PDF (with title and without guides) and JPG (without title and lines).'),
                gettext('Cancel'),
                gettext('Add a title here'),
                gettext('© 2017 Koninklijke Philips N.V. All rights reserved.'),
                gettext('Philips reserves the right to make changes in specifications and/or to discontinue any product at any time without notice or obligation and will not be liable for any consequences resulting from the use of this publication.'),
                gettext('© 2017 Koninklijke Philips N.V. All rights reserved. Philips reserves the right to make changes in specifications and/or to discontinue any product at any time without notice or obligation and will not be liable for any consequences resulting from the use of this publication. www.lighting.philips.com'),
                gettext('Products'),
                gettext('Your light set'),
                gettext('Select background'),
                gettext('Wall'),
                gettext('No Background'),
                gettext('Park'),
                gettext('Street with building'),
                gettext('Street with car'),
                gettext('Residential street'),
                gettext('Project name'),
                gettext('Project info'),
                gettext('Title'),
                gettext('Hide help text'),
                gettext('Show help text'),
            ],
        };
    }
}
