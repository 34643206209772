import Marionette from 'backbone.marionette';
import template from 'templates/lightset-wizard/lightset-wizard-feature';

export default Marionette.ItemView.extend({
    className: 'lightset-wizard__feature',

    template: template,

    tagName: 'li',

    triggers: {
        'click': 'select'
    }
});
