import createjs from 'easeljs';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';
import LightSetLabelHelper from 'controllers/LightSetLabelHelper';

import NumbersUtil from 'utils/numbers';

export default
    class LightSetRearHeightHandleController
    extends createjs.EventDispatcher {

    static get STEP() {
        return 500;
    }

    static get BG_COLOR() {
        return '#4d87c2';
    }

    constructor(stage, onHeightChange, start, min, max, scale) {
        super();

        this.handle = new createjs.Container();
        this.stage = stage;
        stage.addChild(this.handle);

        let handleHeight = 32;
        let handleWidth = 140;
        let handleBg = LightSetLabelHelper.getBackground(handleWidth, handleHeight);
        let handleTxt = LightSetLabelHelper.getLabel(this.currentHeight + ' mm', handleWidth / 2, handleHeight / 2 + 2);
        let handleArrow = LightSetLabelHelper.getArrow(handleWidth, 10, 8, handleHeight - 2 * 10);
        let minusButton = this._createAdjustHeightButton(18, handleHeight / 2, false);
        let plusButton = this._createAdjustHeightButton(handleWidth - 18, handleHeight / 2, true);

        this.handle.addChild(handleBg);
        this.handle.addChild(handleTxt);
        this.handle.addChild(handleArrow);
        this.handle.addChild(minusButton);
        this.handle.addChild(plusButton);

        this.handle.cursor = 'ns-resize';
        this.handle.addEventListener('mousedown', (event) => this._onHeightHandleMouseDown(event));
        this.handle.addEventListener('pressmove', (event) => this._onHeightHandlePressMove(event));
        this.handle.regY = handleHeight / 2 + 5;

        this.textObj = handleTxt;
        this.onHeightChange = onHeightChange;
        this.min = min;
        this.max = max;
        this.scale = scale;

        this.setHeight(start);
    }

    set currentHeight(height) {
        this.onHeightChange(height);
        this.textObj.text = height + ' mm';
        this._currentHeight = height;
    }

    get currentHeight() {
        return this._currentHeight; // Default height is max height
    }

    _createAdjustHeightButton(x, y, increaseHeight) {
        const btnHeight = 20;
        const btnWidth = btnHeight;

        let btnContainer = new createjs.Container();
        let btnBackground = new createjs.Shape();
        let btnText = new createjs.Text(increaseHeight ? '+' : '-', '18px centrale_sans_bold', 'white');

        btnContainer.cursor = 'pointer';

        btnBackground.graphics
            .beginFill(LightSetLabelHelper.BG_COLOR)
            .beginStroke('white')
            .drawRoundRect(0, 0, btnWidth, btnHeight, 1);

        btnContainer.addChild(btnBackground);

        btnText.textAlign = 'center';
        btnText.textBaseline = 'middle';
        btnText.x = btnWidth / 2;
        btnText.y = btnHeight / 2 + 2;
        btnContainer.addChild(btnText);

        btnContainer.addEventListener('click', this._onHeightAdjustBtnClick.bind(this, event, increaseHeight));
        btnContainer.x = x;
        btnContainer.y = y;
        btnContainer.regX = btnWidth / 2;
        btnContainer.regY = btnHeight / 2;
        btnContainer.mouseChildren = false;

        btnContainer.ignoreDrag = true;

        return btnContainer;
    }

    _onHeightAdjustBtnClick(event, increaseHeight) {
        let height = increaseHeight ?
            this.currentHeight + LightSetRearHeightHandleController.STEP :
            this.currentHeight - LightSetRearHeightHandleController.STEP;

        this.setHeight(height);
    }

    _onHeightHandleMouseDown(event) {
        this.startY = event.rawY;
        this.previousHeight = this.currentHeight;
    }

    _onHeightHandlePressMove(event) {
        if (event.target.ignoreDrag) {
            return;
        }

        let deltaY = event.rawY - this.startY;
        let step = this.heightToPixels(LightSetRearHeightHandleController.STEP); // Minimum required pixels to shift
        let height = this.previousHeight - Math.round(deltaY / step) * LightSetRearHeightHandleController.STEP;

        this.setHeight(height);
    }

    setHeight(height) {
        if (height <= this.max && height >= this.min && height !== this.currentHeight) {
            this.currentHeight = height;
            this.handle.y = this.stage.canvas.height - this.heightToPixels(height);

            this.stage.update();
        }
    }

    heightToPixels(height) {
        return height * (335 / 4500);
    }
}
