export default class CordovaFs {

    /**
     * Get the filename for saving
     */
    static get filename() {
        return 'project.json';
    }

    /**
     * Save a Blob instance to the iOS filesystem
     * @param {Blob} blob 
     */
    static save(blob) {
        // TODO: Make this more dry
        CordovaFs.blob = blob;
        CordovaFs.readOnly = false;
        CordovaFs.fileOptions = { create: true, exclusive: false };
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, CordovaFs.gotFileSystem, CordovaFs.fail);
    }

    /**
     * Read the data from project.json on the iOS filesystem
     */
    static read(callback) {
        // TODO: Make this more dry
        CordovaFs.callback = callback;
        CordovaFs.readOnly = true;
        CordovaFs.fileOptions = {};
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, CordovaFs.gotFileSystem, CordovaFs.fail);
    }

    /**
     * Verify we can get the file on the iOS filesystem
     * @param {FileSystem} fileSystem An interface representing a file system
     */
    static gotFileSystem(fileSystem) {
        fileSystem.root.getFile(CordovaFs.filename, CordovaFs.fileOptions, CordovaFs.gotFileEntry, CordovaFs.fail);
    }

    /**
     * Verify we can write to the filesystem
     * @param {FileEntry} fileEntry An interface representing a file on the file system.
     */
    static gotFileEntry(fileEntry) {
        if (CordovaFs.readOnly) {
            fileEntry.file(CordovaFs.gotFileRead, CordovaFs.fail);
        } else {
            fileEntry.createWriter(CordovaFs.gotFileWriter, CordovaFs.fail);
        }
    }

    /**
     * Write the project file to disk
     * @param {FileWriter} writer This class writes to the mobile device file system.
     */
    static gotFileWriter(writer) {
        writer.onerror = CordovaFs.fail;
        writer.write(CordovaFs.blob);
    }

    /**
     * Read the project file from disk
     * @param {File} file 
     */
    static gotFileRead(file) {
        let reader = new FileReader();
        reader.onloadend = CordovaFs.callback;
        reader.onerror = CordovaFs.fail;
        reader.readAsText(file);
    }

    /**
     * Global CordovaFs error handler. Give feedback on fail.
     * @param {*} err 
     */
    static fail(err) {
        let message;
        let title;

        switch(err.code) {
            case 1: {
                title = 'Project'
                message = 'No previous project has been saved. Please save a project first.';
                break;
            }
            default: {
                title = 'Error'
                message = `An unknown error occured, please try again (FileError ${err.code})`;
                break;
            }
        }

        navigator.notification.alert(message, func => func, title);
    }

}
