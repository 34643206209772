// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "toggle--on";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"toggle "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.on : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n	<span class=\"toggle__icon-hide icon-hide\"></span>\n	<div class=\"toggle__switch\">\n		<div class=\"toggle__switch-handle\"></div>\n	</div>\n	<span class=\"toggle__icon-show icon-show\"></span>\n</div>\n";
},"useData":true});
