import Marionette from 'backbone.marionette';

import SelectItemView from 'views/ui/select/SelectItemView';

var SelectCollectionView = Marionette.CollectionView.extend({

    className: 'select__options',

    childView: SelectItemView,

    onBeforeShow() {
        if (this.collection.length > 5) {
            this.$el.addClass('select__options--scrollbar');
        }
    }
});

export default SelectCollectionView;
