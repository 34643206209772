import ApplicationState from 'app/ApplicationState';
import createjs from 'easeljs';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';
import LightsetModelUtil from 'utils/lightset-wizard/LightsetModelUtil';
import LightSetLabelHelper from 'controllers/LightSetLabelHelper';
import LightSetModelSerializer from 'controllers/LightSetModelSerializer';
import LightSetHeightRuler from 'controllers/LightSetHeightRuler';
import Container from 'controllers/LightSetImageContainer';

// Terminology:
//
// Element:     A single component of a lightset, e.g. pole, bracket, luminaire
// Model:       e.g. poleModel; the BackBone model containing data for the element. Each model contains an array of 'renderings'
// Rendering:   A single angle representation of an element. When the 'rotate' button in the object toolbar is pressed,
//              the LightSetController will cycle through renderings of each of its elements to adjust the displayed images
//              and their relative positions / attachment points. Each rendering has their own mounting point(s) and optional locator.
// Locator:     The x/y point where the next element will attach to (e.g. where the luminaire will attach to the bracket).
//              A luminaire has no locator since it's the end of the element 'tree'.
//              A luminaire optionally has two locators, one for regular attachment (lX/lY) and one for suspension (sX/sY).
// Mount:       The x/y point where the current element attaches to the previous element's locator
//
// Images (per rendering):
// src: The primary colorable parts of the element (always present)
// acc: The part of the element that remains uncolored (optional)
// elm: The secondary colorable parts of the element (optional)

export default
    class LightSetController
    extends createjs.EventDispatcher
{
    // Events
    static get UPDATED()    { return 'LIGHT_SET_CONTROLLER_DID_UPDATE'; }
    static get RENDERED()   { return 'LIGHT_SET_CONTROLLER_DID_RENDER'; }
    
    // Model keys
    static get kAngleIndex()    { return 'angleIndex'; }
    
    // Constants
    static get PIXELS_PER_METER() {
        // 1 real life meter of pole equals 438 pixels of image. 
        // This value influences height calculations and must be in line with current PSD's. 
        return 438; 
    }
    
    constructor() {
        super();
        
        this.graphicsScale = 1;
        
        this._pendingLoadOperations = 0;
        this._completedLoadOperations = 0;
        
        this._showBitmapBounds = false;

        // Loads and holds all elements (background, lightset elements, rear bracket elements), and will be scaled down to fit the application
        this._container = new Container();  

        // A scaleX=1/scaleY=1 container. Contains all the above. Suitable for the rendertool scene.
        this._output = new createjs.Container();
        this._output.mouseChildren = false;
        this._output.addChild( this._container );
        this._output.container = this._container;
    }
    
    get angleIndex() {
        const index = this.model.get(LightSetController.kAngleIndex);
        return index != null ? index : 0;
    }
    
    set angleIndex(index) {
        // The index can be out of bounds, no problem. We normalize this using modulo operators when needed.
        // It's stored on the model in order to be transferred when duplicating or saving lightsets.
        this.model.set(LightSetController.kAngleIndex, index);
    }
    
    get isRendered() {
        return this._pendingLoadOperations == 0 && this._completedLoadOperations > 0;
    }
    
    get resultWidth() {
        return this._resultWidth ? this._resultWidth : 600;
    }
    
    set resultWidth(width) {
        this._resultWidth = width;
    }
    
    get resultHeight() {
        return this._resultHeight ? this._resultHeight : 600;
    }
    
    set resultHeight(height) {
        this._resultHeight = height;
    }
    
    get model() {
        return this._model;
    }
    
    set model( model ) 
    {
        this._model = model;
        this._pointType = this._model.get('lightPointType').id;

        const isWallMounted = this._pointType === 'rear';
        if (isWallMounted) {
            if (!this.rearBracketHeight) {
                let range = this.rearBracketYRange;
                let center = Math.floor((range[0] + range[1]) / 2 / 500) * 500; // replace 500 with const step
                this.rearBracketHeight = center;
            } else {
                this._updateRearBracketPosition();
            }
        }

        LightsetModelUtil.stripUnusableAngles(model);
        
        model.on('change:luminaireColor', this._onChangeLuminaireColor, this);
        model.on('change:bracketColor', this._onChangeBracketColor, this);
        model.on('change:poleColor', this._onChangePoleColor, this);
        model.on('change:background', this._redrawBackground, this);
    }
    
    get serializedModel() {
        return LightSetModelSerializer.serialize( this._model );
    }
    
    set serializedModel( obj ) {
        this.model = LightSetModelSerializer.deserialize( obj );
    }
    
    get pointType() {
        return this._pointType;
    }

    get output() {
        return this._output;
    }

    set rearBracketHeight(height) {
        // We can't listen for model changes here since the model only dispatches an event if the value actually changes.
        // The edge case would then be: Duplicate (or load) a light set. The model gets deserialized and is then passed to
        // this LightSetController instance. The change event would never fire. Sad panda.
        this.model.set('rearBracketHeight', height);
        this._updateRearBracketPosition();
    }

    get rearBracketHeight() {
        return this.model.get('rearBracketHeight');
    }

    get rearBracketYRange() {
        if (this.pointType == 'rear') {
            const model = this.model.get('poleModel');
            const minHeight = 3500;
            const maxHeight = Math.max(minHeight, model.height - 1000);

            return [minHeight, maxHeight];
        } else {
            return [5000, 5000];
        }
    }
    
    get scale() {
        // Simulate the height of the entire lightset, so we know in advance what scale to use
        // in order to fit the entire lightset. Can't be done by just rendering the entire set and
        // measuring its bounding box, as you could see jumps in frame due to progressive loading.
        let referenceSize = 0;
        if (this.pointType === 'wall') {
            // wall brackets are fixed at 5m height
            referenceSize = 5 * LightSetController.PIXELS_PER_METER;
        }

        const poleRendering = this._getRendering('pole', 0);
        if (poleRendering != null) {
            referenceSize += poleRendering.mY;
        }

        // If there's a bracket involved, increase the referenceSize with the added size of
        // the bracket
        const bracketRendering = this._getRendering('bracket', 0);
        if (bracketRendering != null) {
            referenceSize += bracketRendering.mY;
        }

        // If the luminaire is top mounted (e.g. not suspended), add the height from
        // top to mounting point of the luminaire
        if (!this.model.get('luminaireModel').suspended) {
            referenceSize += this._getRendering('luminaire', 0).mY;
        }

        // Minimum of 3 meters total rendering size, to properly display very small sets
        // such as bollards
        referenceSize = Math.max(referenceSize, 3 * LightSetController.PIXELS_PER_METER);

        // .95 just a little nudge to prevent cutoff of height ruler / label
        return (this.resultHeight * .95) / referenceSize;
    }
    
    render (heightRuler=true) {
        const manifest = ['luminaire'];

        this.model.get('bracket') !== undefined && manifest.unshift('bracket');
        this.model.get('pole') !== undefined && manifest.unshift('pole');
        
        this._showHeightRuler = (this.pointType == 'wall' || this.pointType == 'luminaire-only') ? false : heightRuler;
        this._container.scaleX = this._container.scaleY = this.scale;
        
        this._reset();
        this._redrawBackground();
        
        // The locator is the starting point. We mount the first element here and then move the locator, IK.
        let referencePoint = this._createReferencePoint();
        this._referencePoint = referencePoint;
        let locator = referencePoint.clone();
        let rearLocator = null;

        let suspended = false; 
        let sideMountingBracket = false;
        let sideEntryLuminaire = false;
        let spiralLuminaire = false;
        
        manifest.forEach( function( elementName, index, manifest ) {
            const elementModel = this.model.get( elementName + 'Model' );
            const prevElementName = manifest[index - 1];
            const prevElementModel = index > 0 ? this.model.get( prevElementName  + 'Model' ) : null;
            
            const element = this._getRendering( elementName );
            const prevElement = this._getRendering( prevElementName );

            let primaryLayer = Container.MAIN;
            
            // Elements will be rendered in this exact order. However, not all configurations contain all elements.
            switch (elementName)
            {
                case 'pole': {
                    this._loadBitmaps( element, locator, [primaryLayer, Container.POLE] );
                    const poleSideMounting = elementModel.sideMountingCompatible && this.model.get('luminaireModel').poleSideMounting;
                    const locators = this._getLocatorsForPole(referencePoint.clone(), element, poleSideMounting);
                    locator = locators.shift();
                    rearLocator = locators.length ? locators.shift() : null;
                }
                break;
                
                case 'bracket': {
                    if (elementModel.suspended === true) {
                        suspended = true; // Bracket is suspended? Make sure the suspended locators are used for the luminaire.
                    }
                    if (elementModel.poleSideMounting === true) {
                        sideMountingBracket = true; // Bracket is pole side mounting? Make sure the layer order is fixed accordingly.
                    }

                    const poleLocators = this._getLocatorsForPole(referencePoint.clone(), prevElement, sideMountingBracket);
                    locator = poleLocators.shift();

                    this._loadBitmaps( element, locator, [primaryLayer, Container.BRACKET] );
                    this._traverseLocator( locator, element, false, 'm', 'l' );

                    if (elementModel.type === 'double') {
                        if (sideMountingBracket && poleLocators.length) {
                            rearLocator = poleLocators.shift();
                            const rearElement = this._getRendering(elementName, this.angleIndex, true);
                            this._loadBitmaps( rearElement, rearLocator, [primaryLayer, Container.BRACKET], 0, false, true );
                            this._traverseLocator( rearLocator, rearElement, true, 'm', 'l' );
                        } else {
                            rearLocator = locator.clone();
                            rearLocator.x -= (element.lX - element.rX);
                            rearLocator.y -= (element.lY - element.rY);
                        }
                    }
                }
                break;
                 
                case 'luminaire': {
                    const angle = prevElement && prevElement.angle ? prevElement.angle : 0;
                    const angleRear = prevElement && prevElement.angleRear ? -prevElement.angleRear : -angle;

                    let mirror = false;
                    sideEntryLuminaire = elementModel.sideEntry;

                    // Load the primary (right side or post-top) luminaire.
                    this._loadBitmaps( element, locator, [primaryLayer, Container.LUMINAIRE], angle, suspended, mirror );

                    // If the luminaires are symmetrical, we can just load the same luminaire for front and back.
                    // If the luminaires are asymmetrical (e.g. side-entry luminaires), we need to load dedicated images.
                    // We simply check for negative angle images to ascertain this. If they exist, we use them.
                    if (this._hasNegativeAngleRenderings(elementModel)) {
                        // The negative angle source images also face right so we need to mirror these, incl coordinates.
                        mirror = true;
                    }

                    // Some configurations include a single pole and multiple (3 or 4) luminaires in a spiral
                    // configuration represented by a single visual, which is the luminaire part. Because of the spiral,
                    // the luminaire part needs to be in front of the pole, contrary to post-top configurations.
                    if (this.pointType === 'spiral') {
                        spiralLuminaire = true;
                    }

                    if (rearLocator != null) {
                        // Load the secondary (left side) luminair
                        const rearElement = this._getRendering(elementName, this.angleIndex, true);
                        this._loadBitmaps( rearElement, rearLocator, [primaryLayer, Container.REAR_LUMINAIRE], angleRear, suspended, mirror );
                    }
                }
                break;
            }
        }.bind(this));

        this._container.arrangeLayers(suspended, sideEntryLuminaire, sideMountingBracket, spiralLuminaire);
        this._notifyListenersOfDisplayUpdate();
    }

    _getRendering(elementName, index=-1, isRearModel=false) {
        return LightsetModelUtil.getRendering(this.model, elementName, index >= 0 ? index : this.angleIndex, isRearModel);
    }

    renderDataURLOutput(callback) {
        // This is a hackspace... needs some refactoring. Only used by the PDF generation so this logic
        // does not really belong here.
        const showingHeightRuler = this._showHeightRuler;
        this._showHeightRuler = false;

        this.on(LightSetController.RENDERED, function(evt) {
            const canvas = document.createElement('canvas');
            canvas.width = this.resultWidth;
            canvas.height = this.resultHeight;

            const stage = new createjs.Stage(canvas);
            stage.addChild(this._container);

            stage.update();
            this._showHeightRuler = showingHeightRuler;
            callback(stage.toDataURL());
        }.bind(this));

        this.render();
    }

    _getLocatorsForPole(mountingPoint, poleElement, poleSideMounting=false) {
        if (!poleElement) {
            return [mountingPoint, null]; // In case of Wall mounted solutions, or anything that does not have a pole
        }

        const primaryLocatorPrefix = poleSideMounting ? 'ls' : 'l';
        const locators = [
            this._traverseLocator(mountingPoint.clone(), poleElement, false, 'm', primaryLocatorPrefix)
        ];

        const rearLocatorPrefix = poleSideMounting ? 'lsr' : 'r';
        if (this.pointType === 'double' && poleElement[rearLocatorPrefix + 'X'] !== undefined 
                                        && poleElement[rearLocatorPrefix + 'Y'] !== undefined) {
            locators.push(
                this._traverseLocator(mountingPoint.clone(), poleElement, false, 'm', rearLocatorPrefix)
            );
        }

        return locators;
    }

    _redrawBackground() {
        this._container.layers[Container.BACKGROUND].clear();
        this._notifyListenersOfDisplayUpdate();
        
        const backgroundId = this.model.get('background');
        if (backgroundId) {
            // let locator = new createjs.Point(0, 0);
            const background = LightsetWizardDataUtil.getBackground(backgroundId);
            if (background != null) {
                const locator = this._createGroundReferencePoint();
                for (let element of background.elements) {
                    this._loadBitmaps( element, locator.clone(), [Container.BACKGROUND] );
                }
                const scale = this.scale;
                const mask = this._loadBitmaps( {'src': 'img/pdf/backgrounds/mask.png'}, new createjs.Point(0, 0), [Container.BACKGROUND] ).shift();
                mask.scaleY = 1 / scale;
                mask.scaleX = mask.scaleY * 20;
            }
        }
    }
    
    copy() {
        // Rather than using $.extend( true, {}, model ) to perform a deep copy, we gracefully create a
        // clean copy of the data by serializing, stringifying, parsing and then deserializing the data.
        const controller = new LightSetController();
        controller.serializedModel = this.serializedModel;
        controller.resultWidth = this.resultWidth;
        controller.resultHeight = this.resultHeight;
        return controller;
    }
    
    cleanForRendertool() {
        // First remove the height indicators.
        this._removeHeightRuler();
        
        // Moves the container and resizes its hitarea in such way that there is minimal whitespace around the lightset objects.
        // This prevents e.g. the Transform widget's frame from having a lot of whitespace around the object.
        
        // We intentionally use minimal instead of no whitespace, as we expand the bounds a little to account for rotation 
        // (cycling through renderings). We can't use a skin-tight fit for every single rendering, since the Erase tool really needs
        // fixed bounds in order to prevent some very complicated code. Since the bounds change when cycling through renderings,
        // we store the initial angle zero bounds in the model. This has the added benefit of transporting this information when
        // saving or duplicating the light set.
        
        let bounds = this.model.get('trimmedBounds');
        if (bounds == null && (this.angleIndex === 0 || this.angleIndex > 0)) {
            bounds = this._container.getTransformedBounds();
            if (bounds != null) {
                const margin = 60;
                bounds.width += margin * 2;
                bounds.height += margin * 2;
                bounds.x -= margin;
                bounds.y -= margin;
            }
        }

        if (bounds) {
            this.model.set('trimmedBounds', bounds);
            
            this._container.x = -bounds.x;
            this._container.y = -bounds.y;

            // Create a hitarea for use in the composer
            const hit = new createjs.Shape();

            hit.graphics.beginFill('red').drawRect(0, 0, bounds.width, bounds.height);

            this._output.hitArea = hit;
            this._output.setBounds(0, 0, bounds.width, bounds.height);
        }

        // May be null, if erronously called before setting a model and calling render().
        return bounds;
    }
    
    _onChangeLuminaireColor( model, colorModel, notifyObservers = true ) {
        this._updateBitmapColor( Container.LUMINAIRE, colorModel, notifyObservers );
    }
    
    _onChangeBracketColor( model, colorModel, notifyObservers = true ) {
        this._updateBitmapColor( Container.BRACKET, colorModel, notifyObservers );
    }
    
    _onChangePoleColor( model, colorModel, notifyObservers = true ) {
        this._updateBitmapColor( Container.POLE, colorModel, notifyObservers );
    }

    _updateBitmapColor( type, colorModel, notifyObservers = true ) {
        const hasColored = this._container.colorBitmapsOfType(type, colorModel);
        hasColored && notifyObservers && this._notifyListenersOfDisplayUpdate();
    }
    
    _hasNegativeAngleRenderings(model) {
        return model.anglesNegative && model.anglesNegative.length > 0;
    }
    
    _updateRearBracketPosition() {
        const height = this.model.get('rearBracketHeight');
        const range = this.rearBracketYRange;

        const poleRendering = this._getRendering( 'pole' );
        const poleModel = this.model.get('poleModel');
        const heightInPixels = height * (poleRendering.mY / poleModel.height);
        const locator = this._createGroundReferencePoint();
        
        locator.y -= heightInPixels;
        this._container.rearLayer.x = locator.x;
        this._container.rearLayer.y = locator.y;
        this._notifyListenersOfDisplayUpdate();
    }

    _getFixingPoint(rendering, pointPrefix='m', suspended=false) {
        // Luminaire fixing point is used only in the wizard preview in composer
        if (this.pointType === 'luminaire-only') {
            return new createjs.Point(0, 0);
        }

        // Only luminaires can have sX (suspended) and pX (pole mounting) coordinates, 
        // which is why this method can be used for any type of model
        if (suspended && rendering.sX != null) {
            pointPrefix = 's';
        } else if (this.pointType === 'top' && rendering.pX != null) {
            pointPrefix = 'p';
        }

        const x = rendering[pointPrefix + 'X'] || 0;
        const y = rendering[pointPrefix + 'Y'] || 0;

        return new createjs.Point(x, y);
    }

    _traverseLocator(locator, rendering, mirrored=false, originPointPrefix='m', destinationPointPrefix='l') {
        const origin = this._getFixingPoint(rendering, originPointPrefix);
        const destination = this._getFixingPoint(rendering, destinationPointPrefix);

        locator.x += mirrored ? -(destination.x - origin.x) : destination.x - origin.x;
        locator.y += destination.y - origin.y;
        return locator; // convenience; the locator provided as argument is traversed anyhow
    }
    
    _loadBitmaps(rendering, locator, layerPath=[], angle = 0, suspended = false, mirrored = false) { 
        if (angle !== 0){ 
            console.log('rendering', rendering, angle, mirrored);
        }
        const origin = this._getFixingPoint(rendering, 'm', suspended);

        // Small exception for background image element coordinates
        if (rendering.oX != null) { // if there's a manual origin given (expressed as a % of total canvas dimension), correct the locator x/y
            locator.x = rendering.oX * (this.resultWidth / this.scale);
        }

        // Fetch all layers of the rendering. Src = main (primary color), acc = uncolored parts, elm = secondary color parts.
        const bitmaps = [];
        for (let layer of ['src', 'acc', 'elm']) {
            const path = rendering[layer];
            if (path !== undefined) {
                const bmp = this._loadBitmap(path, locator.x, locator.y, origin.x, origin.y, angle, mirrored);
                bitmaps.push(bmp);
                const sublayerPath = layerPath.slice();
                if (layer === 'src') {
                    sublayerPath.push(Container.COLOR1);
                } else if (layer === 'acc') {
                    sublayerPath.push(Container.NOCOLOR);
                } else {
                    sublayerPath.push(Container.COLOR2);
                }
                this._container.addBitmap(bmp, sublayerPath);
            }
        }
        
        return bitmaps;
    }
    
    _loadBitmap(url, x, y, regX, regY, angle=0, mirrored=false) {
        const img = new Image();
        const bmp = new createjs.Bitmap(img);
        
        bmp.x = x;
        bmp.y = y;
        bmp.regX = regX;
        bmp.regY = regY;
        bmp.rotation = -angle;
        bmp.scaleX = mirrored ? -1 : 1;
        
        this._pendingLoadOperations++;
        
        img.onload = function() {
            bmp.cache(0, 0, img.width, img.height);
            
            if (this._showBitmapBounds) {
                const s = bmp.parent.addChild(new createjs.Shape());
                
                s.graphics.beginFill('rgba(0, 0, 0, .2)').drawRect(0, 0, img.width, img.height);
                
                for (let prop of ['x', 'y', 'regX', 'regY', 'rotation', 'scaleX']) {
                    s[prop] = bmp[prop];
                }
            }
            
            this._pendingLoadOperations -= 1;
            this._completedLoadOperations += 1;
            if (this.isRendered) {
                this._updateColors();
                this._updateHeightRuler();

                if (ApplicationState.get('target') === 'creator') {
                    this._centerLightSet();
                }

                this._notifyListenersOfDisplayUpdate();
                this.dispatchEvent( LightSetController.RENDERED );

            } else {
                this._notifyListenersOfDisplayUpdate();
            }
        }.bind(this);
        
        img.src = url;
        
        return bmp;
    }

    _centerLightSet() {
        // After rendering the lightset that are possibly assymetric should be recentered. We can only do that after
        // the lightset has rendered because getBounds will not return results until all images have loaded.
        if(this.pointType == 'top' || this.pointType == 'single') {

            // Center the part of the container holding the lightset only (ie not the background)
            const lightSetContainer = this._container.getChildAt(1);
            const lightSetBounds = lightSetContainer.getBounds();
            const originalWidth = this._referencePoint.x * 2;

            // We calculate the differecen between the current leftmost point of the lightset (the x) and the
            // x the lightset should have to be centered which is the width of the canvas - the lightset width/ 2
            const moveX = Math.ceil(lightSetBounds.x) - (originalWidth - lightSetBounds.width) / 2;

            if(moveX > 1) {
                lightSetContainer.x = -moveX;
                this._notifyListenersOfDisplayUpdate();
            }
        }
    }

    _updateColors() {
        if( this.isRendered ) {
            // Pass false to the handlers to prevent display updates on every single color change. We dispatch a single event afterwards.
            const luminaireColorModel = this.model.get('luminaireColor');
            luminaireColorModel && this._onChangeLuminaireColor( this.model, luminaireColorModel, false );
            
            const bracketColorModel = this.model.get('bracketColor');
            bracketColorModel && this._onChangeBracketColor( this.model, bracketColorModel, false );
            
            const poleColorModel = this.model.get('poleColor');
            poleColorModel && this._onChangePoleColor( this.model, poleColorModel, false );
            
            this._notifyListenersOfDisplayUpdate();
        } else {
            throw "Forcing a color update during rendering is bad design due to the asynchronous nature of the render cycle. Wait for isRendered == true.";
        }
    }
    
    _notifyListenersOfDisplayUpdate() {
        this.dispatchEvent(LightSetController.UPDATED);
    }
    
    _createReferencePoint() {

        if (this.pointType == 'wall' ) {
            return this._createWallReferencePoint();
        } else if (this.pointType == 'luminaire-only') {
            return this._createCenterReferencePoint();
        } else {
            return this._createGroundReferencePoint();
        }

    }

    _createCenterReferencePoint() {
        const scale = this.scale;

        return new createjs.Point(
            (this.resultWidth / 2) / scale,
            (this.resultHeight / 3)  / scale
        );
    }

    _createGroundReferencePoint() {
        const scale = this.scale;

        return new createjs.Point(
            (this.resultWidth / 2) / scale,
            (this.resultHeight / scale)
        );
    }
    
    _createWallReferencePoint() {
        const x = (0.2 * this.resultWidth / this.scale) + 279;
        const y = (this.resultHeight / this.scale) - LightSetController.PIXELS_PER_METER * 5.1;
        return new createjs.Point(x, y); // This places the luminaire at a height of 5 meters. Tweak the X to align with a background image.
    }

    _updateHeightRuler() {
        this._removeHeightRuler();
        if (this._showHeightRuler) {
            this._heightRuler = new LightSetHeightRuler(LightSetController.PIXELS_PER_METER * this.scale, 14, this.graphicsScale);
            this._heightRuler.y = this.resultHeight;
            this.output.addChild(this._heightRuler);
        }
    }

    _removeHeightRuler() {
        if (this._heightRuler && this._heightRuler.parent) {
            this._heightRuler.parent.removeChild(this._heightRuler);
        }
    }

    _reset() {
        this._container.clear();
        this._pendingLoadOperations = 0;
        this._completedLoadOperations = 0;
    }
}
