import Marionette from 'backbone.marionette';

import cookieUtil from 'utils/cookies';

import template from 'templates/ui/confirm/confirm';

export default
    class ConfirmView
    extends Marionette.ItemView {

    static get COOKIE_PREFIX() {
        return 'prevent_confirm_dialog_';
    }

    static get ORIENTATION_TOP_LEFT() {
        return 'top-left';
    }

    static get ORIENTATION_TOP_RIGHT() {
        return 'top-right';
    }

    static get ORIENTATION_BOTTOM_LEFT() {
        return 'bottom-left';
    }

    static get ORIENTATION_BOTTOM_RIGHT() {
        return 'bottom-right';
    }

    get className() {
        return 'popover';
    }

    get template() {
        return template;
    }

    onBeforeRender() {
        if (this.getOption('orientation')) {
            this.$el.addClass('popover--' + this.getOption('orientation'));
        }

        if (this.getOption('x')) {
            this.$el.css('left', this.getOption('x'));
        }

        if (this.getOption('y')) {
            this.$el.css('top', this.getOption('y'));
        }
    }

    onRender() {
        this.$('.popover__confirm').on('click', this.onConfirm.bind(this));
        this.$('.popover__cancel').on('click', this.onCancel.bind(this));
    }

    onConfirm() {
        if (this.$('input[type=checkbox]').is(':checked')) {
            cookieUtil.set(ConfirmView.COOKIE_PREFIX + this.getOption('commandId'), '1');
        }

        this.confirm();
    }

    needsConfirmation() {
        return !cookieUtil.has(ConfirmView.COOKIE_PREFIX + this.getOption('commandId'));
    }

    onCancel() {
        const callback = this.getOption('onCancel');
        if (callback !== null) {
            callback.call();
        }
        this.destroy();
    }

    confirm() {
        const callback = this.getOption('onConfirm');
        if (callback !== null) {
            callback.call();
        }
        this.destroy();
    }

}
