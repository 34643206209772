import AbstractBackgroundBitmapTool from 'controllers/composer/tools/scene/AbstractBackgroundBitmapTool';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';

export default 
    class SceneToolBackgroundClone
    extends AbstractBackgroundBitmapTool
{
    constructor( id ) {
        super( id );
        
        this._radius = 25;
        this._softness = 50;
        this._sourceMode = true;
        this._sourcePoint = null;
        this._deltas = null;
    }
    
    get hasMouseCursor() { 
        return true;
    }
    
    get mouseCursor() {
        if( !this._mouseCursor ) {
            this._mouseCursor = new createjs.Shape();
            this._updateMouseCursor();
        }
        return this._mouseCursor;
    }

    inSourceMode() {
        return this._sourceMode;
    }

    hasSourcePoint() {
        return !!this._sourcePoint;
    }
    
    _updateMouseCursor() 
    {
        let graphics = this.mouseCursor.graphics;
        let radius = this._radius + this._softness / 2;
        let blurBounds = { x: 0, y: 0, width: 0, height: 0 };
        graphics.clear();
        
        if( this._sourceMode == true ) {
            graphics.beginFill( 'rgba( 255, 0, 0, .3 )' ).drawCircle( 0, 0, radius );
            let filter = new createjs.BlurFilter( this._softness, this._softness, 1 );
            blurBounds = filter.getBounds();
            this.mouseCursor.filters = [filter];
        } else {
            graphics.beginStroke( 'rgba( 255, 255, 255, .6 )' ).setStrokeStyle( 1 ).drawCircle( 0, 0, radius );
            graphics.beginStroke( 'rgba( 0, 0, 0, .4 )' ).setStrokeStyle( 1 ).drawCircle( 0, 0, radius - 1 );
            this.mouseCursor.filters = [];
        }
        
        radius += 2;
        this.mouseCursor.cache( -radius, -radius, radius * 2, radius * 2 );
        this.context.compositionView.stage.update(); // update immediately or else we'll only see it after mouse move
    }
    
    activate() {
        super.activate();
        this.setSourceMode( true );
    }
    
    setSourceMode( mode ) {
        this._sourceMode = mode;
        this._updateMouseCursor();
    }
        
    _handleSliderValueChange( value, type ) {
        switch( type ) {
            case SceneToolbarItemDataCollection.CLONE_SIZE:
                // range: 10 to 100
                this._radius = value;
                break;
            
            case SceneToolbarItemDataCollection.CLONE_SOFTNESS:
                // range: 0 to 50
                this._softness = 50 - (value * .5);
                break;

            case SceneToolbarItemDataCollection.CLONE_SELECT_SOURCE_POINT:
                this.setSourceMode( true );
                break;

        }
        this._updateMouseCursor();
    }
        
    _handleStageMouseEvent( evt ) 
    {
        let bmp = this.context.compositionView.backgroundObject.bitmap;
        let localPoint = bmp.globalToLocal( evt.rawX, evt.rawY );
        
        if( !this._sourceMode ) {
            super._handleStageMouseEvent( evt );
        } 
        else if( evt.type == 'click' ) {
            this._sourcePoint = new createjs.Point( localPoint.x, localPoint.y );
            this._deltas = null;
            this.setSourceMode( false );
        }
    }
    
    _editBitmapAt( bmp, x, y ) {
        this._cloneBitmapAreaWithParameters( bmp, x, y, this._radius, this._softness );
    }
    
    _cloneBitmapAreaWithParameters( bmp, x, y, radius, softness ) 
    {   
        if( !this._deltas ) {
            this._deltas = new createjs.Point( x - this._sourcePoint.x, y - this._sourcePoint.y );
        }
        
        radius /= bmp.scaleX; // Compensate the brush radius using the bitmap scale.
        
        let diameter = radius * 2;
        
        // Draw the circular blurred brush shape which will be used to mask the cloned rectangle
        let brushBlurBounds = { x: 0, y: 0, width: 0, height: 0 };
        let brush = new createjs.Shape();
        brush.graphics.beginFill( 'red' ).drawCircle( 0, 0, radius );
        if( softness > 0 ) {
            let brushBlur = new createjs.BlurFilter( softness, softness, 1 );
            brushBlurBounds = brushBlur.getBounds();
            brush.filters = [brushBlur];
        }
        brush.x = radius - brushBlurBounds.x;
        brush.y = radius - brushBlurBounds.y;
        brush.cache( -radius, -radius, diameter + brushBlurBounds.width, diameter + brushBlurBounds.height );
        
        // Create a rectangular slice of the area that needs to be cloned
        let cloneCanvas = document.createElement( 'canvas' );
        cloneCanvas.width = diameter + brushBlurBounds.width;
        cloneCanvas.height = diameter + brushBlurBounds.height;
        let ctx = cloneCanvas.getContext('2d');
        ctx.globalAlpha = .3;
        ctx.drawImage( bmp.image, -(x - this._deltas.x) + brush.x, -(y - this._deltas.y) + brush.y );
        
        // Put the clone slice in a bitmap and apply the filters
        let cloneBmp = new createjs.Bitmap( cloneCanvas );
        cloneBmp.filters = [new createjs.AlphaMaskFilter( brush.cacheCanvas )];
        cloneBmp.cache( 0, 0, diameter + brushBlurBounds.width, diameter + brushBlurBounds.height );
        
        // Create a new canvas for the source bitmap and put it all together
        let output = document.createElement( 'canvas' );
        output.width = bmp.image.width;
        output.height = bmp.image.height;
        ctx = output.getContext('2d');
        ctx.drawImage( bmp.image, 0, 0 );
        ctx.drawImage( cloneBmp.cacheCanvas, x - brush.x, y - brush.y );
        
        // Remove the old image or canvas from the bitmap and assign our temp canvas
        try {
            bmp.image.remove();
        } catch( err ) {
            // Do nothing, no compatibility in this browser. It's a minor optimisation anyway as the parentless canvas should be GC'ed. 
        }
        bmp.image = output;
    }
}
