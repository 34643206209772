import $ from 'jquery';
import createjs from 'easeljs';

import AbstractBackgroundBitmapTool from 'controllers/composer/tools/scene/AbstractBackgroundBitmapTool';
import SceneToolbarItemDataCollection from 'models/composer/toolbars/SceneToolbarItemDataCollection';

export default 
    class SceneToolBackgroundBlur
    extends AbstractBackgroundBitmapTool
{
    constructor( id ) {
        super( id );
        
        this._radius = 45;
        this._strength = 55;
        this._softnessMultiplier = 1;
        this._blur = 20;
        this._quality = 1;
    }
    
    get hasMouseCursor() { 
        return true;
    }
    
    get mouseCursor() {
        if( !this._mouseCursor ) {
            this._mouseCursor = new createjs.Shape();
            this._updateMouseCursorRadius();
        }
        return this._mouseCursor;
    }
    
    get _calculatedSoftness() {
        return this._softnessMultiplier * this._radius;
    }
    
    _updateMouseCursorRadius() {
        let r = this._radius + this._calculatedSoftness / 2;
        this.mouseCursor.graphics.clear()
        this.mouseCursor.graphics.beginStroke( 'rgba( 255, 255, 255, .6 )' ).setStrokeStyle( 1 ).drawCircle( 0, 0, r );
        this.mouseCursor.graphics.beginStroke( 'rgba( 0, 0, 0, .4 )' ).setStrokeStyle( 1 ).drawCircle( 0, 0, r - 1 );
        
        r += 2;
        this.mouseCursor.cache( -r, -r, r * 2, r * 2 );
    }
    
    _handleSliderValueChange( value, type ) {
        switch( type ) {
            case SceneToolbarItemDataCollection.BLUR_SIZE:
                // range: 10 to 80, as given
                this._radius = value;
                break;
            
            case SceneToolbarItemDataCollection.BLUR_STRENGTH:
                // range: 0.1 to 1 (10 to 100 is given)
                this._strength = value / 100;
                break;
        }
            
        this._updateMouseCursorRadius();
    }
    
    _editBitmapAt( bmp, x, y ) {
        this._blurBitmapAreaWithParameters( bmp, x, y, this._radius, this._calculatedSoftness, this._blur, this._quality );
    }
    
    _blurBitmapAreaWithParameters( bmp, x, y, radius, softness, blur, quality ) 
    {
        radius /= bmp.scaleX; // Compensate the brush radius using the bitmap scale.
        
        let diameter = radius * 2;
        
        // This isn't the actual blur. The brush is a circular shape with a certain edge hardness.
        // It functions as a mask for the actual blurred area.
        // Why don't we just copy a circular slice and blur that? Because the blurred edges cause an ever so slight burn effect,
        // (a slightly bright halo effect) with pretty much any blur filter I've tried (including easeljs' blur and the nifty FastBlur.) 
        // The result: Blurring the same area over and over again will make it brighter and more saturated until you have a completely 
        // burned up image.
        // You may find this on the internet: http://jsfiddle.net/m1erickson/baDLp/
        // And think... I don't see the artifact there. It's there. Just use a brighter image to test. Also, that version doesn't 
        // do as many overlapping passes, so the effect is less pronounced. But you will see halo's even there.
        // The solution: Make a blurred circle and use it as a mask for a completely blurred image.
        let brushBlur = new createjs.BlurFilter( softness, softness, 1 );
        let brushBlurBounds = brushBlur.getBounds();
        
        // Create the circular 'brush' shape that will act as mask. Apply the blur and cache it.
        var brushContent = new createjs.Shape();
        brushContent.graphics.beginFill( 'red' ).drawCircle( 0, 0, radius );
        brushContent.alpha = this._strength;

        var brush = new createjs.Container();
        brush.addChild( brushContent );
        brush.x = radius - brushBlurBounds.x;
        brush.y = radius - brushBlurBounds.y;
        brush.filters = [brushBlur];
        brush.cache( -radius, -radius, diameter + brushBlurBounds.width, diameter + brushBlurBounds.height );
        
        // Next, we need a blurred version of the source bitmap, onto which we'd then apply the brush mask.
        // When pasting the result on top of the source bitmap, it would then have a blurred circular area.
        // Instead of blurring an entire copy of the source bitmap, we slice the required part and blur that instead.
        // MUCH less expensive this way, especially when using smaller brushes (relatively speaking, since the source bitmap
        // might have been scaled down a lot, which is the same as increasing the brush size).
        let sliceCanvas = document.createElement( 'canvas' );
        sliceCanvas.width = diameter + brushBlurBounds.width;
        sliceCanvas.height = diameter + brushBlurBounds.height;
        sliceCanvas.getContext('2d').drawImage( bmp.image, -x + brush.x, -y + brush.y );
        
        // Put the slice in a bitmap and apply the filters
        let sliceBitmap = new createjs.Bitmap( sliceCanvas );
        sliceBitmap.filters = [new createjs.BlurFilter( blur, blur, quality ), new createjs.AlphaMaskFilter( brush.cacheCanvas )];
        sliceBitmap.cache( 0, 0, diameter + brushBlurBounds.width, diameter + brushBlurBounds.height );
        
        // Create a new canvas for the source bitmap and put it all together
        let output = document.createElement( 'canvas' );
        output.width = bmp.image.width;
        output.height = bmp.image.height;
        let ctx = output.getContext('2d');
        ctx.drawImage( bmp.image, 0, 0 ); 
        ctx.drawImage( sliceBitmap.cacheCanvas, x - brush.x + sliceBitmap._filterOffsetX, y - brush.y + sliceBitmap._filterOffsetY );
        
        // Remove the old image or canvas from the bitmap and assign our temp canvas
        try {
            bmp.image.remove();
        } catch( err ) {
            // Do nothing, no compatibility in this browser. It's a minor optimisation anyway as the parentless canvas should be GC'ed. 
        }
        
        bmp.image = output;
    }
}