// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return " lightset-wizard__slides-wrapper--carousel-actived";
},"3":function(container,depth0,helpers,partials,data) {
    return "<button class=\"lightset-wizard__prev-slide\" disabled><span class=\"icon-arrow-left\"></span></button>\n<button class=\"lightset-wizard__next-slide\"><span class=\"icon-arrow-right\"></span></button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"lightset-wizard__slides-viewport\">\n    <div class=\"lightset-wizard__slides-wrapper"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.carouselEnabled : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></div>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.carouselEnabled : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
