import Backbone from 'backbone';

var LightsetWizardModel = Backbone.Model.extend({
    defaults: {
        step: null,
        steps: 5,
        nextEnabled: null,
        nextBtnLabel: null
    }
});

export default LightsetWizardModel;
