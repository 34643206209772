import $ from 'jquery';
import Analytics from 'utils/Analytics';

import Marionette from 'backbone.marionette';
import Hammer from 'hammerjs';

import template from 'templates/components/slider';

export default
    class SliderView 
    extends Marionette.ItemView 
{
    static get ORIENTATION_HORIZONTAL() {
        return 'horizontal';
    }
    
    get className() {
        return 'slider--container';
    }

    get ui() {
        if (!this.__ui) {
            this.__ui = {
                container: '.slider',
                slider: '.slider__slider',
                zero: '.slider__zero',
                handle: '.slider__handle',
                fill: '.slider__fill',
            }
        }
        return this.__ui;
    }
    
    set ui( ui ) {
        this.__ui = ui;
    }

    onBeforeShow() {
        this.min = this.model.get('min');
        this.max = this.model.get('max');

        this._isHorizontal = this.model.get('orientation') === SliderView.ORIENTATION_HORIZONTAL;
        this.$el.on('touchmove', this._handleTouchMove.bind(this));
    }

    onShow() {
        var hammer = new Hammer.Manager(this.ui.handle[0], {
            recognizers: [[Hammer.Pan, {
                direction: this._isHorizontal ? Hammer.DIRECTION_HORIZONTAL : Hammer.DIRECTION_VERTICAL,
                threshold: 1
            }]]
        });

        hammer.on('panstart', this._onPanStart.bind(this));
        hammer.on('panend', this._onPanEnd.bind(this));
        hammer.on('pan', this._onPan.bind(this));
        
        if (this.min < 0) {
            const range = this.max - this.min;
            const zeroValue = this.model.get('value') - this.min;
            this.zeroPositionPercentage = 100 * (zeroValue / range);
        } else {
            this.zeroPositionPercentage = 0;
            this.ui.zero.hide();
        }
        
        this.ui.zero.css(this._isHorizontal ? 'left' : 'bottom', this.zeroPositionPercentage + '%');
        
        this.setValue(this.model.get('value'));
    }

    _onPanStart(event) {
        this._length = this._isHorizontal ? this.ui.slider.outerWidth() : this.ui.slider.outerHeight();
        this._previousPercentage = this.percentage;
    }

    _onPan(event) {
        const deltaPercentage = (this._isHorizontal ? event.deltaX : -event.deltaY) / this._length * 100;
        const newPercentage = Math.min(Math.max(this._previousPercentage + deltaPercentage, 0), 100);

        this.setPercentage(newPercentage);
        this.model.set('value', this.min + ((this.max - this.min) * newPercentage / 100));
    }

    _onPanEnd(evt) {
        // Perform analytics on releasing the slider, so we send the final value only.
        Analytics.track(this.model.get('analyticsTag') + ':' + Math.ceil(this.model.get('value')));
    }

    _handleTouchMove(evt) {
        evt.preventDefault();
    }

    setPercentage(percentage) {
        this.ui.handle.css(this._isHorizontal ? 'left' : 'bottom', percentage + '%');
        this.ui.fill.css(this._isHorizontal ? 'left' : 'bottom', Math.min(percentage, this.zeroPositionPercentage) + '%');
        this.ui.fill.css(this._isHorizontal ? 'width' : 'height', Math.abs(percentage - this.zeroPositionPercentage) + '%');
        this.percentage = percentage;
    }

    setValue(value) {
        this.setPercentage((value - this.min) / (this.max - this.min) * 100);
    }

    get template() {
        return template;
    }
}
