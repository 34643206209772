// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"well well-info\">\n    <div class=\"well__wrapper\">\n        <button class=\"well__toggle\">\n            <i class=\"icon icon-arrow-down\"></i>\n            <span class=\"well-close-label well__label--hide\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Hide help text",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            <span class=\"well-close-label well__label--show\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show help text",{"name":"trans","hash":{},"data":data}))
    + "</span>\n        </button>\n        <div class=\"well__contents\">\n            <strong>"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</strong>\n            <p>"
    + alias3(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"message","hash":{},"data":data}) : helper)))
    + "</p>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
