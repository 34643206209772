import Backbone from 'backbone';

export default
    class SliderData
    extends Backbone.Model {

    get defaults() {
        return {
            value: 0,
            coarseValue: 0,
            orientation: 'vertical'
        };
    }
}
