import _ from 'underscore';
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import SelectLayoutView from 'views/ui/select/SelectLayoutView';
import Analytics from 'utils/Analytics';
import LanguageUtil from 'utils/Language';

import template from 'templates/intro/intro';


export default 
    class AbstractIntroLayoutView
    extends AbstractLayoutView
{
    get template() {
        return template;
    }

    _defineRegions() {
        return {
           'language-selector': '#language-region',
        }
    }

    onBeforeShow() {
        this.renderLanguageSelector();
    }

    onRender() {
    	this.$('#start-tool').on('click', this.onStartClick.bind(this));
    }

    renderLanguageSelector() {
        const languageOptions = this.model.get('applicationModel').languages;
        const currentLanguage = _.find(languageOptions, { id: LanguageUtil.getCurrentLanguage() });
        if (currentLanguage) {
            currentLanguage.selected = true;
        }

        const languageModel = new Backbone.Model({
            current: new Backbone.Model(currentLanguage),
            options: new Backbone.Collection(languageOptions),
        });

        const languageView = new SelectLayoutView({
            model: languageModel,
        });

        this.showChildView('language-selector', languageView);
        this.listenTo(languageModel, 'change:current', this._onChangeCurrentLanguage);
    }

    _onChangeCurrentLanguage(model) {
        const previousState = model.previousAttributes().current.attributes;

        if (!_.isEqual(previousState, model.get('current').attributes)) {
            const language = model.get('current').id;
            LanguageUtil.reloadWithLanguage(language);
        }
    }
}
