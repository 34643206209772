// import createjs from 'easeljs';
import 'easeljs';

import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';
import AbstractObjectWidget from 'views/composer/composition/objects/widgets/AbstractObjectWidget';
import Memento from 'models/composer/history/Memento';

export default
    class CanvasObject
    extends createjs.Container
{
    constructor( subject, widgetClasses = null )
    {
        super();
        
        this._subject = subject;
        this._defineView( widgetClasses == null ? this.defaultWidgetClasses : widgetClasses );
        
        this.selectable = false; // Default state. Only the Select tool can toggle this.
        this.allowWidgetInteraction = true; // Default state. Allow widgets to influence this object.
    }
    
    // The subject is the actual object we are manipulating, e.g. a lightset image or collection of images. 
    // CanvasObject is but a container for it and for the masking layer (from which we can 'erase' part of the image).
    get subject() {
        return this._subject;
    }
    
    get selectable() {
        return this._selectable;
    }
    
    set selectable( selectable ) {
        if( selectable != this._selectable ) {
            this._selectable = this.mouseChildren = this.mouseEnabled = selectable;
            if( !selectable && this.selected ) {
                this.selected = false;
            }
        }
    }
    
    get selected() {
        return this._selected;
    }
    
    set selected( selected ) {
        if( !this.selectable && selected ) {
            return;
        }
        
        if( selected != this._selected ) {
            this._selected = selected;
            this.widgetsVisible = this._selected;
            this.dispatchEvent( this._selected == true ? CanvasObjectEvent.WAS_SELECTED : CanvasObjectEvent.WAS_DESELECTED );
        }
    }
    
    get allowWidgetInteraction() {
        return this._allowWidgetInteraction;
    }
    
    set allowWidgetInteraction( allow ) {
        this._allowWidgetInteraction = allow;
        for( let widget of this._widgets ) {
            widget.updateView(); // Widgets read this property to know wether or not to show their control graphics.
        }
    }
    
    get defaultWidgetClasses() {
        return [];
    }
    
    get isReorderable() {
        return true;
    }
    
    get widgetsVisible() {
        return this._widgetsVisible;
    }
    
    set widgetsVisible( val ) {
        if( val != this._widgetsVisible ) {
            for( let widget of this._widgets ) {
                widget.visible = val;
            }
            this._widgetsVisible = val;
        }
    }
    
    recordState( stringify=false, callback=null ) {
        return new Memento( this.mementoPropertyMap ).record( this, stringify, callback );
    }
    
    onStateRestored() {
        this.update();
    }
    
    update() {
        // Ask all widgets to update their views. Then inform listeners that we're edited,
        // e.g. to let CompositionView update the stage.
        for( let widget of this._widgets ) {
            widget.updateView();
        }
        this.dispatchEvent( CanvasObjectEvent.HAS_UPDATED );
    }
    
    get mementoPropertyMap() {
        return ['x', 'y', 'rotation', { 
            subject: ['scaleX', 'scaleY']
        }];
    }
    
    requestDelete() {
        this.dispatchEvent( CanvasObjectEvent.REQUEST_DELETE );
    }
    
    _defineView( widgetClasses ) 
    {
        this.addChild( this._subject );
        
        this._widgets = [];
        for( let WidgetClass of widgetClasses ) 
        {
            let widget = new WidgetClass( this.subject, this );
            widget.visible = false;
            
            for( let evtType of [AbstractObjectWidget.MANIPULATION_START, AbstractObjectWidget.MANIPULATION_UPDATE] ) {
                widget.on( evtType, this._handleWidgetEvent, this );
            }
            
            this.addChild( widget );
            this._widgets.push( widget );
            this._onWidgetCreated( widget );
        }
    }
    
    // Hook method for subclasses to implement additional logic for a given widget
    _onWidgetCreated( widget ) {}
    
    _handleWidgetEvent( evt ) 
    {
        switch( evt.type ) 
        {
            case AbstractObjectWidget.MANIPULATION_START:
                this.dispatchEvent( CanvasObjectEvent.WILL_BE_EDITED );
                break;
            
            case AbstractObjectWidget.MANIPULATION_UPDATE:
                this.update();
                break;
            
        }
    }
}