import Marionette from 'backbone.marionette';

import SliderView from 'views/components/SliderView';
import SliderFunctionButtonView from 'views/components/SliderFunctionButtonView';

import SliderData from 'models/components/SliderData';
import SliderFunctionButtonData from 'models/components/SliderFunctionButtonData';

export default
    class SceneToolbarItemSlidersView
    extends Marionette.CollectionView {

    constructor(params) {
        super(params);
    }

    getChildView(model) {
        if (model instanceof SliderData) {
            return SliderView;
        } else if (model instanceof SliderFunctionButtonData) {
            return SliderFunctionButtonView;
        }
    }

    get className() {
        let name = 'composer-scene-toolbar__item-flyout';

        if (this.getOption('expandUpwards')) {
            name += ' composer-scene-toolbar__item-flyout--expand-up';
        }

        return name;
    }
}
