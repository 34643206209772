import AbstractTool from 'controllers/composer/tools/AbstractTool';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

export default 
    class ObjectToolFlip
    extends AbstractTool
{
    activate() {
        const lightSetObj = this.context.lightSetObject;
        lightSetObj.dispatchEvent( CanvasObjectEvent.WILL_BE_EDITED );
        lightSetObj.subject.scaleX = -lightSetObj.subject.scaleX;
        lightSetObj.dispatchEvent( CanvasObjectEvent.HAS_UPDATED );
    }
    
    deactivate() {
        // Todo: deactivate() should only be called on tools that aren't stateless
    }
}