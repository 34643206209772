import AbstractSliderTool from 'controllers/composer/tools/scene/AbstractSliderTool';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

export default
    class AbstractFilterTool
    extends AbstractSliderTool
{
    constructor( id ) {
        super( id );
        
        this._shouldRecordHistory = true;
    }
    
    get compositionView() {
        return this.context.compositionView;
    }
    
    get canActivate() {
        return this.compositionView
            && this.compositionView.backgroundObject 
            && this.compositionView.backgroundObject.bitmap;
    }
    
    activate() {
        super.activate();
        this._shouldRecordHistory = true;
    }
    
    _handleSliderValueChange( value, type ) {

        clearTimeout(this._applyValueTimeout);

        this._applyValueTimeout = setTimeout(function(){
            const obj = this.compositionView.backgroundObject;

            if( this._shouldRecordHistory === true ) {
                obj.dispatchEvent( CanvasObjectEvent.WILL_BE_EDITED );
                this._shouldRecordHistory = false;
            }

            this._setFilterValue( value );
            obj.updateColorMatrixFilter();
            obj.dispatchEvent( CanvasObjectEvent.HAS_UPDATED );
        }.bind(this), 500);
    }
    
    _setFilterValue( value ) {
        throw "Abstract method _setFilterValue() not implemented in " + this.constructor.name;
    }    
}
