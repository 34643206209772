var CookiesUtil = {

    /**
     * Get item
     * @param  {String} key Cookie item to get
     * @return {String}      Cookie item
     */
    get: function(key) {
        if (!key) {
            return null;
        }

        return decodeURIComponent(
            document.cookie.replace(
                new RegExp(
                        '(?:(?:^|.*;)\\s*'
                        + encodeURIComponent(key)
                            .replace(/[\-\.\+\*]/g, '\\$&')
                        + '\\s*\\=\\s*([^;]*).*$)|^.*$'
                    ),
                    '$1'
                )
            ) || null;
    },

    /**
     * Set cookie item
     * @param {String} key    Key
     * @param {String} value  Value
     * @param {Number||String||Date} end Expiration date for cookie
     * @param {String} path   Path
     * @param {String} domain Item domain
     * @param {Boolean} secure Whether the cookie should be secure or not
     * @return {Boolean}      Wether setting was succesful
     */
    set: function(key, value, end, path, domain, secure) {
        if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
            return false;
        }

        var sExpires = '';

        if (end) {
            switch (end.constructor) {
                case Number: {
                    sExpires = (end === Infinity)
                                    ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
                                    : '; max-age=' + end;
                    break;
                }

                case String: {
                    sExpires = '; expires=' + end;
                    break;
                }

                case Date: {
                    sExpires = '; expires=' + end.toUTCString();
                    break;
                }
            }
        }

        document.cookie = encodeURIComponent(key)
                            + '='
                            + encodeURIComponent(value)
                            + sExpires
                            + (
                                domain
                                    ? '; domain=' + domain
                                    : ''
                                )
                            + (
                                path
                                    ? '; path=' + path
                                    : ''
                                )
                            + (
                                secure
                                    ? '; secure'
                                    : ''
                                );

        return true;
    },

    /**
     * Remove cookie
     * @param  {String} key    Key
     * @param  {String} path   Path
     * @param  {String} domain Domain
     * @return {Boolean}       Wether removal was succesful
    */
    remove: function(key, path, domain) {
        if (!this.hasItem(key)) {
            return false;
        }

        document.cookie = encodeURIComponent(key)
                            + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
                            + (
                                domain
                                    ? '; domain=' + domain
                                    : ''
                                )
                            + (
                                path
                                    ? '; path=' + path
                                    : ''
                                );

        return true;
    },

    /**
     * Check if cookie exists
     * @param  {String}  key Cookie to find
     * @return {Boolean}     True if cookie exists, else false
     */
    has: function(key) {
        if (!key) {
            return false;
        }

        return (
            new RegExp(
                '(?:^|;\\s*)'
                + encodeURIComponent(key).replace(/[\-\.\+\*]/g, '\\$&')
                + '\\s*\\='
            )
        ).test(document.cookie);
    }
};

export default CookiesUtil;
