import _ from 'underscore';
import Analytics from 'utils/Analytics';
import ApplicationState from 'app/ApplicationState';

import LanguageUtil from 'utils/Language';
import LightsetWizardAbstractLayoutView from 'views/lightset-wizard/LightsetWizardAbstractLayoutView';
import LightsetWizardFeaturesRowView from 'views/lightset-wizard/LightsetWizardFeaturesRowView';
import LightsetWizardFeatureCollection from 'models/lightset-wizard/FeatureCollection';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

export default LightsetWizardAbstractLayoutView.extend({

    onBeforeShow() {
        // TODO: There's a few horrible hacks for localization here. 
        // Phase 2 will do more with localitaion, where we'll also refactor the 
        // backend a bit. Stay tuned.

        const lightPointTypes = LightsetWizardDataUtil.getLightPointTypes();
        for (let lpt of lightPointTypes) {
            // Not going to implement model translations for just a single feature...
            // Todo: 'name' field needs to be removed from Django.
            switch (lpt.id) {
                case 'single': {
                    lpt.name = gettext('Single');
                    break;
                }

                case 'double': {
                    lpt.name = gettext('Double');
                    break;
                }

                case 'top': {
                    lpt.name = gettext('Post-top');
                    break;
                }

                case 'wall': {
                    lpt.name = gettext('Wall mounted');
                    break;
                }

                case 'column': {
                    lpt.name = gettext('Columns and Bollards');
                    break;
                }

                case 'luminaire-only': {
                    lpt.name = gettext('Luminaire only');
                    break;
                }

                case 'spiral': {
                    lpt.name = gettext('Spiral');
                    break;
                }

                default: {
                    lpt.name = 'untranslated';
                    break;
                }
            }
        }

        if (ApplicationState.get('target') === 'creator') {
            // hide luminaire only
            const luminaireOnlyIndex = _.findIndex(lightPointTypes, {id: 'luminaire-only'});

            if (luminaireOnlyIndex > 0) {
                lightPointTypes.splice(luminaireOnlyIndex, 1);
            }
        }

        const displayOrder = ['top', 'single', 'double', 'wall', 'spiral', 'column', 'luminaire-only'];
        lightPointTypes.sort((a, b) => {
             return displayOrder.indexOf(a.id) - displayOrder.indexOf(b.id);
        });

        const typesViews = new LightsetWizardFeaturesRowView({
            active: this.model.get('lightPointType'),
            collection: new LightsetWizardFeatureCollection(lightPointTypes)
        });

        // Hack to solve localization of a single image. Backend will be refactored later on anyway (phase 2)
        const heights = Object.create(LightsetWizardDataUtil.getHeights()); // a copy because we modify it
        for (let h of heights) {
            if (h.min === 0 && h.max >= 10000) {
                h.src = 'img/lightset-wizard/heights/all-' + LanguageUtil.getCurrentLanguage() + '.png';
            }
        }

        const heightsView = new LightsetWizardFeaturesRowView({
            active: this.model.get('height'),
            collection: new LightsetWizardFeatureCollection(LightsetWizardDataUtil.getHeights())
        });

        this.stopListening(typesViews, 'select:item');
        this.stopListening(heightsView, 'select:item');

        this.listenTo(typesViews, 'select:item', this.onSelectType);
        this.listenTo(heightsView, 'select:item', this.onSelectHeight);

        this.showChildView('topRow', typesViews);
        this.showChildView('bottomRow', heightsView);

        this.bottomRow.$el.addClass('lightset-wizard__feature-row--heights');
    },

    onSelectType(model) {
        Analytics.track('light_point_type:' + model.get('name'));
        this.triggerMethod('set:lightset:part', 'lightPointType', model);
    },

    onSelectHeight(model) {
        Analytics.track('light_point_height:' + model.get('title'));
        this.triggerMethod('set:lightset:part', 'height', model);
    },

    onHideBottomRow() {
        this.bottomRow.$el.hide();
    },

    onShowBottomRow() {
        this.bottomRow.$el.show();
    }
});
