import Marionette from 'backbone.marionette';
import Backbone from 'backbone';
import Analytics from 'utils/Analytics';

import SelectLayoutView from 'views/ui/select/SelectLayoutView';

import LightsetWizardPreviewView from 'views/lightset-wizard/LightsetWizardPreviewView';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

import template from 'templates/lightset-wizard/lightset-wizard-result';

export default Marionette.LayoutView.extend({
    template: template,

    ui: {
        'luminaireColorRegion': '#lightset-wizard-select-luminaire-color-region',
        'bracketColorRegion': '#lightset-wizard-select-bracket-color-region',
        'poleColorRegion': '#lightset-wizard-select-pole-color-region',
        'globalColorRegion': '#lightset-wizard-select-global-color-region',
        'backgroundOptions': '.lightset-wizard__background-option',
        'wallBackgroundOptions': '#lightset-wizard-background-options-wall',
        'regularBackgroundOptions': '#lightset-wizard-background-options-regular',
        'projectNameInput': '#lightset-wizard-project-name',
        'titleInput': '#lightset-wizard-title',
        'colorIndividuallyCheckbox': '#lightset-wizard-color-individually',
        'globalColorSection': '#lightset-wizard-global-color-section',
        'individualColorsSection': '#lightset-wizard-individual-colors-section'
    },

    events: {
        'click @ui.backgroundOptions': 'onClickBackgroundOption',
        'change @ui.projectNameInput': 'onChangeProjectNameInput',
        'change @ui.titleInput': 'onChangeTitleInput',
        'change @ui.colorIndividuallyCheckbox': 'onColorIndividuallyCheckboxChange'
    },

    regions: {
        'preview': '#lightset-wizard-preview-region'
    },

    onBeforeShow() {
        if (this.getOption('useBackground') === true) {
            if (this.model.get('lightPointType').id === 'wall') {
                this.model.set('background', 'wallmounted');
                this.ui.regularBackgroundOptions.hide();
            } else {
                this.model.set('background', 'default');
                this.ui.wallBackgroundOptions.hide();
            }
        }
        
        this.showChildView('preview', new LightsetWizardPreviewView({
            model: this.model
        }));
        
        this.renderColorSelect('global', 'globalColorRegion', this.ui.globalColorRegion);
        this.renderColorSelect('luminaire', 'luminaireColorRegion', this.ui.luminaireColorRegion);
        
        if (this.model.get('bracketModel')) {
            this.renderColorSelect('bracket', 'bracketColorRegion', this.ui.bracketColorRegion);
        }

        if (this.model.get('poleModel')) {
            this.renderColorSelect('pole', 'poleColorRegion', this.ui.poleColorRegion);
        }
        

        this.$('[data-background="' + this.model.get('background') + '"]').addClass('active');
        this.onColorIndividuallyCheckboxChange(null);
    },

    renderColorSelect(propertyName, regionName, regionEl) {
        const colorPropertyName = propertyName + 'Color';
        let colorsCollection = new Backbone.Collection(LightsetWizardDataUtil.getColors());

        let colorModel = new Backbone.Model({
            current: this.model.get(colorPropertyName),
            options: colorsCollection
        });

        this.listenTo(colorModel, 'change:current', function(model, colorModel){
            if (this.ui.colorIndividuallyCheckbox[0].checked) {
                const partModel = this.model.get(propertyName + 'Model');
                if (partModel !== null && colorModel !== null) {
                    let colorName = colorModel.get('name');
                    if (colorName === null) {
                        colorName = colorModel.name;
                    }
                    Analytics.track('selected:' + partModel.name + ':' + colorName);
                }
                this.globalColorRegion.currentView.model.set('current', null);
            } else {
                this.luminaireColorRegion.currentView.model.set('current', colorModel);

                if (this.model.get('bracketModel')) {
                    this.bracketColorRegion.currentView.model.set('current', colorModel);
                }

                if (this.model.get('poleModel')) {
                    this.poleColorRegion.currentView.model.set('current', colorModel);
                }
            }
            
            if (propertyName !== 'global') {
                this.model.set(colorPropertyName, colorModel);
            } else if (colorModel !== null) {
                Analytics.track('selected:entire_lightset:' + colorModel.get('name'));
            }
        });

        this.addRegion(regionName, regionEl);

        this.showChildView(regionName, new SelectLayoutView({
            model: colorModel
        }));
    },

    getLightSetController() {
        return this.preview.currentView.lightSetController;
    },

    onClickBackgroundOption(event) {
        const backgroundValue = event.currentTarget.getAttribute('data-background');
        Analytics.track('background_' + (backgroundValue && backgroundValue !== '' ? backgroundValue : 'none') + ':card');
        this.ui.backgroundOptions.removeClass('active');
        event.currentTarget.classList.add('active');
        this.model.set('background', backgroundValue);
    },

    onChangeProjectNameInput(event) {
        this.model.set('projectName', event.currentTarget.value);
    },

    onChangeTitleInput(event) {
        this.model.set('title', event.currentTarget.value);
    },
    
    onColorIndividuallyCheckboxChange(evt=null) {
        if(evt == null || evt.target.checked) {
            if (evt != null) {
                Analytics.track('select:color_parts_individually');
            }
            this.ui.globalColorSection.hide();
            this.ui.individualColorsSection.show();
        } else {
            Analytics.track('unselect:color_parts_individually');
            this.ui.globalColorSection.show();
            this.ui.individualColorsSection.hide();
        }
        
    }
});
