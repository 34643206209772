import createjs from 'easeljs';

import AbstractWidgetGraphic from 'views/composer/composition/objects/widgets/graphics/AbstractWidgetGraphic';

export default
    class TransformHandle
    extends AbstractWidgetGraphic
{
    constructor() 
    {
        super();
        
        let size = 10;
        
        // Draw the handle
        this.sprite = new createjs.Shape();
        this.sprite.graphics.beginStroke( 'gray' );
        this.sprite.graphics.beginFill( 'white' );
        this.sprite.graphics.drawRect( 0, 0, size, size );
        this.sprite.regX = this.sprite.regY = size / 2;
        this.addChild( this.sprite );
        
        // Draw the hitarea
        this.hitArea = new createjs.Shape();
        this.hitArea.graphics.beginFill( 'rgba(255, 0, 0, .5)' );
        this.hitArea.graphics.drawCircle( 0, 0, size * 2.5 );
        
        this.mouseChildren = false;
        this.cursor = 'move';
    }
}