import _ from 'underscore';
import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import SelectCollectionView from 'views/ui/select/SelectCollectionView';
import SelectItemView from 'views/ui/select/SelectItemView';

import template from 'templates/ui/select/select';

var SelectLayoutView = Marionette.LayoutView.extend({

    className: 'select',

    ui: {
        'toggle': '.select__toggle',
        'current': '.select-current-region',
        'options': '.select-options-region'
    },

    regions: {
        currentOptionRegion: '@ui.current',
        optionsRegion: '.select-options-region'
    },

    events: {
        'click toggle': 'onToggleClick'
    },

    childEvents: {
        'select:option': 'onSelectOption'
    },

    modelEvents: {
        'change:current': 'onChangeCurrent'
    },

    template: template,

    onBeforeShow: function() {
        if (this.model.get('expandUp')) {
            this.$el.addClass('select--expand-up');
        }

        this.showChildView('optionsRegion', new SelectCollectionView({
            collection: this.model.get('options')
        }));

        const currentModel = this.model.get('current');
        if (currentModel && currentModel.attributes == null) { // fixes a small load project bug
            this.model.attributes.current = null;
        }
        this.showChildView('currentOptionRegion', new SelectItemView({ 
            model: this.model.get('current')
        }));
    },

    onToggleClick: function() {
        this.toggleOptions();
    },

    onChangeCurrent: function(model, currentOption) {
        this.model.get('options').each(function(option){
            option.set('selected', option === currentOption);
        });

        this.showChildView('currentOptionRegion', new SelectItemView({
            model: currentOption
        }));
    },

    onSelectOption: function(view) {
        this.toggleOptions();
        this.model.set('current', view.model);
    },

    toggleOptions: function() {
        this.$el.toggleClass('select--expanded');
    }
});

export default SelectLayoutView;
