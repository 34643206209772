import Marionette from 'backbone.marionette';
import createjs from 'easeljs';

import HiResStage from 'views/canvas/HiResStage';

import CanvasObject from 'views/composer/composition/objects/CanvasObject';
import CanvasObjectEvent from 'views/composer/composition/objects/CanvasObjectEvent';

import BackgroundObject from 'views/composer/composition/objects/BackgroundObject';
import LightSetObject from 'views/composer/composition/objects/LightSetObject';
import LineObject from 'views/composer/composition/objects/LineObject';

import HistoryManager from 'views/composer/history/HistoryManager';
import Memento from 'models/composer/history/Memento';
import ContainerUtil from 'views/utils/ContainerUtil';
import ApplicationState from 'app/ApplicationState';

import template from 'templates/composer/composition/composition';

export default
    class CompositionView
    extends Marionette.ItemView
{   
    // Backbone events
    static get DID_ADD_OBJECT() { return 'COMPOSITION_VIEW_DID_ADD_OBJECT_EVENT'; }
    static get DID_REMOVE_OBJECT() { return 'COMPOSITION_VIEW_DID_REMOVE_OBJECT_EVENT'; }
    static get DID_SELECT_OBJECT() { return 'COMPOSITION_VIEW_DID_SELECT_OBJECT_EVENT'; }
    static get DID_DESELECT_OBJECT() { return 'COMPOSITION_VIEW_DID_DESELECT_OBJECT_EVENT'; }
    
    constructor() {
        super();
        this._objects = [];
    }
    
    get template() {
        return template( {} );
    }

    get className() {
        return 'composer__composition';
    }
    
    get stage() {
        return this._stage;
    }
    
    get backgroundContainer() {
        return this._backgroundContainer;
    }
    
    get lightSetContainer() {
        return this._lightSetContainer;
    }
    
    get lineContainer() {
        return this._lineContainer;
    }
    
    get objects() {
        let ary = this._backgroundContainer.children;
        ary = ary.concat( this._lightSetContainer.children );
        ary = ary.concat( this._lineContainer.children );
        return ary;
    }
    
    get lightSetModels() {
        const ary = [];
        for (let lightSetObject of this._lightSetContainer.children) {
            ary.push(lightSetObject.lightSetController.model);
        }
        return ary;
    }
    
    set objects( ary ) {
        // Brute force approach: first remove all existing objects from the stage and unsubscribe the listeners
        // Then add the ones from the array, which will then automatically be assigned to the correct layers
        // This should only be done by the history, but anyway, never record the adding of these elements, hence the false argument
        for( let obj of this.objects ) {
            this.removeObject( obj, false )
        }
        
        for( let obj of ary ) {
            this.addObject( obj, false );
        }
    }
    
    get historyManager() {
        if( !this._historyManagerInstance ) {
            this._historyManagerInstance = new HistoryManager( this._stage );
            this._historyManagerInstance.entryLimit = 0; // no limit for now
        }
        return this._historyManagerInstance;
    }
    
    get backgroundObject() {
        return this._backgroundObject;
    }
    
    get backgroundBitmap() {
        return this._backgroundObject ? this._backgroundObject.subject : null;
    }
    
    set backgroundBitmap( bmp ) {
        if( this._backgroundObject ) {
            this.removeObject( this._backgroundObject, false );
            this._backgroundObject = null;
        }
        if( bmp ) {
            this._backgroundObject = new BackgroundObject( bmp );
            this._backgroundObject.x = this.stage.canvas.width / 2;
            this._backgroundObject.y = this.stage.canvas.height / 2;
            this._backgroundObject.scaleTo( this._stage.canvas.width, this._stage.canvas.height, BackgroundObject.ASPECT_FILL );
            this.addObject( this._backgroundObject, false );
        }
    }
    
    createNewComposition() {
        this._stage = new HiResStage(ApplicationState.get('composerCanvasParameters'));
        this._stage.enableMouseOver();
        createjs.Touch.enable(this._stage);
        this._stage.mouseMoveOutside = true;
        
        for( let type of ['click' ] ) {
            this._stage.on( type, this._handleStageEvent, this );
        }
        
        this._boundsIndicator = new createjs.Shape();
        this._boundsIndicator.graphics.beginFill('rgba( 0, 0, 0, .1)').drawRect( 0, 0, this._stage.canvas.width, this._stage.canvas.height );
        this._stage.addChild( this._boundsIndicator );
        
        this._backgroundContainer = new createjs.Container();
        this._lightSetContainer = new createjs.Container();
        this._lineContainer = new createjs.Container(); 
        
        for( let layer of [this._backgroundContainer, this._lightSetContainer, this._lineContainer] ) {
            this._stage.addChild( layer );
        }
    }
    
    addLightSetController( lightSetController, initiallySelectable = false, initiallySelected = false ) 
    {
        const canvasCenter = new createjs.Point( this._stage.canvas.width / 2, this._stage.canvas.height / 2);
        const unoccupiedPosition = ContainerUtil.getFirstUnoccupiedPosition( this.lightSetContainer, canvasCenter.x, canvasCenter.y, 30 );
        const lightSet = new LightSetObject( lightSetController );
        lightSet.x = unoccupiedPosition.x;
        lightSet.y = unoccupiedPosition.y;
        
        this.addObject( lightSet, true );
        lightSet.selectable = initiallySelectable;
        lightSet.selected = initiallySelected;
        
        return lightSet;
    }
    
    addObject( obj, addHistoryEntry = true )
    {   
        let container = null;
        if( obj instanceof BackgroundObject ) {
            // Don't include adding a background to the history. This action cannot be undone.
            addHistoryEntry = false;
            container = this._backgroundContainer;
        } else if( obj instanceof LightSetObject ) {
            container = this._lightSetContainer;
        } else if( obj instanceof LineObject ) {
            container = this._lineContainer;
        }
        
        if( addHistoryEntry ) {
            this._addCompositionHistoryEntry();
        }
        
        this._setCanvasObjectListeners( obj, true );
        container.addChild( obj );
        this._stage.update();
        this.trigger( CompositionView.DID_ADD_OBJECT, obj );
        
        ApplicationState.set('lightSets', this.lightSetModels);
    }
    
    removeObject( obj, addHistoryEntry = true ) {
        if( addHistoryEntry ) {
            this._addCompositionHistoryEntry();
        }
        // Completely removes the object.
        if( obj ) {
            obj.selected = false;
            this._setCanvasObjectListeners( obj, false );
            obj.parent && obj.parent.removeChild( obj );
            this._stage.update();
            this.trigger( CompositionView.DID_REMOVE_OBJECT, obj );
        }
        
        ApplicationState.set('lightSets', this.lightSetModels);
    }

    replaceAllLightSetsWith(lightSetController) {
        // Replace all light sets with a copy of the new one, keeping the entire undo history intact.
        // Quite simple given the facilities we already have:
        // First record the current object state. Pressing undo will remove everything and re-add the current objects
        this._addCompositionHistoryEntry();

        // Get a property map of a dry CanvasObject. Not a LightSetObject, since we don't want to copy mask/model
        // properties, as these unique to the replaced LightSetObject.
        // This approach prevents regression when we add additional properties to CanvasObject, much better than manual
        // copying Also, if we ever solve the eraser canvas size problem in the future, we can clone erased parts as
        // well.
        let propertyMap = new CanvasObject(null).mementoPropertyMap;

        // Add some specific lightset model level properties that we do want to copy
        // Note: Colors are being preselected based on the current colorset so they are present on the new
        // lightset object
        propertyMap = propertyMap.concat([
            {lightSetController: [
                {model: ['angleIndex']}
            ]}
        ]);

        // Iterate over all current LightSetObject instances
        for (let lightSetObject of this._lightSetContainer.children) {
            // Record the properties of each current LightSetObject,
            // then restore the resulting Memento on our replacement object :)
            // Couldn't be easier.

            const memento = new Memento(propertyMap).record(lightSetObject, false, function() {
                const lightSetControllerCopy = lightSetController.copy();
                const newLightSetObject = new LightSetObject(lightSetControllerCopy);

                // Restore the memento on this object
                memento.restore(newLightSetObject, function() {
                    // Remove the old object. Again, don't record history.
                    this.removeObject(lightSetObject, false);

                    // because we are in edit mode here
                    newLightSetObject.selectable = true;
                    // Don't record history, already did that with this._addCompositionHistoryEntry();
                    this.addObject(newLightSetObject, false);

                    // Kick off the rendering
                    // (browser caching should optimize the fact that we're loading lots of duplicate images...)
                    lightSetControllerCopy.render();

                }.bind(this));
            }.bind(this));
        }
    }

    onStateRestored() {
        this._stage.update();
    }

    _addCompositionHistoryEntry() {
        let memento = new Memento( ['objects'] ).record( this );
        this.historyManager.addEntry( memento );
    }
    
    _addObjectHistoryEntry( canvasObj ) {
        let memento = canvasObj.recordState();
        this.historyManager.addEntry( memento );
    }
    
    _setCanvasObjectListeners( obj, addListeners ) 
    {
        let method = addListeners == true ? obj.on : obj.off;
        
        for( let type of [
            CanvasObjectEvent.WILL_BE_EDITED,
            CanvasObjectEvent.HAS_UPDATED,
            CanvasObjectEvent.WAS_SELECTED,
            CanvasObjectEvent.WAS_DESELECTED,
            CanvasObjectEvent.REQUEST_DELETE
        ]) {
            method.apply( obj, [type, this._handleCanvasObjectEvent, this] );
        }
        
        for( let type of ['mousedown', 'click', 'dblclick'] ) {
            method.apply( obj, [type, this._handleCanvasObjectTouchEvent, this, false, null, false] );
        }
    }
    
    _handleCanvasObjectEvent( evt ) 
    {
        let canvasObj = evt.target;
        
        switch( evt.type ) {
            case CanvasObjectEvent.WILL_BE_EDITED:
                this._addObjectHistoryEntry( canvasObj );
                break;
                
            case CanvasObjectEvent.WAS_SELECTED:
                this.trigger( CompositionView.DID_SELECT_OBJECT, canvasObj );
                this._stage.update();
                break;
                
            case CanvasObjectEvent.WAS_DESELECTED:
                this.trigger( CompositionView.DID_DESELECT_OBJECT, canvasObj );
                this._stage.update();
                break;
                
            case CanvasObjectEvent.HAS_UPDATED:
                this._stage.update();
                break;
            
            case CanvasObjectEvent.REQUEST_DELETE:
                this.removeObject( canvasObj, true );
                break;
        } 
    }
    
    _handleCanvasObjectTouchEvent( evt ) 
    {
        let canvasObj = evt.currentTarget;
        
        switch( evt.type ) {
            case 'mousedown':
                for( let anyObj of this.objects ) {
                    // We could handle both selection and deselection in a single line but we want to ensure the selection event is fired last.
                    if( anyObj !== canvasObj ) {
                        anyObj.selected = false;
                    } 
                }
                canvasObj.selected = true;
                break;
            
            case 'click':
                // If the click lands on this object, cancel propagation, to prevent the stage listener from deselecting the object.
                evt.stopImmediatePropagation();
                evt.stopPropagation();
                if( canvasObj instanceof LineObject ) {
                    // Selected line is always on top
                    canvasObj.parent && canvasObj.parent.addChild( canvasObj );
                    this._stage.update();
                }
                break;
            
            case 'dblclick':
                // Send lightset object to back if it's selected and reorderable
                if( canvasObj instanceof LightSetObject && canvasObj.selectable && canvasObj.selected && canvasObj.isReorderable ) {
                    let container = canvasObj.parent;
                    if( container && container.getChildIndex( canvasObj ) > 0 ) {
                        this._addCompositionHistoryEntry();
                        container.setChildIndex( canvasObj, 0 );
                        this._stage.update();
                    }
                }
                break;
        }
    }
    
    _handleStageEvent( evt ) {
        if( evt.type == 'click' ) {
            for( let obj of this.objects ) {
                obj.selected = false;
            }
        }
    }
}
