import _ from 'underscore';
import Analytics from 'utils/Analytics';

import LightsetWizardAbstractLayoutView from 'views/lightset-wizard/LightsetWizardAbstractLayoutView';
import LightsetWizardFeaturesRowView from 'views/lightset-wizard/LightsetWizardFeaturesRowView';
import LightsetWizardFeatureCollection from 'models/lightset-wizard/FeatureCollection';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

export default
    class LightsetWizardLuminairesLayoutView
    extends LightsetWizardAbstractLayoutView
{

    initialize() {
        this.lightPointType = this.model.get('lightPointType').id;
        this.luminaireFamilies =
            LightsetWizardDataUtil.getLuminaireFamiliesToDisplay(this.lightPointType, this.model.get('height'));
    }

    onBeforeShow() {
        let luminairesView = new LightsetWizardFeaturesRowView({
            active: this.model.get('luminaire'),
            collection: new LightsetWizardFeatureCollection(this.luminaireFamilies)
        });

        this.listenTo(luminairesView, 'select:item', this.onSelectLuminaireFamily);
        this.showChildView('topRow', luminairesView);
    }

    onSelectLuminaireFamily(family) {
        Analytics.track('select_luminaire:product_type:' + family.get('name'));
        let models = LightsetWizardDataUtil.getLuminaireModels(family, this.lightPointType, this.model.get('height'));

        this.triggerMethod('set:lightset:part', 'luminaire', family);

        let luminaireModelsView = new LightsetWizardFeaturesRowView({
            active: this.model.get('luminaireModel'),
            collection: new LightsetWizardFeatureCollection(models)
        });

        this.luminaireFamily = family;

        this.stopListening(luminaireModelsView, 'select:item');
        this.listenTo(luminaireModelsView, 'select:item', this.onSelectLuminaireModel);
        this.showChildView('bottomRow', luminaireModelsView);
    }

    onSelectLuminaireModel(model) {
        Analytics.track('select_luminaire:' + this.luminaireFamily.get('name') + ':' + model.get('name'));

        if (this.lightPointTypeIs('rear')) {
            const rearModel = LightsetWizardDataUtil.getRearModel(this.luminaireFamily.get('models'), model);
            this.triggerMethod('set:lightset:part', 'rearLuminaireModel', rearModel);
        }

        this.triggerMethod('set:lightset:part', 'luminaireModel', model);
    }

    lightPointTypeIs(lightPointType) {
        return this.lightPointType === lightPointType;
    }

    get hasBrackets() {
        return !this.lightPointTypeIs('top');
    }

    get hasPoles() {
        return !this.lightPointTypeIs('wall');
    }
};
