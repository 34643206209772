import Marionette from 'backbone.marionette';

import $ from 'jquery';
import SliderView from 'views/components/SliderView';
import ConfirmModel from 'models/ui/confirm/Confirm';
import ConfirmView from 'views/ui/confirm/ConfirmView';
import AbstractLayoutView from 'views/abstract/AbstractLayoutView';
import ObjectToolbarMenuItem from 'views/composer/toolbars/ObjectToolbarMenuItem';

export default
    class ObjectToolbarMenuView
    extends Marionette.CollectionView 
{
    constructor(params) {
        super(params);
    }
    
    get className() {
        return 'object-toolbar__main-section';
    }
    
    get childView() {
        return ObjectToolbarMenuItem;
    }
    
    get childViewOptions() {
        // We pass the lightset model to each child just because the Info button needs to show some set info.
        // Not the cleanest thing to do, but very simple and has little memory consequence (it's just object references, not actual objects)
        return {'lightSetModel': this.getOption('lightSetModel')};
    }

    get triggers() {
        return {
            'click #object-toolbar__color-button': 'show:color',
            'click #object-toolbar__eraser-button': 'show:eraser'
        };
    }
}
