import $ from 'jquery';

import Marionette from 'backbone.marionette';
import Backbone from 'backbone';

import ToggleView from 'views/components/ToggleView';

export default
    class SceneToolbarItemToggleVisibilityView
    extends Marionette.ItemView {

    onShow() {
        var model = new Backbone.Model({
            on: this.model.get('value') === 1
        });

        var toggleView = new ToggleView({
            model: model
        });

        toggleView.render();
        this.$el.append(toggleView.$el);

        this.listenTo(model, 'change:on', this._handleToggle);
    }

    _handleToggle(model, isOn) {
        this.model.set('value', isOn ? 1 : 0);
    }

    get className() {
        let name = 'composer-scene-toolbar__item-flyout';

        if (this.getOption('expandUpwards')){
            name += ' composer-scene-toolbar__item-flyout--expand-up';
        }

        return name;
    }

    get template() {
        return false;
    }
}
