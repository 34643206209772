export default
	class HiResStageParameters
{
	constructor(canvasOrId, width, height, dpiMultiplier) {
		this._canvasOrId = canvasOrId;
		this._width = width;
		this._height = height;
		this._dpiMultiplier = dpiMultiplier;
	}

	get canvasOrId() {
		return this._canvasOrId;
	}

	get canvas() {
		if (this.canvasOrId == null) {
			return null;
		} else if (this.canvasOrId.tagName != null && this.canvasOrId.tagName.toLowerCase() == 'canvas') {
			return this.canvasOrId;
		} else {
			return document.getElementById(this.canvasOrId);
		}
	}

	get width() {
		return this._width;
	}

	get height() {
		return this._height;
	}

	get dpiMultiplier() {
		return this._dpiMultiplier;
	}
}