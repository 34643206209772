import AbstractPage from 'controllers/pdf/AbstractPage';
import LightSetController from 'controllers/LightSetController';
import ApplicationState from 'app/ApplicationState';
import LightSetModelSerializer from 'controllers/LightSetModelSerializer';

export default
    class LightSetPage 
    extends AbstractPage
{
    constructor(pdf, model, lightSet, isFirstPage, callback) {
        model.get('title') == null && model.set('title', lightSet.get('title'));
        model.get('projectName') == null && model.set('projectName', lightSet.get('projectName'));
        super(pdf, model, isFirstPage, callback);
        this.lightSet = lightSet;
    }

    get steps() {
        return [
            this._addHeader.bind(this), 
            this._addBackground.bind(this), 
            this._addLightSet.bind(this), 
            this._addSpecs.bind(this), 
            this._addFooter.bind(this)
        ];
    }

    _addHeader(callback) {
        const title = this.model.get('title');
        if (title != null && title !== gettext('Untitled project')) {
            this.pdf
                .font('CentraleSansMedium')
                .fontSize(24)
                .fillColor(this.colors.blue)
                .text(title, this.margin + 25, 50, { width: 450 });
        }

        const projectName = this.model.get('projectName');
        if (projectName != null) {
            this.pdf
                .font('CentraleSansMedium')
                .fontSize(12)
                .fillColor(this.colors.darkGrey)
                .text(projectName, this.margin + 26, 80, {width: 450}
                );
        }

        callback();
    }
    
    _addBackground(callback) {
        
        callback(); // Yes, commented out code in the repo isn't nice. Reasons. Fight me.

        // if (this.lightSet.get('background') == null) {
        //     let bgImage = this.lightSet.get('lightPointType').bgImage;
        //     let imgWidth = this.width - 2 * this.margin;
        //     let imgHeight = imgWidth * (bgImage.height / bgImage.width);
        
        //     this.addImage(bgImage.src, {
        //         x: this.margin,
        //         y: this.footerY - imgHeight,
        //         width: imgWidth
        //     }, callback);
        // } else {
        //     callback();
        // }
    }

    _addLightSet(callback) {
        const lightSetModel = LightSetModelSerializer.copy(this.lightSet);
        if (lightSetModel.get('background') == null) {
            const lightPointType = lightSetModel.get('lightPointType').id;
            lightSetModel.set('background', lightPointType === 'wall' ? 'wallmounted' : 'default');
            lightSetModel.set('removeBackgroundAfterRender', true);
        }

        const lightSetController = new LightSetController();
        lightSetController.resultWidth = 1450;
        lightSetController.resultHeight = 1800;
        lightSetController.model = lightSetModel;
        
        lightSetController.renderDataURLOutput(function(imgData) {
            ApplicationState.push('pdfImages', {data: imgData, type: 'lightset'});

            const lsWidth = this.width - 2 * this.margin;
            const lsX = this.width / 2 - lsWidth / 2;
            
            this.addImage(imgData, {
                x: lsX,
                y: 150,
                width: lsWidth
            }, callback);
        }.bind(this));
    }

    _addSpecs(callback) {
        let parts = [];

        this.pdf.x = this.width / 2 + 20;
        this.pdf.y = this.height / 2 + 30;
        this.pdf.fontSize(10)
                .fillColor(this.colors.darkGrey);

        if (this.lightSet.get('customProductText')) {
            this.pdf.font('CentraleSansBook').text(this.lightSet.get('customProductText'));
        } else {
            if (this.lightSet.get('luminaireModel')) {
                parts.push({
                    part: gettext('Luminaire'),
                    name: this.lightSet.get('luminaireModel').name,
                    color: this.lightSet.get('luminaireColor').get('name')
                });
            }

            if (this.lightSet.get('bracketModel')) {
                parts.push({
                    part: gettext('Bracket'),
                    name: this.lightSet.get('bracketModel').name,
                    color: this.lightSet.get('bracketColor').get('name')
                });
            }

            if (this.lightSet.get('poleModel')) {
                parts.push({
                    part: gettext('Pole'),
                    name: this.lightSet.get('poleModel').name,
                    color: this.lightSet.get('poleColor').get('name')
                });
            }

            for(let part of parts) {
                this.pdf
                    .font('CentraleSansMedium')
                    .text(part.part + ': ', {
                        width: 200,
                        continued: true
                    })
                    .font('CentraleSansBook')
                    .text(part.name)
                    .text(part.color);

                this.pdf.y = this.pdf.y + 10;
            }
        }

        callback();
    }
};
