import _ from 'underscore';

export default class LightsetModelUtil {

    static stripUnusableAngles(model) {
        const luminaireModel = model.get('luminaireModel') || false;
        const bracketModel = model.get('bracketModel') || false;
        const poleModel = model.get('poleModel') || false;

        let keyPairs = LightsetModelUtil._getAngleKeyPairs(luminaireModel.angles);

        if (bracketModel || poleModel)
        {
            if (bracketModel) {
                const bracketKeyPairs = LightsetModelUtil._getAngleKeyPairs(bracketModel.angles);
                keyPairs = _.intersection(keyPairs, bracketKeyPairs);
            }

            if (poleModel && poleModel.angles.length > 1) {
                const poleKeyPairs = LightsetModelUtil._getAngleKeyPairs(poleModel.angles);
                keyPairs = _.intersection(keyPairs, poleKeyPairs);
            }

            LightsetModelUtil._removeSuperfluousAngles(luminaireModel, keyPairs);
            if (bracketModel) {
                LightsetModelUtil._removeSuperfluousAngles(bracketModel, keyPairs);
            }

            if (poleModel) {
                LightsetModelUtil._removeSuperfluousAngles(poleModel, keyPairs);
            }
        }
        else {
            return;
        }
    }

    static _getAngleKeyPairs(renderings) {
        const keyPairs = [];
        _.each(renderings, function(rendering, index, renderings) {
            const keyPairString = LightsetModelUtil._getAngleKeyPairString(rendering);
            keyPairs.push(keyPairString);
            if (rendering.angleH > 0 && rendering.angleV > 0) {
                keyPairs.push('-' + keyPairString);
            }
        });
        return keyPairs;
    }

    static _getAngleKeyPairString(rendering) {
        return rendering.angleH.toString() + ':' + Math.abs(rendering.angleV).toString();
    }

    static _removeSuperfluousAngles(elementModel, keyPairs) {
        elementModel.angles = LightsetModelUtil._getCoincidingAngles(elementModel.angles, keyPairs);
        elementModel.anglesNegative = LightsetModelUtil._getCoincidingAngles(elementModel.anglesNegative, keyPairs);
    }

    static _getCoincidingAngles(renderings, keyPairs) {
        const validRenderings = [];
        _.each(renderings, function(rendering, index, renderings) {
            const keyPair = LightsetModelUtil._getAngleKeyPairString(rendering);
            if (keyPairs.indexOf(keyPair) >= 0) {
                validRenderings.push(rendering);
            }
        });

        return validRenderings;
    }

    static getRendering(model, elementName, index, isRearModel=false) {
        const elementModel = model.get(elementName + 'Model');

        if (!elementModel) {
            return null;
        }

        // When cycling through angles (rotating the lightset), we can encounter an arbitrary amount of angles.
        // Using a modulo calculation, we can make sure we only cycle through existing angles.
        index = index % elementModel.angles.length

        // Lightsets can be rotated around the Y-axis through the GUI. As a consequence, luminaires that 
        // are not symmetrical will require dedicated negative angle images for any angle greater than 0. 
        // The first rendering is always assumed to be angle 0, so this one will be physically mirrored.
        // For angles greater than 0, we check if there are dedicated negative angle images, returning these
        // in that event. If they don't exist, we simply return the positive angle image, so that it can be 
        // copied. So the source data is supplied e.g. as follows (note the indices):

        // model.angles         model.anglesNegative
        //  0: x  0 y  0
        //  1: x  5 y  5        0: x  -5 y  -5
        //  2: x 10 y 10        1: x -10 y -10
        //  3: x 15 y 15        2: x -15 y -15

        // The same applies for side-entry double brackets, as they are split into two images, since the primary (right) part
        // will appear in front of the pole, and the secondary (left) part behind the pole, when rotating the lightset.
        // Of course, for pole side mounting double brackets, the negative angle images are mandatory.

        if (isRearModel && elementModel.anglesNegative && elementModel.anglesNegative[index - 1] && index > 0) {
            return elementModel.anglesNegative[index - 1]
        } else {
            // The primrary rendering is requested and/or no dedicated negative secondary version exists.
            return elementModel.angles[index]; 
        }
    }
};
