export default
    class NumbersUtil {

    /**
     * Limit an integer with a minimum value and a maximum value
     * @param  {Integer} value Value to limit
     * @param  {Integer} min Minimum value
     * @param  {Integer} max Maximum value
     * @return {Integer} limited value
     */
    static limit(value, min, max) {
        return Math.min(max, Math.max(min, value));
    }

    /**
     * Find the closest multiple
     * @param  {Integer} value Value to find closest multiple for
     * @param  {Integer} step  Step that result needs to be a multiple of
     * @return {Integer}       Resulting multiple
     */
    static nearestMultiple(value, step) {
        return Math.round(value / step) * step;
    }
};
