import Marionette from 'backbone.marionette';

import template from 'templates/lightset-wizard/lightset-wizard-header';

export default Marionette.ItemView.extend({

    template: template,

    className: 'lightset-wizard__header',

    ui: {
        'title': '.lightset-wizard__title',
        'closeBtn': '.lightset-wizard__close',
        'resetBtn': '.lightset-wizard__reset',
    },

    modelEvents: {
        'change:title': 'onTitleChange'
    },

    triggers: {
        'click @ui.closeBtn': 'close:wizard',
        'click @ui.resetBtn': 'reset:wizard'
    },

    onTitleChange(model, value) {
        this.ui.title.text(value);
    }
});
