import Backbone from 'backbone';
import LightsetWizardDataUtil from 'utils/lightset-wizard/LightsetWizardDataUtil';

export default 
    class LightSetModel
    extends Backbone.Model
{
    constructor(params) { 
        super(params);
        // Can't include these in defaults since the defaults initialize before the data util has loaded its sources
        const defaultColor = LightsetWizardDataUtil.getDefaultColor();
        for (let key of ['luminaireColor', 'bracketColor', 'poleColor']) {
            if (!this.get(key)) {
                this.set(key, defaultColor);
            }
        }
    }
    
    get defaults() {
        return {
            luminaire: null,
            luminaireModel: null,
            bracket: null,
            bracketModel: null,
            pole: null,
            poleModel: null,
            height: null,
            lightPointType: null
        }
    }
};
