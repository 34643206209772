// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"render__contents render__contents--enter-title\">\n    <h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Render image",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n    <p class=\"render__intro-text\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Feel free to add a title to the final image. The image will be rendered in PDF (with title and without guides) and JPG (without title and lines).",{"name":"trans","hash":{},"data":data}))
    + "</p>\n    <div class=\"render__preview\">\n    	<input id=\"composition-title-input\" type=\"text\" name=\"title\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add a title here",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"render__title-input\">\n    	<img id=\"composition-preview-image\" src=\""
    + alias3(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"image","hash":{},"data":data}) : helper)))
    + "\">\n    </div>\n    <div class=\"render__actions\">\n        <button id=\"cancel-and-close\" class=\"button button--transparent-inverted\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    	<button id=\"to-render-screen\" class=\"button button--primary-inverted\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Render image",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});
