// import 'easeljs';
// 
import CanvasObject from 'views/composer/composition/objects/CanvasObject';
import AggregatedColorMatrixFilter from 'controllers/composer/tools/scene/AggregatedColorMatrixFilter';
import MoveWidget from 'views/composer/composition/objects/widgets/MoveWidget';

export default
    class BackgroundObject 
    extends CanvasObject
{
    static get ASPECT_FIT() { return 'background-scale-aspect-fit'; }
    static get ASPECT_FILL() { return 'background-scale-aspect-fill'; }
    
    constructor( bitmap, widgetClasses = null ) 
    {
        super( bitmap, widgetClasses );
        this._colorMatrixFilter = new AggregatedColorMatrixFilter();
        
        if( bitmap && bitmap.image != null ) {
            this._updateBitmap();
            this.updateColorMatrixFilter();
        }
    }
    
    get defaultWidgetClasses() {
        return [MoveWidget];
    }
    
    get colorMatrixFilter() {
        return this._colorMatrixFilter;
    }
    
    get bitmap() {
        return this.subject;
    }
    
    get isReorderable() {
        return false;
    }
    
    get mementoPropertyMap() {
        return super.mementoPropertyMap.concat([
            { subject: ['image', 'regX', 'regY'] }, // Record the canvas/image contained within the subject (which is a createjs.Bitmap)
            { colorMatrixFilter: ['exposure', 'brightness', 'contrast', 'saturation', 'hue'] }
        ]);
    }
    
    onStateRestored() {
        this.bitmap.setBounds( 0, 0, this.bitmap.image.width, this.bitmap.image.height );
        this.updateColorMatrixFilter();
        super.onStateRestored();
    }
    
    scaleTo( width, height, mode ) 
    {
        if( !this.bitmap || this.bitmap.image == null ) {
            return;
        }
        
        let scale = 1;
        let bounds = this.subject.getBounds();
        let ratioW = width / bounds.width;
        let ratioH = height / bounds.height;
        
        switch( mode ) {
            case BackgroundObject.ASPECT_FIT:
                scale = Math.min( ratioW, ratioH );
                break;
                
            case BackgroundObject.ASPECT_FILL:
                scale = Math.max( ratioW, ratioH );
                break;
        }
        
        this.bitmap.scaleX = this.bitmap.scaleY = scale;
    }
    
    updateColorMatrixFilter() 
    {
        let bmp = this.bitmap;
        if( !bmp.filters ) {
            bmp.filters = [this._colorMatrixFilter];
        } else {
            let filters = bmp.filters;
            if( filters.indexOf( this._colorMatrixFilter ) < 0 ) {
                filters.push( this._colorMatrixFilter );
            }
        }
        
        this._updateBitmap();
    }
    
    _updateBitmap() {
        if( this.bitmap && this.bitmap.image != null ) {
            // Initial values have not been set yet, do so now
            if( !this.bitmap.cacheID ) {
                let bounds = this.bitmap.getBounds();
                this.bitmap.regX = bounds.width / 2;
                this.bitmap.regY = bounds.height / 2;
                this.bitmap.cache( 0, 0, bounds.width, bounds.height );
            } else {
                this.bitmap.updateCache();
            }
        }
    }
}