export default
    class ObjectToolbarItemType
{
    static get ROTATE() { return 'rotate-perspective'; }
    static get FLIP() { return 'flip'; }
    static get COLOR() { return 'color'; }
    static get INFO() { return 'info'; }
    static get DUPLICATE() { return 'duplicate'; }
    static get ERASER() { return 'eraser'; }
    static get REMOVE() { return 'remove'; }
}
