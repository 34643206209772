// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<h3 class=\"popover__header\">"
    + alias4(((helper = (helper = helpers.modalHeader || (depth0 != null ? depth0.modalHeader : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modalHeader","hash":{},"data":data}) : helper)))
    + "</h3>\n<p class=\"popover__text\">"
    + alias4(((helper = (helper = helpers.modalTxt || (depth0 != null ? depth0.modalTxt : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"modalTxt","hash":{},"data":data}) : helper)))
    + "</p>\n<div class=\"popover__actions\">\n	<button class=\"popover__confirm button button--primary\">"
    + alias4(((helper = (helper = helpers.confirmLabel || (depth0 != null ? depth0.confirmLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"confirmLabel","hash":{},"data":data}) : helper)))
    + "</button>\n	<button class=\"popover__cancel button button--transparent\">"
    + alias4(((helper = (helper = helpers.cancelLabel || (depth0 != null ? depth0.cancelLabel : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cancelLabel","hash":{},"data":data}) : helper)))
    + "</button>\n</div>\n";
},"useData":true});
