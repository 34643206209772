import Backbone from 'backbone';

export default
    class ConfirmModel
    extends Backbone.Model {

    get defaults() {
        return {
            confirmTxt: gettext('Are you sure?'),
            confirmLabel: gettext('Do it'),
            cancelLabel: gettext('Cancel'),
            dontShowLabel: gettext('Don\'t show this again')
        };
    }
}
